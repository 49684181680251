import React, { Component } from 'react';

class Email extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M11.6523438,12.8505859 C12.0830078,12.8505859 12.4433594,12.6396484 12.8916016,12.1914062 L20.6347656,4.38671875 C20.2304688,4.13183594 19.6152344,4 18.7978516,4 L4.52441406,4 C3.68945312,4 3.07421875,4.13183594 2.67871094,4.38671875 L10.4130859,12.1914062 C10.8701172,12.6396484 11.2304688,12.8505859 11.6523438,12.8505859 Z M21.9882812,18.9589844 C22.1992188,18.5810547 22.3134766,18.0273438 22.3134766,17.2714844 L22.3134766,7.515625 C22.3134766,6.63671875 22.1464844,5.97753906 21.8564453,5.55566406 L15.1943359,12.15625 L21.9882812,18.9589844 Z M1.31640625,18.9589844 L8.11914062,12.15625 L1.45703125,5.55566406 C1.15820312,5.97753906 1,6.63671875 1,7.515625 L1,17.2714844 C1,18.0273438 1.11425781,18.5810547 1.31640625,18.9589844 Z M18.7890625,20.7958984 C19.7382812,20.7958984 20.4501953,20.6025391 20.8984375,20.2509766 L13.9814453,13.3339844 L13.6386719,13.6767578 C12.9882812,14.3183594 12.3642578,14.6259766 11.6523438,14.6259766 C10.9492188,14.6259766 10.3076172,14.3183594 9.65722656,13.6767578 L9.31445312,13.3515625 L2.40625,20.2509766 C2.86328125,20.6025391 3.56640625,20.7958984 4.515625,20.7958984 L18.7890625,20.7958984 Z"/>
      </svg>
    );
  }
}

export default Email;