import React, { Component } from 'react';
import './LatestActivities.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


//Components
import { MoonLoader } from 'halogenium';
import Activity from './ActivityCard';

class LatestActivities extends Component {

    state = {
        activities: null
    }

    componentDidMount() {
        const db = firebase.firestore();
        var that = this;

        db.collection(this.props.endpoint)
        .where('companyID', '==', this.props.companyID)
        .orderBy('startTime', 'desc')
        .limit(2)
        .onSnapshot(function(querySnapshot) {
            var activities = []
            querySnapshot.forEach(function(doc) {
                const activity = doc.data()
                activity['id'] = doc.id;
                activities.push(activity) 
            });
            that.setState({activities});
        });
    }

    generateActivities(activities) {
        return activities.length
            ? <React.Fragment>
            <div className='activities'>
                {activities.map(activity => (
                    <Activity 
                        activity={activity}
                        url={this.props.url}
                    />
                ))}
                </div>
            </React.Fragment>
            : null
    }

    loading() {
        return (
          <article aria-busy={true} className="loader">
            <MoonLoader
              loading={true}
              color="#FFFFFF"
              size="50"
              margin="auto"
            />
          </article>
        )
    }

    render() {
        var that = this;

        if (!this.state.activities) {
            return (
                <div className="latest-activities">
                    {that.loading()}
                </div>
            )
        }

        if (this.state.activities.length > 0) {
            return (
                <div className="latest-activities">
                <h5>{this.props.title}</h5>
                {that.generateActivities(this.state.activities)}
                </div>
            )
        }

        return (
            <div className="latest-activities">
            <h4>{this.props.title}</h4>
            <h6>{this.props.error}</h6>
            </div>
        )
    } 
};

export default LatestActivities;