import React, { Component } from 'react';

class Photo extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
      <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M3.69979716,19.6478702 L20.3002028,19.6478702 C22.6466531,19.6478702 24,18.3042596 24,15.9675456 L24,5.66653144 C24,3.32008114 22.6466531,1.97647059 20.3002028,1.97647059 L18.6547667,1.97647059 C17.905071,1.97647059 17.6129817,1.90831643 17.0288032,1.39229209 L16.2888438,0.710750507 C15.7046653,0.184989858 15.1496957,0 14.2052738,0 L9.78498986,0 C8.83083164,0 8.27586207,0.184989858 7.70141988,0.710750507 L6.95172414,1.39229209 C6.42950396,1.86140513 6.13258679,1.96037752 5.51966022,1.97449553 L3.69979716,1.97647059 C1.35334686,1.97647059 0,3.32008114 0,5.66653144 L0,15.9675456 C0,18.3042596 1.35334686,19.6478702 3.69979716,19.6478702 Z M11.9951318,15.9383367 C8.97687627,15.9383367 6.54279919,13.5139959 6.54279919,10.4860041 C6.54279919,7.45801217 8.97687627,5.02393509 11.9951318,5.02393509 C15.0133874,5.02393509 17.4377282,7.45801217 17.4377282,10.4860041 C17.4377282,13.5139959 15.0133874,15.9383367 11.9951318,15.9383367 Z M19.5602434,8.62636917 C18.7326572,8.62636917 18.0705882,7.9643002 18.0705882,7.136714 C18.0705882,6.29939148 18.7326572,5.64705882 19.5602434,5.64705882 C20.3878296,5.64705882 21.0498986,6.29939148 21.0498986,7.136714 C21.0498986,7.9643002 20.3878296,8.62636917 19.5602434,8.62636917 Z M11.9951318,13.0174442 C10.5931034,13.0174442 9.47342799,11.8880325 9.47342799,10.4860041 C9.47342799,9.07423935 10.5931034,7.95456389 11.9951318,7.95456389 C13.3971602,7.95456389 14.5168357,9.07423935 14.5168357,10.4860041 C14.5168357,11.8880325 13.3971602,13.0174442 11.9951318,13.0174442 Z"/>
  </svg>
    );
  }
}

export default Photo;