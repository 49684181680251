import React, { Component } from 'react';
import './SubscribeFailed.css';

//Components
import Failed from '../Components/Assets/icons/cross';

class SubscribeFailed extends Component {

  render() {
    return (  
      <main className="subscribe-failed">
        <div className="content-container wide-container">
          <div className="message-container">
            <Failed size='56'/>
            <h5>Subscription purchase cancelled</h5>
            <p></p>
          </div>
        </div> 
      </main>    
    );
  }
}

export default SubscribeFailed;