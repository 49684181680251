import React, { Component } from 'react';

class CashApp extends Component {
  render() {
    return (
        <svg width="190px" height="43px" viewBox="0 0 190 43">
            <defs>
                <linearGradient x1="49.9537465%" y1="99.9537251%" x2="49.9537465%" y2="0%" id="linearGradient-1">
                    <stop stop-color="#00C244" offset="0%"></stop>
                    <stop stop-color="#00D64B" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Cash-App---Dollar---Full" transform="translate(0.000000, 0.780000)">
                    <g id="RGB_Cash_App-link" transform="translate(59.501620, 9.927730)" fill="#1C1D1F" fill-rule="nonzero">
                        <g id="RGB_Cash_App">
                            <path d="M16.5201296,6.32030334 C16.1774116,6.5329749 15.7287624,6.44620786 15.4900509,6.12109001 C14.3316334,4.46515927 12.4180436,3.50288655 10.3979639,3.56046993 C6.5108746,3.56046993 4.12031467,6.63612934 4.12031467,11.0285403 C4.12031467,15.4209512 6.54974549,18.5549169 10.4368348,18.5549169 C12.4903596,18.6020759 14.4228542,17.5862304 15.5483572,15.8679665 C15.7792304,15.5375921 16.2243078,15.4384516 16.573577,15.6396 L18.4150856,16.7085495 C18.6057171,16.8192796 18.7407768,17.0053065 18.7870322,17.2208569 C18.8332877,17.4364072 18.7864444,17.6614691 18.6580287,17.8406643 C16.8231227,20.590919 13.7028376,22.2042899 10.3979639,22.1116037 C4.21263304,22.1116037 0,17.6025801 0,11.0285403 C0,4.45450047 4.21263304,0.00378320824 10.3347987,0.00378320824 C13.5808689,-0.0863531215 16.6604213,1.43886256 18.5559926,4.07550926 C18.6854916,4.25149481 18.7360141,4.47338957 18.6954695,4.68809179 C18.6549248,4.90279402 18.5269616,5.09098332 18.3422027,5.20762402 L16.5201296,6.32030334 Z" id="Path"></path>
                            <path d="M30.1249422,20.9454769 L30.1249422,20.0514463 C29.1531698,21.3050326 27.7926886,22.0824505 25.6110597,22.0824505 C22.3847756,22.0824505 20.1448404,20.3186837 20.1448404,17.2235889 C20.1448404,13.3705116 23.5800555,12.7097064 25.7033781,12.4133158 C27.9724665,12.0829132 29.9160111,11.9274297 29.9160111,10.440618 C29.9160111,9.1238665 28.3903286,8.7691696 27.1027302,8.7691696 C25.6263412,8.79431334 24.1759518,9.16190056 22.8658029,9.84297803 C22.681121,9.94424015 22.4631017,9.96570974 22.2622151,9.90241672 C22.0613285,9.83912369 21.8949923,9.69655576 21.8017122,9.50771657 L21.1214715,8.12779987 C20.9339617,7.75407184 21.0756442,7.29897067 21.4421564,7.0977212 C23.2740996,6.11435558 25.31994,5.59705498 27.3991208,5.59147409 C31.1015733,5.59147409 33.7156409,7.11229778 33.7156409,10.790456 L33.7156409,20.9454769 C33.7156409,21.3748329 33.3675791,21.7228947 32.938223,21.7228947 L30.8975012,21.7228947 C30.4700438,21.7202231 30.1249338,21.3729425 30.1249422,20.9454769 Z M29.9160111,13.9244218 C29.1968996,14.4637554 27.8801481,14.6435333 26.4759371,14.8961941 C25.0717261,15.1488549 23.9104581,15.5229873 23.9104581,17.0486698 C23.9104581,18.4528808 24.9259602,19.1088272 26.3884776,19.1088272 C28.2105507,19.1088272 29.9160111,18.0933251 29.9160111,15.7319183 L29.9160111,13.9244218 Z" id="Shape"></path>
                            <path d="M37.0731143,17.6997573 C37.3458567,17.379531 37.8238309,17.3343173 38.1517816,17.5977212 C39.4487796,18.579113 41.0396475,19.0928486 42.665664,19.0553797 C44.3419713,19.0553797 45.580981,18.516046 45.580981,17.2041534 C45.580981,16.0088734 44.4440074,15.7464949 41.6938917,15.2897619 C38.7785747,14.8038758 35.9847293,13.8321034 35.9847293,10.5086421 C35.9847293,7.04427372 38.9729292,5.58175637 42.3789912,5.58175637 C44.5150358,5.54944125 46.6003248,6.23370344 48.3019435,7.52530102 C48.4709584,7.65592228 48.5783486,7.85069197 48.5986005,8.06333673 C48.6188524,8.27598149 48.5501583,8.48752113 48.4088385,8.64769806 L47.4370662,9.78953055 C47.1695602,10.1074928 46.7001448,10.1608354 46.3681166,9.91100209 C45.1815577,9.03812625 43.7451078,8.57123746 42.2720963,8.579674 C40.8144378,8.579674 39.5851458,8.9975361 39.5851458,10.1296509 C39.5851458,11.388096 41.3489125,11.6261802 43.1126793,11.9225708 C46.8734382,12.583376 49.2639981,13.6571844 49.2639981,16.7814324 C49.2639981,20.0708818 46.635354,22.1018859 42.4615919,22.1018859 C40.1796651,22.1714339 37.9452362,21.4409475 36.1450717,20.0368697 C35.9821505,19.9040621 35.8795291,19.7112779 35.8603343,19.5019629 C35.8411395,19.2926479 35.9069863,19.0844146 36.0430356,18.9241904 L37.0731143,17.6997573 Z" id="Path"></path>
                            <path d="M51.5428043,20.9454769 L51.5428043,1.16991 C51.5428043,0.740553972 51.8908661,0.392492139 52.3202221,0.392492139 L54.5601573,0.392492139 C54.7663414,0.392492139 54.9640806,0.474398429 55.1098748,0.620192559 C55.2556689,0.76598669 55.3375752,0.963725965 55.3375752,1.16991 L55.3375752,7.91886882 C56.4669901,6.40683778 58.2613095,5.53867717 60.1478482,5.59147409 C63.4664507,5.59147409 65.4051365,7.95288085 65.4051365,11.3249308 L65.4051365,20.9454769 C65.4051365,21.3748329 65.0570747,21.7228947 64.6277186,21.7228947 L62.3877834,21.7228947 C61.9584274,21.7228947 61.6103656,21.3748329 61.6103656,20.9454769 L61.6103656,12.1995259 C61.6103656,10.5232187 61.0758908,9.00239496 58.9234151,9.00239496 C56.8924109,9.00239496 55.3375752,10.4357591 55.3375752,12.8263191 L55.3375752,20.9454769 C55.3375752,21.3748329 54.9895134,21.7228947 54.5601573,21.7228947 L52.3202221,21.7228947 C51.8908661,21.7228947 51.5428043,21.3748329 51.5428043,20.9454769 L51.5428043,20.9454769 Z" id="Path"></path>
                            <path d="M90.0541416,21.1932788 L88.4312818,16.4364533 L80.2878297,16.4364533 L78.6746876,21.1932788 C78.5680723,21.5103142 78.270622,21.7236173 77.9361407,21.7228966 L75.3123554,21.7228966 C75.0574401,21.7228966 74.818627,21.5982928 74.6732243,21.388913 C74.5278217,21.1795331 74.4943057,20.9121725 74.5835261,20.6733806 L81.9204072,0.89781375 C82.0403028,0.601373767 82.3294859,0.408585022 82.6492365,0.411884804 L86.2593707,0.411884804 C86.5791212,0.408585022 86.8683044,0.601373767 86.9881999,0.89781375 L94.3007867,20.6782395 C94.3879904,20.9164629 94.353478,21.18225 94.2083254,21.390302 C94.0631729,21.598354 93.8256398,21.7224988 93.5719574,21.7228966 L90.7926886,21.7228966 C90.4582073,21.7236173 90.160757,21.5103142 90.0541416,21.1932788 L90.0541416,21.1932788 Z M84.4081444,3.85686049 L81.2741786,13.1227096 L87.4254975,13.1227096 L84.4081444,3.85686049 Z" id="Shape"></path>
                            <path d="M99.9176307,6.72844772 L99.9176307,7.95288085 C101.03706,6.39870313 102.862811,5.51138845 104.776492,5.59147409 C109.081444,5.59147409 111.860713,9.14816081 111.860713,13.8078091 C111.860713,18.4674574 109.081444,22.0678739 104.776492,22.0678739 C102.857571,22.1404427 101.03111,21.2418238 99.9176307,19.677314 L99.9176307,26.1444588 C99.9176307,26.5738149 99.5695689,26.9218767 99.1402129,26.9218767 L96.9002776,26.9218767 C96.4709216,26.9218767 96.1228598,26.5738149 96.1228598,26.1444588 L96.1228598,6.70901227 C96.1228598,6.27965624 96.4709216,5.93159441 96.9002776,5.93159441 L99.1207774,5.93159441 C99.3337238,5.92620074 99.5395555,6.00842148 99.6901796,6.15904557 C99.8408037,6.30966967 99.9230244,6.51550137 99.9176307,6.72844772 Z M103.979639,18.763848 C106.608283,18.763848 107.895882,16.5239128 107.895882,13.8078091 C107.895882,11.0917055 106.608283,8.90521773 103.979639,8.90521773 C101.350995,8.90521773 99.9176307,11.0868466 99.9176307,13.8078091 C99.9176307,16.5287716 101.321842,18.763848 103.979639,18.763848 Z" id="Shape"></path>
                            <path d="M117.992596,6.72844772 L117.992596,7.95288085 C119.113773,6.40078174 120.938331,5.51404699 122.851458,5.59147409 C127.15155,5.59147409 129.930819,9.14816081 129.930819,13.8078091 C129.930819,18.4674574 127.15155,22.0678739 122.851458,22.0678739 C120.933104,22.1377766 119.10784,21.2397466 117.992596,19.677314 L117.992596,26.1444588 C117.992596,26.5738149 117.644534,26.9218767 117.215178,26.9218767 L114.980102,26.9218767 C114.550746,26.9218767 114.202669,26.5738149 114.202669,26.1444588 L114.202669,6.70901227 C114.201381,6.50243142 114.282868,6.30393395 114.428946,6.15785632 C114.575023,6.0117787 114.773521,5.93029102 114.980102,5.93159441 L117.215178,5.93159441 C117.425195,5.9301695 117.62674,6.01430966 117.773403,6.16463925 C117.920066,6.31496884 117.999205,6.51853025 117.992596,6.72844772 L117.992596,6.72844772 Z M122.059463,18.763848 C124.688107,18.763848 125.946553,16.5239128 125.946553,13.8078091 C125.946553,11.0917055 124.663813,8.90521773 122.059463,8.90521773 C119.455113,8.90521773 117.992596,11.0868466 117.992596,13.8078091 C117.992596,16.5287716 119.401666,18.763848 122.059463,18.763848 Z" id="Shape"></path>
                        </g>
                    </g>
                    <g id="RGB_Dollar" transform="translate(0.000000, -0.000000)">
                        <path d="M27.3456733,0 C31.5826006,0 33.6670523,0 35.9555761,0.719111522 C38.4419255,1.61943562 40.3999998,3.57750992 41.3003239,6.06385932 C42.0194354,8.33294771 42.0194354,10.4368348 42.0194354,14.6543267 L42.0194354,27.3456733 C42.0194354,31.5583063 42.0194354,33.6621934 41.3003239,35.9361407 C40.3999998,38.4224901 38.4419255,40.3805644 35.9555761,41.2808885 C33.6864877,42 31.5826006,42 27.3651087,42 L14.6348913,42 C10.4222582,42 8.31837112,42 6.04442388,41.2808885 C3.55807447,40.3805644 1.60000017,38.4224901 0.699676076,35.9361407 C0,33.6670523 0,31.5826006 0,27.3456733 L0,14.6348913 C0,10.4222582 0,8.31837112 0.719111522,6.04442388 C1.61943562,3.55807447 3.57750992,1.60000017 6.06385932,0.699676076 C8.31351226,0 10.4222582,0 14.6348913,0 L27.3456733,0 Z" id="Path" fill="url(#linearGradient-1)"></path>
                        <path d="M22.0592318,13.765155 C24.123384,13.7683788 26.1198758,14.5015732 27.6955113,15.8350301 C28.0333165,16.1411366 28.5512871,16.1304788 28.8762147,15.8107358 L30.4990745,14.1830171 C30.6634999,14.0197478 30.7522752,13.7952326 30.743972,13.5636648 C30.7356688,13.332097 30.6310414,13.1145166 30.4553447,12.9634429 C29.1807696,11.8791775 27.7069254,11.0539568 26.1163813,10.534012 L26.6022675,8.13859324 C26.653466,7.88927244 26.5896166,7.63015322 26.4284427,7.43316298 C26.2672689,7.23617275 26.0259242,7.12227692 25.7714021,7.12308683 L22.6471541,7.12308683 C22.2483692,7.12269122 21.9028819,7.39948514 21.8162888,7.78875521 L21.3789912,9.93151319 C17.2198056,10.1355854 13.6922721,12.1957427 13.6922721,16.4180935 C13.6922721,20.0670986 16.6075891,21.6365109 19.6881074,22.7346136 C22.6034243,23.8181398 24.1485423,24.2214253 24.1485423,25.7471078 C24.1485423,27.2727904 22.6082832,28.234845 20.3391948,28.234845 C18.1371445,28.2699606 16.0096535,27.4374641 14.4162425,25.917168 C14.0872618,25.5936617 13.5596609,25.5936617 13.2306802,25.917168 L11.4912078,27.6177696 C11.3270143,27.7761862 11.2342699,27.9945414 11.2342699,28.2226978 C11.2342699,28.4508542 11.3270143,28.6692094 11.4912078,28.8276261 C12.9085285,30.1551209 14.6384197,31.1028004 16.5201296,31.5826006 L16.0342434,33.8031004 C15.9823044,34.0542233 16.0444831,34.3154466 16.203991,34.516239 C16.363499,34.7170314 16.6038872,34.8366865 16.8602499,34.8429096 L19.9942156,34.8429096 C20.3962855,34.8450923 20.7449355,34.5653615 20.8299398,34.1723739 L21.281814,32.0684868 C26.267006,31.7623785 29.3135123,29.0802869 29.3135123,25.1543267 C29.3135123,21.5393336 26.2767237,20.0136511 22.5888478,18.7697825 C20.4801018,17.9777881 18.6580287,17.4578899 18.6580287,15.8933364 C18.6580287,14.328783 20.3586303,13.765155 22.0592318,13.765155 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
  }
}

export default CashApp;