import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="ic_boarding_poop" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#8C6E63" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M15.2412327,8.61601498 C16.4850526,8.61601498 17.4933679,9.62433024 17.4933679,10.8681502 C17.4933679,11.1986085 17.4215137,11.51682 17.2896663,11.8050077 C18.1155331,12.1588401 18.6940995,12.9790698 18.6940995,13.9344309 C18.6940995,15.2132844 17.6573839,16.25 16.3785304,16.25 L7.62146958,16.25 C6.34261611,16.25 5.30590053,15.2132844 5.30590053,13.9344309 C5.30590053,12.9790698 5.88446688,12.1588401 6.71033373,11.8050077 C6.57848631,11.51682 6.50663211,11.1986085 6.50663211,10.8681502 C6.50663211,9.62433024 7.51494738,8.61601498 8.7587673,8.61601498 L8.82097077,8.61601498 C8.80596846,8.52274551 8.79824708,8.42751492 8.79824708,8.3310421 C8.79824708,8.22962031 8.80688431,8.12838274 8.82406515,8.02842675 L8.85432765,7.85236334 C9.0196893,6.89030991 9.74535226,6.12096173 10.6961085,5.8997065 C11.5538472,5.70009783 12.4459656,5.70009783 13.3037044,5.8997065 C14.2544606,6.12096173 14.9801236,6.89030991 15.1454852,7.85236334 L15.1757477,8.02842675 C15.2098346,8.22673983 15.2098053,8.42491981 15.1789498,8.61601498 L15.2412327,8.61601498 Z" id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero"></path>
    </g>
  </svg>
);
