import Phone from '../Components/Assets/images/petsitternotifications.png';
import Email from '../Components/Assets/icons/email';
import Messaging from '../Components/Assets/icons/messaging';
import Notifications from '../Components/Assets/icons/notifications';
import Share from '../Components/Assets/icons/share';

export const petsitternotificationsfeature = 
  {
    title: 'Your customers are kept up to date',
    featureimage: Phone,
    features: [
        {
            icon: Email, 
            title: 'Automatic emails'
        },
        {
            icon: Messaging, 
            title: 'Automatic text message'
        },
        {
            icon: Notifications, 
            title: 'Push notifications'
        },
        {
            icon: Share, 
            title: 'Notify your customers with any messaging app using our share buttons'
        }
    ]
  }