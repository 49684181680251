import React, { Component } from 'react';

class Boarding extends Component {
  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
            <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M0,12.8338834 C0,13.5731573 0.607260726,14.1628163 1.41694169,14.1628163 C1.83058306,14.1628163 2.2090209,13.9955996 2.51705171,13.7139714 L11.8459846,5.15071507 C11.8811881,5.10671067 11.9339934,5.08030803 12.0044004,5.08030803 C12.0484048,5.08030803 12.1188119,5.09790979 12.1540154,5.14191419 L21.4917492,13.7139714 C21.7909791,13.9955996 22.1694169,14.1628163 22.5830583,14.1628163 C23.3927393,14.1628163 24,13.5731573 24,12.8338834 C24,12.4642464 23.9207921,12.0946095 23.4543454,11.6721672 L19.8283828,8.35423542 L19.8283828,2.69526953 C19.8283828,2.27282728 19.5555556,2 19.1331133,2 L16.8184818,2 C16.3872387,2 16.1056106,2.27282728 16.1056106,2.69526953 L16.1056106,4.96589659 L13.870187,2.91529153 C13.2981298,2.38723872 12.6908691,2.14081408 11.9867987,2.14081408 C11.30033,2.14081408 10.6930693,2.3960396 10.129813,2.91529153 L0.545654565,11.6721672 C0.0792079208,12.0946095 0,12.4642464 0,12.8338834 Z M3.79317932,20.3234323 L3.79317932,14.1012101 L11.8371837,6.71727173 C11.8811881,6.67326733 11.9427943,6.64686469 12.0044004,6.64686469 C12.0660066,6.64686469 12.1188119,6.67326733 12.1716172,6.71727173 L20.2156216,14.0836084 L20.2156216,20.3234323 C20.2156216,21.7315732 19.2211221,22.6468647 17.6985699,22.6468647 L14.4334433,22.6468647 L14.4334433,15.9933993 C14.4334433,15.5621562 14.1606161,15.2893289 13.7293729,15.2893289 L10.2794279,15.2893289 C9.8569857,15.2893289 9.56655666,15.5621562 9.56655666,15.9933993 L9.56655666,22.6468647 L6.31023102,22.6468647 C4.78767877,22.6468647 3.79317932,21.7315732 3.79317932,20.3234323 Z"/>
        </svg>
    );
  }
}

export default Boarding;