import Phone from '../Components/Assets/images/petsittermessages.png';
import Messaging from '../Components/Assets/icons/messaging';
import App from '../Components/Assets/icons/app';
import Read from '../Components/Assets/icons/read';

export const petsittermessagingfeature = 
  {
    title: 'Instant message your clients',
    featureimage: Phone,
    features: [
        {
            icon: Messaging, 
            title: 'Message your clients in app'
        },
        {
            icon: App, 
            title: 'No need to switch apps to contact your clients'
        },
        {
            icon: Read, 
            title: 'See when they\'ve read your message'
        }
    ]
  }