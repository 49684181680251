import Phone from '../Components/Assets/images/customerappointments.png';
import AddAppointment from '../Components/Assets/icons/addappointment';
import Notifications from '../Components/Assets/icons/notifications';

export const customerappointmentsfeature = 
  {
    title: 'Book appointments with your pet sitter',
    featureimage: Phone,
    features: [
        {
            icon: AddAppointment, 
            title: 'Book walks, drop ins, and pet sitting appointments'
        },
        {
            icon: Notifications, 
            title: 'Walkies will automatically notify you when your appointments are accepted, updated, or cancelled'
        }
    ]
  }