import React from 'react';

export default (props: {}): React.DOM => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Symbols-/-Trash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle" fill="#3CAE2C" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M7.31402439,8.9695122 L7.70426829,17.3414634 C7.7652439,18.4573171 8.39329268,19 9.50304878,19 L14.4969512,19 C15.6128049,19 16.2408537,18.4573171 16.2957317,17.3414634 L16.6920732,8.9695122 L17.2835366,8.9695122 C17.7713415,8.9695122 18.125,8.6402439 18.125,8.15243902 C18.125,7.67682927 17.7713415,7.34756098 17.2835366,7.34756098 L15.0091463,7.34756098 L15.0091463,6.81707317 C15.0091463,5.68292683 14.2408537,5 12.9115854,5 L11.0884146,5 C9.75914634,5 8.99085366,5.68292683 8.99085366,6.81707317 L8.99085366,7.34756098 L6.71646341,7.34756098 C6.2347561,7.34756098 5.875,7.67682927 5.875,8.15243902 C5.875,8.6402439 6.2347561,8.9695122 6.71646341,8.9695122 L7.31402439,8.9695122 Z M10.5396341,6.7804878 C10.5457317,6.47560976 10.7713415,6.27439024 11.1067073,6.27439024 L12.8993902,6.27439024 C13.2286585,6.27439024 13.4542683,6.47560976 13.4603659,6.7804878 L13.472561,7.34756098 L10.527439,7.34756098 L10.5396341,6.7804878 Z M14.0030488,17.2560976 C13.7164634,17.2560976 13.5030488,17.0121951 13.5091463,16.695122 L13.6920732,10.2560976 C13.7042683,9.94512195 13.929878,9.70121951 14.2042683,9.70121951 C14.4847561,9.70121951 14.6981707,9.94512195 14.6920732,10.2560976 L14.4969512,16.7012195 C14.4847561,17.0243902 14.277439,17.2560976 14.0030488,17.2560976 Z M10.0030488,17.2560976 C9.72865854,17.2560976 9.5152439,17.0243902 9.50914634,16.7012195 L9.32012195,10.2560976 C9.31402439,9.94512195 9.52134146,9.70121951 9.79573171,9.70121951 C10.0762195,9.70121951 10.3018293,9.94512195 10.3079268,10.2560976 L10.4908537,16.695122 C10.5030488,17.0121951 10.2896341,17.2560976 10.0030488,17.2560976 Z M12.4969512,16.7073171 C12.4969512,17.0121951 12.2652439,17.2560976 12.0030488,17.2560976 C11.7347561,17.2560976 11.5030488,17.0121951 11.5030488,16.7073171 L11.5030488,10.25 C11.5030488,9.94512195 11.7347561,9.70121951 12.0030488,9.70121951 C12.2713415,9.70121951 12.4969512,9.94512195 12.4969512,10.25 L12.4969512,16.7073171 Z" id="Icon" fill="#FFFFFF"></path>
    </g>
</svg>
);
