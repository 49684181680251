import React, { Component } from 'react';

class Tracking extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M3.50143357,20.4921188 C8.1791829,25.1692937 15.8208171,25.1692937 20.4985664,20.4921188 C25.168454,15.8228047 25.168454,8.1821089 20.4907047,3.504934 C15.8129553,-1.17224091 8.1791829,-1.16438011 3.50929534,3.504934 C-1.16845399,8.1821089 -1.16845399,15.8228047 3.50143357,20.4921188 Z M4.97158336,13.0636646 C4.60208047,12.694207 4.56277166,12.1360904 4.99516865,11.7037465 C5.1366804,11.5622521 5.34894801,11.4286185 5.60052445,11.3185674 L15.0425025,7.20736993 C15.7186142,6.9243812 16.2610759,6.97940679 16.6384405,7.3567251 C17.0236669,7.74190421 17.0786993,8.28429928 16.787814,8.95246712 L12.6839734,18.4011465 C12.5739087,18.6526921 12.432397,18.8570728 12.2908853,18.9985672 C11.8584883,19.4309111 11.3081648,19.3994679 10.9308002,19.0221496 C10.7263943,18.8177688 10.5848826,18.4876153 10.5927443,18.0238282 L10.5848826,13.8183012 C10.6006061,13.6768068 10.5534355,13.5824772 10.4826796,13.5117301 C10.4197855,13.4488437 10.3254444,13.4016789 10.1760709,13.4095397 L5.9778891,13.4095397 C5.51404505,13.4174005 5.18385098,13.2759061 4.97158336,13.0636646 Z"/>
      </svg>
    );
  }
}

export default Tracking;