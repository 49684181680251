import React, { Component } from 'react';
import './ButtonFacebook.css';
import Facebook from './Assets/icons/facebook';

export default class ButtonFacebook extends Component {
    render() {
        return ( 
            <div>
                <button
                    style={{backgroundColor: this.props.color}}
                    className="button-facebook"
                    onClick={this.props.action}>
                    <div className="facebook-logo"><Facebook/></div>
                    {this.props.title}
                </button>
            </div>
        )
    }
}