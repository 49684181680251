import Phone from '../Components/Assets/images/petsitteractivity.png';
import Tracking from '../Components/Assets/icons/tracking.js';
import Poop from '../Components/Assets/icons/poop.js';
import Photo from '../Components/Assets/icons/photo.js';
import Video from '../Components/Assets/icons/video.js';
import Treat from '../Components/Assets/icons/treat.js';
import Notes from '../Components/Assets/icons/notes.js';
import Weather from '../Components/Assets/icons/weather.js';

export const petsittertrackingfeature = 
  {
    title: 'Track walks and drop ins',
    featureimage: Phone,
    features: [
        {
            icon: Tracking, 
            title: 'Track distance, duration, and route in realtime'
        },
        {
            icon: Poop, 
            title: 'Track pees and poops'
        },
        {
            icon: Photo, 
            title: 'Take photos'
        },
        {
            icon: Video, 
            title: 'Shoot videos'
        },
        {
            icon: Treat, 
            title: 'Checklists for food, treats, and medication'
        },
        {
            icon: Notes, 
            title: 'Add notes during or after a walk/drop in'
        },
        {
            icon: Weather, 
            title: 'Weather'
        }
    ]
  }
