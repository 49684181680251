import React, { Component } from 'react';

class ThumbsUp extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'}  viewBox='0 0 24 24'>
      <path fill={this.props.color || "#1AE0B3"} fillRule="evenodd" d="M20.2049934,23.7257681 C21.151117,23.5129355 22.0762155,22.935247 22.0762155,21.8102746 C22.0762155,21.3643396 21.9605782,21.0501582 21.7923784,20.7866511 C21.6977661,20.6346279 21.7187911,20.5231441 21.8449409,20.4623348 C22.6123522,20.1380184 23.2431012,19.4893857 23.2431012,18.5569762 C23.2431012,18.0299622 23.0959264,17.5637574 22.8226018,17.2191713 C22.6859396,17.0570131 22.7279895,16.9151247 22.9277267,16.7935061 C23.4954008,16.4793246 23.8528252,15.8104221 23.8528252,15.0604406 C23.8528252,14.5131567 23.6636005,13.9253333 23.3377135,13.6415565 C23.1590013,13.4895332 23.1800263,13.3679146 23.4007884,13.2057564 C23.7897503,12.9219796 24,12.354426 24,11.7159282 C24,10.5706861 23.0959264,9.64841145 21.9080158,9.64841145 L17.629435,9.64841145 C16.5361367,9.64841145 15.8107753,9.12139738 15.8107753,8.24979719 C15.8107753,6.66875497 17.8501971,3.79044736 17.8501971,1.72293062 C17.8501971,0.648632703 17.1248357,0 16.1681997,0 C15.3061761,0 14.8646518,0.577688501 14.412615,1.45942358 C12.6149803,4.8444755 10.1971091,7.60116448 8.35742444,9.94232315 C6.79106439,11.9591655 6.04467806,13.6415565 6.00262812,16.4083804 C5.91852825,20.6853023 9.52431012,23.9284658 15.1590013,23.9791402 L16.8199737,23.99941 C18.3863338,24.0095449 19.5532194,23.8879263 20.2049934,23.7257681 Z M7.21156373,22.8643028 C5.10906702,21.3136652 4.14191853,18.9826414 4.18396846,16.3779757 C4.2260184,13.509803 5.40341656,11.3612072 6.50722733,10.0943464 L4.66754271,10.0943464 C1.96583443,10.0943464 0,12.9017099 0,16.4995944 C0,19.9758603 2.1130092,22.8643028 5.1195795,22.8643028 L7.21156373,22.8643028 Z"/>
  </svg>
    );
  }
}

export default ThumbsUp;