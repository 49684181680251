import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="ic_boarding_camera" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#F7792D" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M7.35307235,17.5 L16.6469277,17.5 C17.9606046,17.5 18.7182854,16.7477701 18.7182854,15.4395441 L18.7182854,9.67244797 C18.7182854,8.35877106 17.9606046,7.60654113 16.6469277,7.60654113 L15.7257185,7.60654113 C15.305996,7.60654113 15.1424678,7.56838454 14.8154113,7.27948464 L14.4011397,6.89791873 C14.0740833,6.60356789 13.7633796,6.5 13.2346383,6.5 L10.7599108,6.5 C10.2257185,6.5 9.91501487,6.60356789 9.59340932,6.89791873 L9.17368682,7.27948464 C8.88131814,7.54212091 8.71508694,7.59753131 8.37193564,7.60543538 L7.35307235,7.60654113 C6.03939544,7.60654113 5.28171457,8.35877106 5.28171457,9.67244797 L5.28171457,15.4395441 C5.28171457,16.7477701 6.03939544,17.5 7.35307235,17.5 Z M11.9972745,15.4231913 C10.3074827,15.4231913 8.94474727,14.0659068 8.94474727,12.370664 C8.94474727,10.6754212 10.3074827,9.31268583 11.9972745,9.31268583 C13.6870664,9.31268583 15.0443508,10.6754212 15.0443508,12.370664 C15.0443508,14.0659068 13.6870664,15.4231913 11.9972745,15.4231913 Z M16.2326561,11.3295342 C15.7693261,11.3295342 15.398662,10.9588702 15.398662,10.4955401 C15.398662,10.0267592 15.7693261,9.66154609 16.2326561,9.66154609 C16.6959861,9.66154609 17.0666501,10.0267592 17.0666501,10.4955401 C17.0666501,10.9588702 16.6959861,11.3295342 16.2326561,11.3295342 Z M11.9972745,13.7879088 C11.2123389,13.7879088 10.5854807,13.1555996 10.5854807,12.370664 C10.5854807,11.5802775 11.2123389,10.9534192 11.9972745,10.9534192 C12.7822101,10.9534192 13.4090684,11.5802775 13.4090684,12.370664 C13.4090684,13.1555996 12.7822101,13.7879088 11.9972745,13.7879088 Z" id="camera" fill="#FFFFFF" fillRule="nonzero"></path>
    </g>
  </svg>
);
