import React, { Component } from 'react';

class Service extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
      <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M18.6499162,19.5070883 C20.7520938,19.5070883 22,18.2071974 22,16.0174482 L22,3.67284624 C22,1.30861505 20.7688442,0 18.5242881,0 L10.3919598,0 C8.28978224,0 7.04187605,1.29989095 7.04187605,3.48964013 L7.04187605,3.55943293 L8.23115578,3.57688113 C10.0904523,3.57688113 11.0536013,3.87350055 12.3685092,5.19956379 L16.9329983,9.84078517 C18.2562814,11.1842966 18.5242881,12.2050164 18.5242881,14.1330425 L18.5242881,19.5070883 L18.6499162,19.5070883 Z M16.438861,3.63794984 L12.5946399,3.63794984 C12.1172529,3.63794984 11.9078727,3.17557252 11.9078727,2.90512541 L11.9078727,2.6521265 C11.9078727,2.39912759 12.1172529,1.91930207 12.5946399,1.91930207 L16.438861,1.91930207 C16.9162479,1.91930207 17.1423786,2.39912759 17.1423786,2.6521265 L17.1423786,2.90512541 C17.1423786,3.17557252 16.9162479,3.63794984 16.438861,3.63794984 Z M13.5661642,24 C15.6683417,24 16.9162479,22.7001091 16.9162479,20.5103599 L16.9162479,14.1417666 L9.8559464,14.1417666 C8.83417085,14.1417666 8.22278057,13.5049073 8.22278057,12.4405671 L8.22278057,5.09487459 L5.35008375,5.09487459 C3.2479062,5.09487459 2,6.39476554 2,8.58451472 L2,20.5103599 C2,22.7001091 3.2479062,24 5.35008375,24 L13.5661642,24 Z M10.4087102,12.4667394 L16.5979899,12.4492912 C16.5896147,11.9345692 16.1624791,11.4198473 15.5762144,10.8266085 L11.3551089,6.43838604 C10.8274707,5.88876772 10.3500838,5.4438386 9.83919598,5.4263904 L9.83919598,11.8735005 C9.83919598,12.2748092 10.0234506,12.4667394 10.4087102,12.4667394 Z"/>
      </svg>
    );
  }
}

export default Service;