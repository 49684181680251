import reviewOne from '../Components/Assets/images/reviewone.png';
import reviewTwo from '../Components/Assets/images/reviewtwo.png';
import reviewThree from '../Components/Assets/images/reviewthree.png';

export const reviews = [
  {
    src: reviewOne,
    name: 'Joyce',
    subtext: 'Pawpet',
    text: 'I have to say that I’ve tried a lot of apps out there and this is only only one who is seriously good and I know that pet parents love it to. You can add as many clients as you want, pets and reports for only $4.99. Let’s be serious, it’s nothing in comparison to what you have to pay for other apps out there.'
  },
  {
    src: reviewTwo,
    name: 'Haley',
    subtext: 'Haley\'s Pet Sitting',
    text: 'This app makes it such a breeze to keep dog information together and even added the booking calender to help out even more! These app creators really do listen to your feedback and are such caring people. Plus my customers love the notifications they get and I have only heard good feedback about using this app. Seriously you won’t regret giving it a try!'
  },
  {
    src: reviewThree,
    name: 'Cole',
    subtext: 'Dabbin Dawgs',
    text: 'If you’re a pet sitter or dog walker, this is the app you need, stop your search. I’ve tried many (perhaps all of them) and none are nearly as intuitive, well-thought out, or as feature-rich as Walkies for the price. Everything from the report cards, to the clean UI, customizable invoices… it’s all just such a complete dog walker’s app.'
  }
];
