import React, { Component } from 'react';

class Distance extends Component {
  render() {
      return (
          <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
            <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M11.994447,24 C18.6024988,24 24,18.6049954 24,12 C24,5.39500463 18.6024988,0 11.994447,0 C5.38639519,0 0,5.39500463 0,12 C0,18.6049954 5.39750116,24 11.994447,24 Z M8.17399352,17.039778 C7.27441,17.4172063 6.56362795,16.7289547 6.94123091,15.8075856 L9.29569644,10.1905643 C9.48449792,9.74653099 9.76214715,9.46901018 10.2063859,9.28029602 L15.7926886,6.94912118 C16.7478019,6.54949121 17.4252661,7.24884366 17.0365571,8.1813136 L14.7043036,13.7761332 C14.5155021,14.2090657 14.2267469,14.5087882 13.7936141,14.6864015 L8.17399352,17.039778 Z M11.994447,13.5541166 C11.1503933,13.5541166 10.4618232,12.8658649 10.4618232,12.0111008 C10.4618232,11.1674376 11.1503933,10.4791859 11.994447,10.4791859 C12.8496067,10.4791859 13.5381768,11.1674376 13.5381768,12.0111008 C13.5381768,12.8658649 12.8496067,13.5541166 11.994447,13.5541166 Z"/>
          </svg>
        );
    }
}

export default Distance;