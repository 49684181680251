import React, { Component } from 'react';
import Map from './Map';
import { Polyline, Marker } from 'react-google-maps';

function center(coords) {
  return {
    lat: coords.length ? coords[0].lat : 54,
    lng: coords.length ? coords[0].lng : -4
  };
}

export default class ActivityMap extends Component {
  render() {
    const props = this.props;
    return (
      <Map
        fit={map => {
          const bounds = new window.google.maps.LatLngBounds();

          props.coords.forEach(coord => bounds.extend(coord));
          map.fitBounds(bounds);
        }}
        ref={ref => this.map = ref}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_MAPS_KEY}`}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div className="map-container" style={{ height: '500px'}} />}
        mapElement={<div style={{ height: '100%' }} />}
        defaultCenter={center(props.coords)}
      >
        {props.coords.length > 1
          ? <Polyline
              path={props.coords}
              options={{
                strokeColor: "#2f2d5e",
                strokeWeight: 4
              }}
            />
          : <Marker position={props.coords[0]}/>
        }
      </Map>
    );
  }
}
