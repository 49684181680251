import React, { Component } from 'react';
import './Contract.css';

// Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

//Components
import { MoonLoader } from 'halogenium';
import Header from '../Components/Header';
import HeaderBlank from '../Components/HeaderBlank';
import Footer from '../Components/FooterPoweredBy';
import E404 from '../Errors/E404';

//Formatters
import moment from 'moment';

//Assets
import Tick from '../Components/Assets/icons/tick';

class Contract extends Component {

  state = {
    contract: null,
    loading: true,
  }

  componentDidMount() {
    const db = firebase.firestore();
    var that = this;

    db.collection('contracts').doc(window.location.pathname.split('/')[2]).onSnapshot(function(doc) {
        if (doc.exists) {
          const contract = doc.data();
          that.setState({contract, loading: false});
        } else {
          console.log("No such document!");
          that.setState({loading: false});
        }
    })
  }

  sign(event) {
    event.preventDefault();
    const signedBy = event.target[0].value
    const db = firebase.firestore();
    return db.collection("contracts").doc(this.state.contract.id)
    .set({signed: true, 
          signedBy: signedBy,
          signedDate: new Date()
    }, {merge: true})
    .then(() => {
        console.log('Contract signed') 
    }).catch(function(error) {  
        console.log(error)   
    });
  }

  loading() {
    return (
      <article aria-busy={true} className="wide-container loading">
        <MoonLoader
          loading={true}
          color="#1AE0B3"
          size="50"
        />
      </article>
    )
  }

  form() { 
    return (
        <div className="form">
            <h6 className='contract-form-description'>Please enter your full name and sign the contract to accept the terms.</h6>
            <form className="contract-form" onSubmit={this.sign.bind(this)}>
                <input className='contract-form-text-field' type="text" placeholder="Full name" required/>
                <button className='contract-form-button' type="submit">Sign contract</button>
            </form>
        </div>
    )
  }

  signed() { 
    return (
        <div className="contract-signed">
            <Tick size='32'/>
            <h6 className='contract-signed-title'>Contract signed, thank you.</h6>
        </div>
    )
  }

  contract() {
    const contract = this.state.contract;
    const dateCreated = moment(contract.createdDate.toDate()).format('dddd Do MMMM');

    return (
        <div className="content-container wide-container">
            <div className="contract">
                <h6 className='contract-date'>{dateCreated}</h6>
                <h3 className='contract-title'>{contract.title}</h3>
                <p className="content" dangerouslySetInnerHTML={{__html: contract.content}}></p>
                {contract.signed === true ?
                    this.signed()
                :
                    this.form()
                }
            </div>
      </div>       
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <main className="contract">
        <HeaderBlank/>
        {this.loading()}
        <Footer/>
        </main>
      )
    }

    if (this.state.contract) {
      return (
        <main className="contract">
        <Header
          company={true}
          companyID={this.state.contract.companyID || null}
        />
        {this.contract()}
        <Footer/>
        </main>
      )
    }

    return (
      <main className="contract">
      <E404/>
      </main>
    )
  }
}

export default Contract;