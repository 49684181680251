
import React, { Component } from 'react';
import './Bulletin.css';

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';

//Assets
import AppStore from '../Components/Assets/images/appstorebadge.js';
import PlayStore from '../Components/Assets/images/playstorebadge.js';

class Bulletin extends Component {

  render() {
    window.scrollTo(0, 0)
    return (
      <main className="bulletin">
        <div className="bulletin-page">
            <Header/>
            <div className="bulletin-content">
            <div className="bulletin-illustration"/>
            <h5>Sorry, at the moment bulletins can only be viewed in the Walkies Journal app.</h5>
            <div className="badges">
              <a href="https://apps.apple.com/app/walkies-customer-pet-journal/id1488308024">
              <div className="appstore"><AppStore color={'#A940AA'}/></div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.swiftlabs.journal">
              <div className="playstore"><PlayStore color={'#A940AA'}/></div>
              </a>
            </div>
            </div>
            <Footer/>
        </div>
      </main>
    )
  }
}

export default Bulletin;
