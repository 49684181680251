import React, { Component } from 'react';
import './ButtonSecondary.css';

export default class ButtonSecondary extends Component {
    render() {
        return (
            <button
                className="button-secondary"
                onClick={this.props.action}>{this.props.title}
            </button>
        )
    }
}