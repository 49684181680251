import React from 'react';

export default (props: {}): React.DOM => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g id="Symbols-/-Plants" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect id="Rectangle" fill="#3CCE46" x="0" y="0" width="24" height="24" rx="10"></rect>
            <path d="M5.11824375,6.52011962 C4.97628824,7.16200541 4.92074043,8.02608243 4.92074043,8.6124204 C4.92074043,13.4697676 7.84625834,16.6668526 12.3147709,16.6668526 C14.4255876,16.6668526 15.6846713,15.7842596 16.3882769,15.0683101 C16.8635192,15.7904316 17.0980544,16.5125531 17.4066533,17.4877258 C17.511577,17.8271846 17.7646281,18 18.073227,18 C18.6348771,18 19.0792596,17.5124137 19.0792596,16.8766999 C19.0792596,15.975591 17.7461122,14.4017364 17.0733665,13.7721946 C14.1725365,11.0750399 9.82129152,12.7599901 8.84611889,9.95173978 C8.79057108,9.79126833 8.95104252,9.71720459 9.05596616,9.82830021 C11.2223307,12.2538878 14.2280843,10.2788547 16.8511753,12.6735824 C17.1844621,12.9698374 17.5979847,12.8402258 17.6658764,12.482251 C17.6967363,12.3094356 17.7214243,12.0316966 17.7214243,11.7230977 C17.7214243,8.65562425 15.6291235,6.91512626 12.3332868,6.91512626 C11.2840505,6.91512626 10.0496547,7.19903728 9.11768595,7.19903728 C8.16720123,7.19903728 7.09327694,7.14966145 6.17982409,6.22386465 C5.79098943,5.83502999 5.24785531,5.96464154 5.11824375,6.52011962 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
        </g>
    </svg>
);
