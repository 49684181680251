import React, { Component } from 'react';
import './Reviews.css';

//Data
import { reviews } from '../Data/reviews';

//Components
import Review from './Review';

//Assets
import ReviewStars from './Assets/images/reviewstars.svg';

class Reviews extends Component {

  generateReviews(reviews) {
    return reviews.length
      ? <React.Fragment>
          <div className="reviews-content">
            {reviews.map(review => (
              <Review key={review.name} {...review}/>
            ))}
          </div>
        </React.Fragment>
      : null
  }

  render() {
    var numberOfReviews = 3;
    if (window.innerWidth <= 1024 && window.innerWidth > 700) {
      numberOfReviews = 2;
    } else if (window.innerWidth <= 700) {
      numberOfReviews = 3;
    }

    return (
      <div className="reviews wide-container">
        <img className="review-stars" alt="" src={ReviewStars}/>
        <div className="reviews-top">
          <h3>Great reviews</h3>
          <p>4.7 / 5 stars on the App Store</p>
        </div>
        {this.generateReviews(reviews.slice(0, numberOfReviews))}
      </div>
    )
  }
};

export default Reviews;