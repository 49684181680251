import React, { Component } from 'react';
import './Dashboard.css';

//Firebase
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

//Components
import { MoonLoader } from 'halogenium';
import Header from '../Components/Header';
import Footer from '../Components/FooterPoweredBy';
import Walks from '../Components/AllActivities';

class Dashboard extends Component {

    state = {
        user: null
    }
    
    componentDidMount = () => {
        var that = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                that.setState({user: user});
            } else {
                that.setState({user: null});
            }
        });
    };

    loading() {
        return (
          <article aria-busy={true} className="topLoader wide-container">
            <MoonLoader
              loading={true}
              color="#1AE0B3"
              size="50"
              margin="auto"
            />
          </article>
        )
    }

    displayProfile() {
        return (
            <div className="profile">
                <div className="top-container">
                    <div className="header wide-container">
                    <h2 className="title">Walks</h2>
                    </div>
                </div>
                <div className="content wide-container">
                    <Walks type='walks'/>
                </div>
                };
            </div>
        )
    }

    render() {
        window.scrollTo(0, 0)
        if (this.user && this.user !== null) {
            return (
                <main className="dashboard">
                <Header/>
                {this.displayProfile()}
                <Footer/>
                </main>
            )
        } else {
            window.location.href="/login"
            return
        }
    }
  };

  export default Dashboard;
