import React, { Component } from 'react';
import './Header.css';

//Firebase
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

//Assets
import Logo from './Assets/logo/logodark';

//Helpers
import { Link } from 'react-router-dom';
import classnames from 'classnames';

//Components
import SideMenu from '../Components/SideMenu';
import Button from '../Components/ButtonPrimary';

export default class Header extends Component {

  state = {
    user: {}
  }

  componentDidMount = () => {
    var that = this;
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            that.setState({user: user});
        } else {
            that.setState({user: null});
        }
    });
  };

  openDownload() {
    window.location.href="/download"
  }

  render() {
    if (this.state.user) {
      //TODO: Get company logo using company ID an put in nav
      return (
        <header className={classnames('main-header')}>
            <div className="header-content wide-container">
              <Link className="logo" to="/"><Logo/></Link> 
                {window.innerWidth > 800 ?
                <div className="header-links">
                  <Link className="link" to="/walks">Walks</Link>
                  <Link className="link" to="/dropins">Drop ins</Link>
                  <Link className="link" to="/petsittings">Pet sittings</Link>
                  <Link className="link" to="/taxis">Taxis</Link>
                  <Link className="link" to="/appointments">Appointments</Link>
                  <Link className="link" to="/invoices">Invoices</Link>
                </div>
                : 
                <SideMenu/>
                }
            </div>
        </header>
      )
    } else {
      if (!this.props.company) {
        return (
          <header className={classnames('main-header')}>
              <div className="header-content wide-container">
                <Link className="logo" to="/"><Logo/></Link>
                  {window.innerWidth > 800 ?  
                  <div className="header-links">
                    <Link className="link" to="/">Home</Link>
                    <Link className="link" to="/journal">Journal</Link>
                    <Link className="link" to="/about">About</Link>
                    <Link className="link" to="/pricing">Pricing</Link>
                  </div>
                  : 
                  <SideMenu/>
                  }
                  {window.innerWidth > 800 ?  
                  <Button
                      action={this.openDownload}
                      title='Download app'
                  />
                  : null}
              </div>
          </header>
        )
      }

      if (this.props.companyLogo) {
        const imageStyle = {
          backgroundImage: `url(${this.props.companyLogo})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }

        const companyURL = `/company/${this.props.companyID}`

        return (
          <header className={classnames('main-header')}>
              <div className="header-content wide-container">
                <Link to={companyURL}>
                  <div className='header-logo-box'>
                    <div className="header-logo logo" style={imageStyle}></div>
                    <h5>{this.props.companyName || null}</h5>
                  </div>
                </Link>
              </div>
          </header>
        )
      }

      return (
        <header className={classnames('main-header')}>
            <div className="header-content wide-container">
            <Link className="logo" to="/"><Logo/></Link>
            </div>
        </header>
      )
    }
  }
}
