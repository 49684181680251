import React, { Component } from 'react';

class Treat extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'}  viewBox='0 0 24 24'>
      <path fill={this.props.color || "#1AE0B3"} fillRule="evenodd" d="M18.4613227,13.5960012 C20.0635772,14.3343443 21.3033135,14.4502848 22.1805317,13.9438227 C23.9204272,12.9392935 24.5165594,10.7144977 23.5120303,8.97460217 C23.2613534,8.54041716 22.8118557,8.14686116 22.163537,7.79393416 L21.9400762,7.67777815 C21.2447579,7.3324808 20.8241786,6.60401896 20.8727988,5.82920683 C20.9266017,4.97180439 20.8142395,4.30189156 20.5357124,3.81946835 C19.5311832,2.07957281 17.3063874,1.48344057 15.5664919,2.48796973 C14.6892738,2.99443185 14.169813,4.12604525 14.0081096,5.88280994 C13.9507145,6.50649363 13.5940972,7.06348822 13.051689,7.37664775 L7.31057381,10.6912821 C6.76816437,11.0044173 6.10749595,11.0347592 5.53867732,10.7726389 C3.93642279,10.0342958 2.69668647,9.91835535 1.81946835,10.4248175 C0.0795728081,11.4293466 -0.516559429,13.6541424 0.487969729,15.394038 C0.738646561,15.828223 1.18814432,16.221779 1.836463,16.574706 L2.05992381,16.690862 C2.75524209,17.0361593 3.17582138,17.7646212 3.12720115,18.5394333 C3.0733983,19.3968357 3.18576046,20.0667486 3.46428763,20.5491718 C4.46881679,22.2890673 6.69361259,22.8851996 8.43350813,21.8806704 C9.31072625,21.3742083 9.830187,20.2425949 9.99189038,18.4858302 C10.0492855,17.8621465 10.4059028,17.3051519 10.948311,16.9919924 L16.6894262,13.677358 C17.2318356,13.3642228 17.8925041,13.333881 18.4613227,13.5960012 Z"/>
  </svg>
    );
  }
}

export default Treat;