import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle" fill="#8598FF" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M7.35199833,17.4798085 L16.6480017,17.4798085 C17.9912573,17.4798085 18.75,16.7210658 18.75,15.3834305 L18.75,8.61656953 C18.75,7.27893422 17.9912573,6.52019151 16.6480017,6.52019151 L7.35199833,6.52019151 C6.01436303,6.52019151 5.25,7.27893422 5.25,8.61656953 L5.25,15.3834305 C5.25,16.7210658 6.01436303,17.4798085 7.35199833,17.4798085 Z M14.7089925,12.2697752 C13.9333888,12.2697752 13.315154,11.6459201 13.315154,10.8759367 C13.315154,10.1003331 13.9333888,9.48209825 14.7089925,9.48209825 C15.4733555,9.48209825 16.0915903,10.1003331 16.0915903,10.8759367 C16.0915903,11.6459201 15.4733555,12.2697752 14.7089925,12.2697752 Z M8.38051624,10.7466694 C8.11636137,10.7466694 7.90840966,10.5106162 7.90840966,10.2577019 C7.90840966,9.98792673 8.11074105,9.76873439 8.38051624,9.76873439 L11.2075354,9.76873439 C11.4829309,9.76873439 11.6852623,9.98230641 11.6852623,10.2577019 C11.6852623,10.5106162 11.4773106,10.7466694 11.2075354,10.7466694 L8.38051624,10.7466694 Z M8.38051624,12.2191923 C8.11636137,12.2191923 7.90840966,11.9943797 7.90840966,11.7358451 C7.90840966,11.4716903 8.11074105,11.2412573 8.38051624,11.2412573 L10.3644879,11.2412573 C10.6342631,11.2412573 10.8422148,11.4660699 10.8422148,11.7358451 C10.8422148,11.9943797 10.6286428,12.2191923 10.3644879,12.2191923 L8.38051624,12.2191923 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
    </g>
  </svg>
);
