import React, { Component } from 'react';

class Team extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'}  viewBox='0 0 24 24'>
      <path fill={this.props.color || "#1AE0B3"} fillRule="evenodd" d="M16.5881081,11.7664865 C18.6032432,11.7664865 20.1859459,10.0281081 20.1859459,7.84864865 C20.1859459,5.76432432 18.5859459,4 16.5881081,4 C14.5989189,4 12.9902703,5.77297297 12.9988498,7.8572973 C13.0075676,10.0281081 14.5816216,11.7664865 16.5881081,11.7664865 Z M6.45189189,11.9567568 C8.19891892,11.9567568 9.5827027,10.4345946 9.5827027,8.54054054 C9.5827027,6.72432432 8.18162162,5.19351351 6.45189189,5.19351351 C4.72216216,5.19351351 3.31243243,6.73297297 3.32972973,8.54918919 C3.33837838,10.4345946 4.70486486,11.9567568 6.45189189,11.9567568 Z M1.85945946,19.9481081 L8.06054054,19.9481081 C7.0572973,18.5816216 7.74054054,16.0043243 9.98918919,14.1535135 C9.08108108,13.6345946 7.83567568,13.2367568 6.45189189,13.2367568 C2.71567568,13.2367568 0,15.9783784 0,18.1578378 C0,19.2735135 0.458378378,19.9481081 1.85945946,19.9481081 Z M11.3383784,19.9394595 C9.70378378,19.9394595 9.17621622,19.36 9.17621622,18.4432432 C9.17621622,16.2464865 12.1081081,13.2540541 16.5881081,13.2540541 C21.0681081,13.2540541 24,16.2464865 24,18.4432432 C24,19.36 23.4724324,19.9394595 21.8378378,19.9394595 L11.3383784,19.9394595 Z"/>
  </svg>
    );
  }
}

export default Team;