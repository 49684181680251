import React, { Component } from 'react';
import './Footer.css';

//Helpers
import { Link } from 'react-router-dom';

//Assets
import Logo from './Assets/logo/logodark';

export default class Footer extends Component {

  openIntercom(e) {
    e.preventDefault();
    window.Intercom('showNewMessage');
  }

  render() {
    return (
      <footer>
        <div className="footer wide-container">
          <div className="columns">
            <div className="logo-column column">
              <Link className="logo" to="/"><Logo/></Link>
              <p>Copyright 2024 SwiftLab Ltd. All rights reserved.</p>
            </div>
            <div className="company-column column">
              <h6>Company</h6>
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/download">Download</Link>
              <Link to="/">Pet Sitter app</Link>
              <Link to="/journal">Customer app</Link>
            </div>
            <div className="help-column column">
            <h6>Help</h6>
              <a href='' onClick={this.openIntercom}>Contact us</a>
              <Link to="/client-help">Client help</Link>
              <Link to="/pricing">Pricing</Link>
            </div>
            <div className="legal-column column">
              <h6>Legal</h6>
              <a href="https://www.iubenda.com/privacy-policy/78887434">Privacy Policy</a>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </div>
            <div className="social-column column">
              <h6>Follow us</h6>
              <a href="https://www.facebook.com/personalwalkies">Facebook</a>
              <a href="https://twitter.com/personalwalkies">Twitter</a>
              <a href="https://www.instagram.com/personalwalkies">Instagram</a>
            </div>
          </div>
          </div>
      </footer>
    )
  }
}
