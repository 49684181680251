import React, { Component } from 'react';
import './CompanyProfile.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components
import { MoonLoader } from 'halogenium';
import E404 from '../Errors/E404';
import Header from '../Components/Header';
import HeaderBlank from '../Components/HeaderBlank';
import Footer from '../Components/FooterPoweredBy';
import Services from '../Components/Services';
import Contact from '../Components/Contact';
import PhotoSet from '../Components/PhotoSet';
import LatestActivities from '../Components/LatestActivities';
import ReactPlayer from 'react-player'
import Qualifications from '../Components/Qualifications';

class CompanyProfile extends Component {

    state = {
        companyID: '',
        company: null
    }

    componentDidMount() {
        const db = firebase.firestore();
        var that = this;

        if (window.location.pathname.split('/')[1] === 'company') {
            const companyID = window.location.pathname.split('/')[2];
            db.collection(`companies/${companyID}/information`).doc('basic')
            .get()
            .then((doc) => {
                if (doc.exists) {
                  const company = doc.data();
                  that.setState({companyID: companyID, company: company});
                } else {
                  console.log("No such document!");
                  that.setState({companyID: null});
                }
            });
        } else {
            const companyName = window.location.pathname.split('/')[2];
            db.collection(`companyURLs`).doc(companyName).get().then((doc) => {
                if (doc.exists) {
                    const companyID = doc.data().companyID;
                    db.collection(`companies/${companyID}/information`).doc('basic')
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const company = doc.data();
                            that.setState({companyID: companyID, company: company});
                        } else {
                            console.log("No such document!");
                            that.setState({companyID: null});
                        }
                    });
                } else {
                  console.log("No url such document!");
                  that.setState({companyID: null});
                }
            });
        }
    }

    loading() {
        return (
          <article aria-busy={true} className="topLoader wide-container">
            <MoonLoader
              loading={true}
              color="#1AE0B3"
              size="50"
              margin="auto"
            />
          </article>
        )
    }

    companyProfile() {
        var coverImage = {
            backgroundImage: `url(${this.state.company.cover})`,
            backgroundColor: hexToRGBA(`${this.state.company.color || '#1AE0B3'}`, 0.1)
        }

        const themedBackground = {
            backgroundColor: hexToRGBA(`${this.state.company.color || '#1AE0B3'}`, 0.1)
        }

        if (!this.state.company.cover && !this.state.company.profile) {
            coverImage = {
                height: '40px',
                opacity: '0'
            }
        }

        const profileImage = {
            backgroundImage: `url(${this.state.company.profile})`
        }

        var titleBottomPadding;
        if (this.state.company.name && !this.state.company.description) {
            titleBottomPadding = {
                paddingBottom: '40px'
            }
        }

        return (
            <div>
                <div className="top-container wide-container" style={coverImage}>
                    <div className="top-container-profile" style={profileImage}></div>
                </div>
                <div className="content wide-container">
                    <div className="left-column">
                        <div className="top-content">
                            {this.state.company.name ?
                                <h3 className="title" style={titleBottomPadding}>{this.state.company.name}</h3>
                            : null}
                            <div className="paragraphContainer">
                            {this.state.company.description ?
                                this.state.company.description.split('\\n').map(i => {
                                    return <p className="paragraph">{i}</p>
                                })
                            : null}
                            </div>
                        </div>
                        <Services 
                            companyID={this.state.companyID}
                            color={this.state.company.color}
                        />
                        {this.state.company.profileSettings ?
                        this.state.company.profileSettings.displayLatestWalks ?
                        <LatestActivities 
                            companyID={this.state.companyID}
                            color={this.state.company.color}
                            title='Recent walks'
                            url='walk'
                            endpoint='walks'
                            error='No recent walks'
                        />
                        : null
                        : null}
                        {this.state.company.profileSettings ?
                        this.state.company.profileSettings.displayLatestDropins ?
                        <LatestActivities 
                            companyID={this.state.companyID}
                            color={this.state.company.color}
                            title='Recent drop ins'
                            url='checkin'
                            endpoint='checkIns'
                            error='No recent drop ins'
                        />
                        : null
                        : null}
                        {this.state.company.gallery ?
                        this.state.company.gallery.images || this.state.company.gallery.videos ?
                            <div className="gallery">
                            <h5>Gallery</h5>
                            <div className='player-wrapper'>
                            {Array.isArray(this.state.company.gallery.videos)
                            ? [this.state.company.gallery.videos.map((video, key) =>
                            <ReactPlayer
                                className='react-player'
                                url={video.url}
                                playing
                                controls
                                playsinline
                                loop
                                muted
                                width='100%'
                                height='100%'
                            />
                            )] : null}
                            </div>
                            {Array.isArray(this.state.company.gallery.images) ? <PhotoSet key="pp" photos={this.state.company.gallery.images}/> : null}
                            </div>
                        : null
                        : null}
                    </div>
                    <div className="right-column" style={themedBackground}>
                        {this.state.company.location ?
                            <div className="location">
                                <h6>Location</h6>
                                <p>{this.state.company.location}</p>
                            </div>
                        : null}
                        <Contact 
                            color={this.state.company.color}
                            companyID={this.state.companyID}
                        />
                        <Qualifications 
                            companyID={this.state.companyID}
                            color={this.state.company.color}
                        />
                    </div>
                </div>
            </div>
        );
    }

    noSuchCompany() {
        return (
            <div className="content wide-container">
            <div className="left-column">
                <div className="top-container">
                    <h2 className="title">Sorry, that company doesn't exist</h2>
                    <p className="description">The company you're looking for doesn't exist.</p>
                </div>
            </div>
            </div>
        )
    }

    hiddenCompanyProfile() {
        return (
            <div className="content wide-container">
            <div className="left-column">
                <div className="top-container">
                {this.state.company.name ?
                    <h2 className="title">{this.state.company.name}</h2>
                : null}
                <p className="description">This company's profile is set to private</p>
                </div>
            </div>
            </div>
        )
    }

    render() {
    window.scrollTo(0, 0)

    if (this.state.companyID == '')  {
        return (
            <main className="company-profile">
            <HeaderBlank/>
            {this.loading()}
            <Footer/>
            </main>
        )
    }

    if (this.state.companyID == null) {
        return (
            <main className="company-profile">
            <HeaderBlank/>
            {this.noSuchCompany()}
            <Footer/>
            </main>
        )
    }

    if (!this.state.company.profileSettings || this.state.company.profileSettings.hidden == null || this.state.company.profileSettings.hidden === true) {
        return (
            <main className="company-profile">
            <Header
            company={true}
            companyLogo={this.state.company.logo || null}
            companyName={this.state.company.name || null}
            />
            {this.hiddenCompanyProfile()}
            <Footer/>
            </main>
        )
    }

    if (this.state.company) {
        return (
            <main className="company-profile">
            <Header
              company={true}
              companyLogo={this.state.company.logo || null}
              companyName={this.state.company.name || null}
            />
            {this.companyProfile()}
            <Footer/>
            </main>
        )
    }

    return (
        <main className="company-profile">
        <E404/>
        </main>
    )}
  };

  export default CompanyProfile;


function hexToRGBA(hex, opacity) {
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(opacity||1).join(',') + ')';
}