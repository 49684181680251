import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactModal from 'react-modal';
import firebase from 'firebase/compat/app';

firebase.initializeApp({
    apiKey: "AIzaSyD4rKTy-Zm8vM1PnO9TbYqcosVl9GWUC-4",
    authDomain: "walkies-log.firebaseapp.com",
    databaseURL: "https://walkies-log.firebaseio.com",
    projectId: "walkies-log",
    storageBucket: "walkies-log.appspot.com",
    messagingSenderId: "823145412091",
    appId: "1:823145412091:web:4201e7292a5a5fa0e1c75e"
  });

ReactModal.setAppElement('#root');

ReactDOM.render(<App />, document.getElementById('root'));
