import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="ic_boarding_treat" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#1AE0B3" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M15.1294521,15.3490975 C15.9002936,15.9484882 16.2857143,16.5464081 16.2857143,17.1428571 C16.2857143,18.4446712 15.2303855,19.5 13.9285714,19.5 C13.6412233,19.5 13.321316,19.4024114 12.9688494,19.2072341 L12.9688412,19.2072488 C12.3660625,18.873462 11.6339143,18.8734599 11.0311337,19.2072435 C10.6786738,19.4024145 10.3587721,19.5 10.0714286,19.5 C8.76961452,19.5 7.71428571,18.4446712 7.71428571,17.1428571 C7.71428571,16.5464105 8.09970337,15.948493 8.8705387,15.3491047 C9.35783192,14.9702038 9.64285714,14.3875197 9.64285714,13.7702511 L9.64285714,10.229764 C9.6428642,9.61249427 9.35783786,9.02980929 8.87054788,8.65090247 C8.09970643,8.05151178 7.71428571,7.45359191 7.71428571,6.85714286 C7.71428571,5.5553288 8.76961452,4.5 10.0714286,4.5 C10.3587767,4.5 10.678684,4.59758863 11.0311506,4.79276589 L11.0311588,4.7927512 C11.6339375,5.12653805 12.3660857,5.12654006 12.9688663,4.79275655 C13.3213262,4.59758552 13.6412279,4.5 13.9285714,4.5 C15.2303855,4.5 16.2857143,5.5553288 16.2857143,6.85714286 C16.2857143,7.45358954 15.9002966,8.05150703 15.1294613,8.65089533 C14.6421681,9.0297962 14.3571429,9.6124803 14.3571429,10.2297489 L14.3571429,13.770236 C14.3571358,14.3875057 14.6421621,14.9701907 15.1294521,15.3490975 Z" id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-270.000000) translate(-12.000000, -12.000000) "></path>
    </g>
  </svg>
);
