import React, { Component } from 'react';

class Award extends Component {
  render() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
          <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M11.994543,24 C18.6084584,24 24,18.6169245 24,12 C24,5.38307552 18.6084584,0 11.994543,0 C5.38062756,0 0,5.38307552 0,12 C0,18.6169245 5.38062756,24 11.994543,24 Z M8.2946794,17.4704277 C8,17.2411283 7.92360164,16.8917197 8.09822647,16.411283 L9.14597544,13.288444 L6.46111869,11.355778 C6.01364256,11.0391265 5.87175989,10.733394 6.00272851,10.3949045 C6.11186903,10.078253 6.41746248,9.89262966 6.93042292,9.9144677 L10.2373806,9.93630573 L11.2414734,6.78070974 C11.3942701,6.28935396 11.6452933,6.03821656 11.994543,6.03821656 C12.3328786,6.03821656 12.5620737,6.27843494 12.7366985,6.78070974 L13.7626194,9.93630573 L17.058663,9.9144677 C17.5716235,9.89262966 17.899045,10.078253 18.0081855,10.3949045 C18.1173261,10.722475 17.9645293,11.0391265 17.5170532,11.355778 L14.8431105,13.277525 L15.8799454,16.411283 C16.0327422,16.8917197 16,17.2629663 15.7162347,17.4704277 C15.4542974,17.66697 15.1159618,17.6342129 14.6684857,17.2957234 L11.994543,15.3412193 L9.32060027,17.2957234 C8.87312415,17.6342129 8.54570259,17.677889 8.2946794,17.4704277 Z"/>
          </svg>
        );
    }
}

export default Award;