import React, { Component } from 'react';
import './Login.css';

//Firebase
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ButtonFacebook from '../Components/ButtonFacebook';
import ButtonGoogle from '../Components/ButtonGoogle';

class Login extends Component {

    state = {
        user: null
    }

    componentDidMount = () => {
        var that = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                that.setState({user: user});
            } else {
                that.setState({user: null});
            }
        });
    };

    loginWithGoogle() {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            console.log('Sign-in successful.')
            window.location.href="/walks"
          }).catch(function(error) {
          });
    }

    loginWithFacebook() {
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            window.location.href="/walks"
          }).catch(function(error) {
          });
    }

    // loginWithApple() {
    //     var provider = new firebase.auth.OAuthProvider('apple.com');
    //     provider.addScope('email');
    //     provider.addScope('name');
    //     firebase.auth().getRedirectResult()
    //     .then(function(result) {
    //         if (result.credential) {
    //         // You can get the Apple OAuth Access and ID Tokens.
    //         var accessToken = result.credential.accessToken;
    //         var idToken = result.credential.idToken;

    //         // ...
    //         console.log('signed in')
    //         }
    //         console.log(result)
    //         // The signed-in user info.
    //         var user = result.user;
    //     })
    //     .catch(function(error) {
    //         // Handle Errors here.
    //         var errorCode = error.code;
    //         var errorMessage = error.message;
    //         // The email of the user's account used.
    //         var email = error.email;
    //         // The firebase.auth.AuthCredential type that was used.
    //         var credential = error.credential;

    //         // ...
    //     })
    // }

    render() {
        window.scrollTo(0, 0)
        if (this.state.user) {
            window.location.href="/walks"
            return
        } else {
            return (
                <main className="login">
                    <Header/>
                    <div className="top-container">
                        <div className="header wide-container">
                        <h2 className="title">Journal login</h2>
                        <p className="intro">View all of your walk, drop in, and pet sitting reports</p>
                        </div>
                    </div>
                    <div className="content wide-container">
                        <div className="left-column">
                        <ButtonFacebook 
                            title='Continue with Facebook'
                            action={this.loginWithFacebook}
                        />
                        <ButtonGoogle 
                            title='Continue with Google'
                            action={this.loginWithGoogle}
                        />
                        {/* <ButtonSecondary 
                            title='Logout'
                            action={this.logout}
                        /> */}
                        </div>
                    </div>
                    <Footer/>
                </main>
            )
        }
    };
};

export default Login;
