import React, { Component } from 'react';
import './E404.css';

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';

class Error404 extends Component {
  render() {
    window.scrollTo(0, 0)
    return (
      <div className="error-page">
        <Header/>
        <div className="error-page-content">
          <div className="error-page-illustration"/>
          <h5>We couldn't sniff out what you're looking for.</h5>
          </div>
        <Footer/>
      </div>
    )
  }
};

export default Error404;
