import React, { Component } from 'react';
import './Home.css';

//Firebase
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Hero from '../Components/HomeHero';
import Reviews from '../Components/Reviews';
import FeatureCalloutSpecial from '../Components/FeatureCalloutSpecial';
import ShowcaseRight from '../Components/ShowcaseRight';
import ShowcaseLeft from '../Components/ShowcaseLeft';
import ContactUs from '../Components/ContactUs';

//Data
import { petsittertrackingfeature } from '../Data/petsittertrackingfeature';
import { petsitterpetsittingfeature } from '../Data/petsitterpetsittingfeature';
import { petsitternotificationsfeature } from '../Data/petsitternotificationsfeature';
import { petsitterinvoicesfeature } from '../Data/petsitterinvoicesfeature';
import { petsitterschedulefeature } from '../Data/petsitterschedulefeature';
import { petsittercustomersfeature } from '../Data/petsittercustomersfeature';
import { petsittermessagingfeature } from '../Data/petsittermessagingfeature';
import { petsitterteamfeature } from '../Data/petsitterteamfeature';

class Home extends Component {

  state = {
    user: null
  }

  componentDidMount = () => {
      var that = this;
      firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
              that.setState({user: user});
          } else {
              that.setState({user: null});
          }
      });
  };

  render() {
    window.scrollTo(0, 0)
    if (this.state.user) {
      window.location.href="/walks"
      return 
    } else {
      return (
        <main className="home header-offset">
          <Header wide noShadow/>
          <Hero/>
          <Reviews/>
          <div className='home-tracking-showcase'>
            <ShowcaseRight showcase={petsittertrackingfeature}/>
          </div>
          <div className='home-pet-sitting-showcase'>
            <ShowcaseLeft showcase={petsitterpetsittingfeature}/>
          </div>
          <FeatureCalloutSpecial/>
          <div className='home-notification-showcase'>
            <ShowcaseLeft showcase={petsitternotificationsfeature}/>
          </div>
          <div className='home-invoice-showcase'>
            <ShowcaseRight showcase={petsitterinvoicesfeature}/>
          </div>
          <div className='home-appointments-showcase'>
            <ShowcaseLeft showcase={petsitterschedulefeature}/>
          </div>
          <div className='home-clients-showcase'>
            <ShowcaseRight showcase={petsittercustomersfeature}/>
          </div>
          <div className='home-inbox-showcase'>
            <ShowcaseLeft showcase={petsittermessagingfeature}/>
          </div>
          <div className='home-team-showcase'>
            <ShowcaseRight showcase={petsitterteamfeature}/>
          </div>
          <ContactUs
            content='You can instant message me (the founder of Walkies) in app or here with any questions you have or features you’d like me to add at any time. I’m very responsive and everything we do is centered around you.'
          />
          <Footer/>
        </main>
      );
    }
  }
}

export default Home;

