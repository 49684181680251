import React, { Component } from 'react';
import './ButtonGoogle.css';
import Google from './Assets/icons/google';

export default class ButtonGoogle extends Component {
    render() {
        return (
            <div>
            <button
                style={{backgroundColor: this.props.color}}
                className="button-google"
                onClick={this.props.action}>
                <div className="google-logo"><Google/></div>
                {this.props.title}
            </button>
        </div>
        )
    }
}