import React, { Component } from 'react';
import './TnCs.css';

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';

class Terms extends Component {
  render() {
    window.scrollTo(0, 0)
    return (
      <main className="tncs">
        <Header/>
        <div className="jumbo">
          <h2 className="wide-container">Terms & conditions</h2>
        </div>
        <div className="wide-container">
          <h2>1.0 Welcome to Walkies</h2>
          <p>
            1.1. Walkies operates a platform which connects pet Sitters with the
            owners of the pets they walk and/or provide other pet related
            services using our website at www.personalwalkies.com and
            mobile applications (the "Service")
          </p>
          <p>
            1.2. The Service is operated by SwiftLab Ltd ("Walkies", "we", "our",
            or "us").
          </p>
          <h2>2.0 Key definitions used in these Terms of Service</h2>
          <p>
            2.1. Pet Sitter means a person that has registered with our Service
            to allow the Pet Owners whose pets they look after to view information generated by our Service;
          </p>
          <p>
            2.2. Pet Owner means a person who owns a pet that is looked after or
            using our Service.
          </p>
          <h2>3.0 Your relationship with us</h2>
          <p>
            3.1. This document and any documents referred to within it
            (collectively, the "Terms of Service") set out the terms of your
            relationship with us. It is important that you read and understand
            the Terms of Service before using the Service. If there is anything
            within them that you do not understand, then please contact us at
            support@personalwalkies.com to discuss what this means for
            you.
          </p>
          <p>
            3.2. By setting up an account with us or using and accessing the
            Service you agree to these Terms of Service. If you do not agree
            to these Terms of Service, please do not browse or otherwise
            access or use the Service.
          </p>
          <p>
            3.3. Walkies is not party to any agreement you, as a Pet Sitter or
            Pet Owner, may enter into with another Pet Sitter or Pet
            Owner. The Service merely facilitates the viewing of walk and
            check in reports and other services and content generated by Pet Sitters for the viewing of Pet
            Owners.
          </p>
          <h2>4.0 Information about us</h2>
          <p>
            4.1. SwiftLab Ltd is incorporated and registered in the United Kingdom
            under company number 10265897. Our registered office is at The
            Shippon, Rindle Road, Astley, M29 7LG, United Kingdom.
          </p>
          <h2>5.0 Information about you</h2>
          <p>
            5.1. Your privacy is important to us. You should read our Privacy
            Policy https://www.iubenda.com/privacy-policy/78887434 to
            understand how we collect, use and share information about you.
          </p>
          <h2>6.0 Setting up an account</h2>
          <p>
            6.1. In order for Pet Sitters, and Pet Owners to access our Service and use certain
            features and areas of the Service, they must register with us and
            set up an account with an ID (associated with your email address)
            and password or by connecting your Facebook or other social
            media account to the Service as made available from time to time
            (your "Account"). We encourage you to use strong passwords
            with your Account.
          </p>
          <p>
            6.2. You must be 16 years or older and capable in your country of
            residence of entering into a legally binding agreement to use our
            Service.
          </p>
          <p>
            6.3. You are responsible for maintaining the confidentiality of your
            login details and any activities that occur under your Account. If
            you have any concerns that your Account may have been
            misused, you should contact us at support@personalwalkies.com
            straight away to let us know.
          </p>
          <h2>7.0 Your Content</h2>
          <p>
            7.1. You confirm that any images, text or information that you make
            available or create ("User Content") whilst using the Service will
            meet the Rules of Acceptable Use.
          </p>
          <p>
            7.2. We do not claim ownership of your User Content. Instead, you
            grant us a perpetual, irrevocable, worldwide, non-exclusive,
            royalty-free licence to use, copy, reproduce and make available
            the User Content anywhere and in any form for the purposes of
            providing our Service (including allowing our users to view and
            use your User Content).
          </p>
          <p>
            7.3. We will also occasionally publish media, including but not limited
            to images and video, contained in your User Content in the
            context of sharing media updates of pets on our website, mobile
            application and across third party social media applications. If you
            object to our use of the media contained in your User Content in
            this way, please contact us as support@personwalkies.com and
            we will remove them.
          </p>
          <p>
            7.4. Our right to use your User Content does not in any way affect
            your privacy rights and we will only use information that identifies
            you as set out in our <a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/78887434">Privacy Policy</a>.
          </p>
          <p>
            7.5. We do not check or moderate any User Content before it is added
            to the Service by users. We may later check, moderate, reject,
            refuse or delete any User Content if anybody objects to it, or we
            think that it breaks any of the Rules of Acceptable Use.
          </p>
          <h2>8.0 Walkies Plus and Walkies Pro</h2>
          <p>
            8.1. Walkies Pro can be purchased via an Apple in app purchase and a Stripe purchase. Walkies Pro gives a Pet Sitter more features; specifically they can shoot upto 6 videos on walks, drops ins, and pet sittings, take unlimited photos on walks, drop ins, and pet sittings and send automatic text messages to their customers as an alternative to emails, and track weather conditions on walks and check ins.
          </p>
          <p>
            8.2. Upon purchasing Walkies Pro via Stripe your chosen payment method will be chrged per month when your free trial ends. If you have previously had a free trial you will not get another upon a subsequent purchase of Walkies Pro. Your subscription will automatically renew.
          </p>
          <p>
            8.3. Upon purchasing Walkies Pro via an Apple in app purchase your iTunes account will be charged per month when your free trial ends. If you have previously had a free trial you will not get another upon a subsequent purchase of Walkies Pro. Your subscription will automatically renew unless auto-renew is turned off at least 24-hours before the end of the current period. You can turn off auto-renew by going to your iTunes account.
          </p>
          <p>
            8.4. We retain the right to change the Walkies Plus and Walkies Pro services at any point. See point 15 for more details.
          </p>
          <h2>9.0 Your right to use the Service</h2>
          <p>
            9.1. The materials and content comprising the Service (excluding User
            Content) belongs to us or our third party licensors and we give
            you permission to use these materials and content for the sole9.
            purpose of using the Service in accordance with these Terms of
            Service.
          </p>
          <p>
            9.2. Your right to use the Service is personal to you and you are not
            allowed to give this right to another person or to sell, gift or
            transfer your Account to another person. Your right to use the
            Service does not stop us from giving other people the right to use
            the Service.
          </p>
          <p>
            9.3. Other than as allowed in these Terms of Service you are not given
            a right to use the "Walkies" name, or any of the "Walkies"
            trademarks, logos, domain names and other distinctive brand
            features.
          </p>
          <p>
            9.4. Unless allowed by these Terms of Service and as permitted by the
            functionality of the Service, you agree:
            not to copy any portion of our Service;
          </p>
          <ul>
            <li>
              9.4.1. not to give or sell or otherwise make available any portion of our Service to anybody else;
            </li>
            <li>
              9.4.2 not to change our Service in any way;
            </li>
            <li>
              9.4.3 not to look for or access the code of our Service that
              we have not expressly published publicly for general use.
            </li>
          </ul>
          <p>
            9.5. You agree that you have no rights in or to any portion of the
            Service other than the right to use them in accordance with these
            Terms of Service.
          </p>
          <h2>10.0 Rules of Acceptable Use</h2>
          <p>
            10.1. In addition to the other requirements within these Terms of
            Service, this section describes specific rules that apply to your
            use of the Service (the "Rules of Acceptable Use").
          </p>
          <p>
            10.2. When using the Service you must not:
          </p>
          <ul>
            <li>
              10.2.1. circumvent, disable or otherwise interfere with any
              security related features of the Service or features that
              prevent or restrict use or copying of the content accessible
              via the Service;
            </li>
            <li>
              10.2.2. create more than one account on the Service
              (however, you may connect all your social networks or other
              services accounts, that we support, to your account on the
              Service);
            </li>
            <li>
              10.2.3. give any false or misleading information or permit
              another person, other than an employee of yours that is
              under an appropriate duty of confidentiality, to use the
              Service under your name or on your behalf;
            </li>
            <li>
              10.2.4.
              impersonate any person, or misrepresent your identity
              or affiliation with any person or give the impression they are
              linked to Walkies, if this is not the caseuse the Service other than for its intended purpose as
              set out in these Terms of Service or if we have suspended
              or banned you from using it;
            </li>
            <li>
              10.2.5.
              send junk, spam or repetitive messages, advocate,
              promote or engage in any illegal or unlawful conduct
              (including fraud or the sale of counterfeit or stolen items) or
              conduct that causes damage or injury to any person or
              property;
            </li>
            <li>
              10.2.7.
              modify, interfere, intercept, disrupt or hack the Service
              or collect any data from the Service other than in
              accordance with these Terms of Service;
            </li>
            <li>
              10.2.8.
              misuse the Service by knowingly introducing viruses,
              Trojans, worms, logic bombs or other material which would
              harm the Service or any user of the Service's own
              equipment;
            </li>
            <li>
              10.2.9.
              submit or contribute any User Content that contains
              nudity or violence or is abusive, threatening, obscene,
              misleading, untrue, inaccurate or offensive;
            </li>
            <li>
              10.2.10. submit or contribute any User Content without the
              permission of the content owner or otherwise infringe the
              copyright, trademark or other rights of third parties;
            </li>
            <li>
              10.2.11. use any User Content in violation of any licensing
              terms specified by the owner;
            </li>
            <li>
              10.2.12. submit or contribute any information or commentary
              about another person without that person's permission;
            </li>
            <li>
              10.2.13. threaten, abuse or invade another's privacy, or cause
              annoyance, inconvenience or needless anxiety or be likely
              to harass, upset, embarrass, alarm or annoy any other
              person in particular any Walkies staff member;
            </li>
            <li>
              10.2.14. use any automated system, including without limitation
              "robots", "spiders" or "offline readers" to access the Service
              in a manner that send more request messages to the
              Service than a human can reasonably produce in the same
              period of time.
            </li>
          </ul>
          <p>
            10.3.Failure to comply with the Rules of Acceptable Use constitutes a
            serious breach of these Terms of Service, and may result in our
            taking all or any of the following actions (with or without notice):
            immediate, temporary or permanent withdrawal of
          </p>
          <ul>
            <li>
              10.3.1.
              your right to use our Service;
            </li>
            <li>
              10.3.2.
              immediate, temporary or permanent removal of any
              User Content;
            </li>
            <li>
              10.3.3
              issuing of a warning to you;
            </li>
            <li>
              10.3.4.
              legal action against you including proceedings for
              reimbursement of all costs (including, but not limited to,
              reasonable administrative and legal costs) resulting from the
              breach;
            </li>
            <li>
              10.3.5.
              disclosure of such information to law enforcement
              authorities as we reasonably feel is necessary.
            </li>
          </ul>
          <p>
            10.4. The responses described in this clause 10.3 are not limited, and
            we may take any other action we reasonably deem appropriate.
          </p>
          <h2>11.0 Notice and takedown policy</h2>
          <p>
            11.1.
            Any person may contact us by sending us an
            "Infringement Notice" if any content available through our
            Service infringes their rights or fails to comply with our Rules of
            Acceptable Use. The Infringement Notice should be sent by email
            to support@personalwalkies.com. Please provide the information
            described below in the Infringement Notice:
          </p>
          <ul>
            <li>
              11.1.1. your name and contact details;
            </li>
            <li>
              11.1.2. a statement explaining in sufficient detail why you
              consider that the content available through our Service
              infringes your rights or fails to comply with our Rules of
              Acceptable Use; and
            </li>
            <li>
              11.1.3. a link to or such other means of identifying the
              problematic content.
            </li>
          </ul>
          <p>

            We will take the action that we believe is appropriate
          </p>
          <p>
            11.2.
            depending on the nature of the Infringement Notice and will aim
            respond to you within a reasonable period of time on the action
            we propose to take.
          </p>
          <h2>12.0 Advertisements on the Service</h2>
          <p>
            12.1.
            We and our selected business partners may provide
            advertising to you through the Service or by other methods such
            as email. This advertising may be based on your User Content or
            other information available through the Service. When delivering
            advertising we will only use information that identifies you as set
            out in our <a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/78887434">Privacy Policy</a>.
          </p>
          <h2>13.0 Ending our relationship</h2>
          <p>
            13.1.
            If at any time you do not feel that you can agree to these
            Terms of Service or any changes made to the Terms of Service or
            the Service, you must immediately stop using the Service.
          </p>
          <p>
            13.2.
            If you wish to end your use of the Service, you may
            deactivate your account by sending an email to
            support@personalwalkies.com and we will deactivate your
            account. If you deactivate your account, you will not be able to13.
            access the account or data previously stored in the account. For
            information on our retention of this data, please refer to our <a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/78887434">Privacy Policy</a>.
          </p>
          <p>
            13.3.
            We may immediately end your use of the Service if you
            break the Rules of Acceptable Use, any other important rule(s), or
            terms and conditions we set for accessing and using the Service
            including these Terms of Service.
          </p>
          <p>
            13.4.
            We may also withdraw the Service as long as we give you
            reasonable notice that we plan to do this so that you have a
            reasonable period of time to download any of your User Content.
          </p>
          <p>
            13.5.
            If you or we end your use of the Service or we withdraw the
            Service as described in this section, we may delete or modify your
            User Content, account or any other information we hold about
            you. You will also lose any rights you have to use the Service or
            access our content or your User Content. We will not offer you
            compensation for any losses.
          </p>
          <p>
            13.6.
            The termination of your use of the Service and the
            cancellation of your Account shall not affect any of your
            outstanding obligations to us.
          </p>
          <h2>14.0 Liability</h2>
          <p>
            14.1.
            You acknowledge that Walkies is not a party to any
            agreement between Pet Sitters and Pet Owners. Accordingly,
            Walkies will not be liable for any breach of a contract between
            Pet Sitters and Pet Owners. For the avoidance of doubt, the
            Pet Sitter is solely responsible for carrying out the Pet Sitting
            Services. Walkies assumes no liability for any loss or damage
            suffered by a Pet Owner, including harm caused to their pet,
            which is caused by the Pet Sitter’s action or inaction.
          </p>
          <p>
            14.2.
            Pet Sitters agree and accept liability for any loss or
            damage we may suffer in respect of any breach by Pet Sitters
            of these Terms of Service or any agreement with Pet Owners.
          </p>
          <p>
            14.3.
            Pet Sitters agree to indemnify us for any loss or damage
            we may suffer as a result of any Pet Owner bringing any claim
            against us or taking any action against us as a result of any
            breach by Pet Sitters of these Terms of Service or any
            agreement with Pet Owners.
          </p>
          <p>
            14.4.
            Pet Owners agree to indemnify us for any loss or damage
            we may suffer as a result of any claim or action brought against
            us as a result of any breach by Pet Owner of these Terms of
            Service or any agreement with Pet Owners.14.
          </p>
          <p>
            14.5.
            Our Service makes available third party content such as
            User Content. As we do not produce such third party content, we
            cannot be responsible for it in any way. In particular, we cannot
            verify any claims made by a Pet Owner or Pet Sitter.
          </p>
          <p>
            14.6.
            We will use reasonable endeavours to ensure that the
            Service is reasonably available during normal business hours.
          </p>
          <p>
            14.7.
            Unfortunately, due to the nature of the Internet and
            technology, the Service is provided on an "as available" and "as
            is" basis. This means that we are unable to promise that your use
            of the Service will be uninterrupted, without delays, error-free or
            meet your expectations and we do not give any commitment
            relating to the performance or availability of the Service in these
            Terms of Service and, to the extent we are able to do so, we
            exclude any commitments that may be implied by law.
          </p>
          <p>
            14.8.
            In every case, we will never be responsible for any loss or
            damage that is not reasonably foreseeable.
          </p>
          <h2>15.0 Changes to the Service</h2>
          <p>
            15.1.
            We are constantly updating and improving the Service to try
            and find ways to provide you with new and innovative features
            and services. Improvements and updates are also made to reflect
            changing technologies, tastes, behaviours and the way people
            use the Internet and our Service.
          </p>
          <p>
            15.2.
            In order to do this, we may need to update, reset, stop
            offering and/or supporting a particular part of the Service, or
            feature relating to the Service ("changes to the Service"). These
            changes to the Service may affect your past activities on the
            Service, features that you use, your Profile and your User Content
            ("Service elements"). Any changes to the Service could involve
            your Service elements being deleted or reset.
          </p>
          <p>
            15.3.
            You agree that a key characteristic of our Service is that
            changes to the Service will take place over time and this is an
            important basis on which we grant you access to the Service.
            Once we have made changes to the Service, your continued use
            of the Service will show that you have accepted any changes to
            the Service. You are always free to stop using the Service or
            deactivate your Account in the settings feature of the Service.
          </p>
          <p>
            15.4.
            We will try, where possible and reasonable, to contact you
            to let you know about any significant changes to the Service.
          </p>
          <h2>16.0 Changes to the documents</h2>
          <p>
            16.1.
            We may revise these Terms of Service from time to time and
            the most current version will always be at
            www.personalwalkies.com/terms-and-conditions.
          </p>
          <p>
            16.2.
            Changes will usually occur because of new features being
            added to the Service, changes in the law or where we need to
            clarify our position on something.
          </p>
          <p>
            16.3.
            We will try, where possible and reasonable, to contact you
            to let you know about any significant changes to any of the
            documents referred to in these Terms of Service. We may contact
            you through the Service (for example by asking you to accept the
            changes before you continue to use the Service) or via a separate
            email.
          </p>
          <p>
            16.4.
            Normally, we will try to give you some warning before the
            new terms become effective. However, sometimes changes will
            need to be made immediately and if this happens we will not give
            you any notice.
          </p>
          <h2>17.0 Documents that apply to our relationship with you</h2>
          <p>
            17.1.
            The current version of the Terms of Service contains the
            only terms and conditions that apply to our relationship with you.
            Older versions of the Terms of Service will no longer apply to our
            relationship and will be completely replaced by the current
            version.
          </p>
          <p>
            17.2.
            We intend to rely on these Terms of Service as setting out
            the written terms of our agreement with you for the provision of
            the Service. If part of the Terms of Service cannot be enforced
            then the remainder of the Terms of Service will still apply to our
            relationship.
          </p>
          <p>
            17.3.
            If you do not comply with these Terms of Service and we do
            not take action immediately, this does not mean we have given up
            any right we have and we may still take action in the future.
          </p>
          <h2>18.0 Severability</h2>
          <p>
            18.1.
            If any provision of these Terms of Service is judged to be
            illegal or unenforceable, this will not affect the continuation in full
            force and effect of the remainder of the provisions.
          </p>
          <h2>19.0 Law</h2>
          <p>
            19.1.
            English law will apply to all disputes and the interpretation of
            these Terms of Service. The English courts will have non-
            exclusive jurisdiction over any dispute arising from or related to
            your use of the Service. If you are resident in the EU, nothing in
            this clause shall affect your rights under the law of the country in
            which you live, including your right to have a dispute in relation to
            your use of the Service heard in the courts of that country.
          </p>
          <h2>20.0 Contact, feedback and complaints</h2>
          <p>
            20.1.
            If you need to contact us in relation to these Terms of
            Service or any other document mentioned in them, please email
            us at support@personalwalkies.com.
          </p>
          <p>
            20.2.
            We value hearing from our users, and are always interested
            in learning about ways we can improve the Service. By providing
            your feedback you agree that you are giving up any rights you
            have in your feedback so that we may use and allow others to use
            it without any restriction and without any payment to you.
          </p>
          <h2>22.0 Connecting Pet Owners using the Journal app</h2>
          <p>
            22.1. To connect a Pet Owner's record within a Pet Sitter's app to a Pet Owner's app a link is 
            shared by the Pet Sitter to the corresponding Pet Owner. This link allows any user of the Journal app
            to connect their app to a Pet Owner's record. Pet Sitters and Pet Owners 
            are responsible for all data shared and/or changed when/after connecting a Pet Owner to a Pet Sitter's customer record. 
          </p>
          <p>
            22.2. Walkies will not be liable for any incorrectly connected customer records and/or data shared and/or data changed that 
            has happened due to the connecting of a Pet Owner's record within a Pet Sitter's app to a Pet Owner's app.
          </p>
          <h2>21.0 Invoices</h2>
          <p>
            21.1. Invoices allows a Pet Sitter to be paid via the Walkies website. 
          </p>
          <p>
            22.2. We take a percentage of the invoice amount to cover the transaction fees we incur from our payment provider Stripe.
          </p>
          <p>
            22.3. We retain the right to change the Invoices service at any point. See point 15 for more details.
          </p>
        </div>
        <Footer/>
      </main>
      )
    }
  };

  export default Terms;
