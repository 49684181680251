import React, { Component } from 'react';
import './Boarding.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components
import Header from '../Components/Header';
import HeaderBlank from '../Components/HeaderBlank';
import Footer from '../Components/FooterPoweredBy';
import { MoonLoader } from 'halogenium';
import E404 from '../Errors/E404';
import BoardingUpdates from '../Components/BoardingUpdates';
import Leaderboard from '../Components/Leaderboard';
import IconWithTitle from '../Components/IconWithTitle';
import ActivityMap from '../Components/ActivityMap';

//Icons
import TeamMember from '../Components/Assets/icons/teammember.js';
import Tick from '../Components//Assets/icons/tick';
import Cross from '../Components//Assets/icons/cross';

//Formatters
import moment from 'moment';

class Boarding extends Component {

  state = {
    boarding: null
  }

  componentDidMount() {
    const db = firebase.firestore();
    var that = this;

    db.collection('boarding').doc(window.location.pathname.split('/')[2]).onSnapshot(function(doc) {
        if (doc.exists) {
          const boarding = doc.data();
          that.setState({boarding});
        } else {
          console.log("No such document!");
          that.setState();
        }
    });
  }

  loading = (): React.DOM => {
    return (
      <article aria-busy={true} className="wide-container loading">
        <MoonLoader
          loading={true}
          color="#1AE0B3"
          size="50"
          margin="auto"
        />
      </article>
    )
  }

  boarding = () => {
    const boarding = this.state.boarding;

    var title = 'Boarding';
    if (boarding.boarding === false) {
      title = "Daycare"
    }

    if (boarding.type === 'daycare') {
      title = "Daycare"
    }

    if (boarding.type === 'overnight') {
      title = "Overnight stay"
    }

    if (boarding.type === 'homeSitting') {
      title = "Home Sitting"
    }

    if (boarding.type === 'dropIn') {
      title = "Drop in"
    }

    if (boarding.type === 'training') {
      title = "Training"
    }

    if (boarding.type === 'grooming') {
      title = "Grooming"
    }

    const start = moment(boarding.startTime.toDate());
    
    var notes;
    if (boarding.endTime) {
      notes = boarding.notes
    }

    return (
      <div>
        <div className="top-container">
          <div className="header wide-container">
            <div className="header-left">
            <h5 className="typeTitle">{title}</h5>
            <h3 className="title">{start.format('dddd Do MMMM')}</h3>
            {boarding.teamMembers && boarding.teamMembers[0] ? (
                <IconWithTitle
                  icon={<TeamMember/>}
                  title={`by ${boarding.teamMembers[0].name}`}
                />
            ) : ( null )}
            {boarding.additionalChecklistItems && boarding.additionalChecklistItems.length > 0 ? (
              boarding.additionalChecklistItems.map((checklistItem, index) => (
                <IconWithTitle icon={checklistItem.completed ? <Tick/> : <Cross/>} title={checklistItem.title}/>
              ))
            ) : ( null )} 
          </div>
          <div className="header-right">
            {boarding.coordinates && boarding.coordinates.length > 0 ? (
            <ActivityMap coords={boarding.coordinates}/>
            ) : ( null ) }
          </div>
          </div>
        </div>
        <div className="wide-container">
          {notes ? (
            <div>
              <h5 className='boarding-details-title'>{'Notes'}</h5>
              <p className="activity-notes">
                {notes}
              </p>
            </div>
          ) : null}
        {boarding.pets ?
        <Leaderboard
            boarding={true}
            displayNotes={boarding.endTime != null}
            dogs={boarding.pets}
          />
          : null}
        </div>
        <div className="wide-container updates">
          <BoardingUpdates
            updates={
              boarding.updates.sort(function (a, b) {
                return new Date(a.time.toDate()) < new Date(b.time.toDate())
              })
            }
          />
        </div>
      </div>
    );
  }

  render() {
    window.scrollTo(0, 0)
    if (!this.state.boarding) {
      return (
        <main className="boarding">
        <HeaderBlank/>
        {this.loading()}
        <Footer/>
        </main>
      )
    }

    if (this.state.boarding) {
      return (
        <main className="boarding">
        <Header
          company={true}
          companyID={this.state.boarding.companyID || null}
          companyLogo={this.state.boarding.companyLogo || null}
          companyName={this.state.boarding.companyName || null}
        />
        {this.boarding()}
        <Footer/>
        </main>
      )
    }

    return (
      <main className="boarding">
      <E404/>
      </main>
    )
  }
}

export default Boarding;
