import React, { Component } from 'react';

class Messaging extends Component {
  render() {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M6.98338075,22.6041314 C7.44773746,22.6041314 7.82075067,22.4214288 8.37645087,21.9190163 L12.1369996,18.5999844 L18.120396,18.5999844 C21.1425732,18.6075925 23,16.7197134 23,13.7280196 L23,5.87198036 C23,2.88028218 21.1425732,1 18.120396,1 L5.87196477,1 C2.85742682,1 1,2.87267412 1,5.87198036 L1,13.7280196 C1,16.7273259 2.99444986,18.5999844 5.66642242,18.5999844 L5.91002066,18.5999844 L5.91002066,21.4165881 C5.91002066,22.1473828 6.3134817,22.6041314 6.98338075,22.6041314 Z M8.64288888,10.2339089 C8.64288888,11.1397981 7.8511985,11.9086487 6.95291733,11.9086487 C6.03181198,11.9086487 5.26296138,11.1397981 5.26296138,10.2339089 C5.26296138,9.32803523 6.02420392,8.55156098 6.95291733,8.55156098 C7.8511985,8.55156098 8.64288888,9.32803523 8.64288888,10.2339089 Z M13.7051643,10.2339089 C13.7051643,11.1397981 12.9439217,11.9086487 12.0304244,11.9086487 C11.1169272,11.9086487 10.3556846,11.1397981 10.3556846,10.2339089 C10.3556846,9.32803523 11.1093191,8.55156098 12.0304244,8.55156098 C12.9439217,8.55156098 13.7051643,9.32803523 13.7051643,10.2339089 Z M18.7979499,10.2339089 C18.7979499,11.1397981 18.0290369,11.9086487 17.1003235,11.9086487 C16.2096504,11.9086487 15.4179756,11.1397981 15.4179756,10.2339089 C15.4179756,9.32803523 16.2020111,8.55156098 17.1003235,8.55156098 C18.0290369,8.55156098 18.7979499,9.32803523 18.7979499,10.2339089 Z"/>
    </svg>
    );
  }
}

export default Messaging;