import React from 'react';

export default function(props) {
  return (
    <span
      onClick={e => props.onClick(e, {index: props.index, photo: props.photo.src})}
      className="photo"
      style={{
        width: props.photo.width,
        height: props.photo.height
      }}
    >
      <img {...props.photo} alt=""/>
    </span>
  );
}
