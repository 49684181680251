import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="ic_boarding_wee" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#E8DD62" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M15.401574,7.39297921 L17.1476077,10.2060335 C18.7291931,12.7541433 17.9456703,16.1019227 15.3975605,17.6835081 C14.5377207,18.2172018 13.5458515,18.5 12.5338468,18.5 L11.4654103,18.5 C8.46636446,18.5 6.03515746,16.068793 6.03515746,13.0697472 C6.03515746,12.0577426 6.31795571,11.0658733 6.85164937,10.2060335 L8.59768309,7.39297921 C9.76386125,5.51413661 12.2323383,4.93640794 14.1111809,6.1025861 C14.6349047,6.42765602 15.0765041,6.86925546 15.401574,7.39297921 Z" id="Rectangle" fill="#FFFFFF"></path>
    </g>
  </svg>
);
