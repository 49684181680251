import Phone from '../Components/Assets/images/customerreports.png';
import Tracking from '../Components/Assets/icons/tracking';
import Poop from '../Components/Assets/icons/poop';
import Photo from '../Components/Assets/icons/photo';
import Treat from '../Components/Assets/icons/treat';
import Notes from '../Components/Assets/icons/notes';
import Weather from '../Components/Assets/icons/weather';

export const customerreportsfeature = 
  {
    title: 'See what your pet gets up to during their walks and drop-ins',
    featureimage: Phone,
    features: [
        {
            icon: Tracking, 
            title: 'Track distance, duration, and route in realtime '
        },
        {
            icon: Poop, 
            title: 'See if they peed and pooped'
        },
        {
            icon: Photo, 
            title: 'View and save photos and videos'
        },
        {
            icon: Treat, 
            title: 'See if they ate, had treats, or their medication'
        },
        {
            icon: Notes, 
            title: 'Your pet sitter can leave notes for anything else'
        },
        {
            icon: Weather, 
            title: 'See what the weather was like during the walk'
        }
    ]
  }