import React, { Component } from 'react';
import './About.css';

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ContactUs from '../Components/ContactUs';

//Assets
import UsImageOne from '../Components/Assets/images/usimageone.jpeg';
import UsImageTwo from '../Components/Assets/images/usimagetwo.jpeg';
import UsImageThree from '../Components/Assets/images/usimagethree.jpeg';

class About extends Component {
  render() {
    window.scrollTo(0, 0)
    return (
      <main className="about">
        <Header/>
        <div className="top-container">
            <div className="header wide-container">
            <h2 className="title">About</h2>
            <p className="intro">In short, I'm one person that has designed, coded, and supported Walkies from the ground up, oh, and of course I love dogs.</p>
            </div>
        </div>
        <div className="content wide-container">
            <div className="left-column">
                <p>Hello there! It’s Rob here. I founded Walkies in 2018 after wondering what my family's naughty Dalmatians got up to when they were with their dog walker. Daisy, our oldest Dally, is a reserved rescue dog who would rather keep to herself, whereas Gatsby is boisterous, full of energy, and usually up to no good. I wanted to create something that would allow our family to be up-to-date with their day and keep in communication with our walker without hounding them for constant updates.</p>
                <p>Whether you’re a walker or owner, we all share a common obsession...dogs... and view them as not only our companions but our family. We know how nervous you can be trusting a new person to walk your best friend, or what it’s like to be trusted with that responsibility as a sitter. From your snuggly stay-at-home Pugs who don’t fancy the rain, to your adventurous puddle-raiding Retrievers. Each pet has their own needs and their own requirements. I wanted to keep communication key so that you as a dog walker and your clients are on the same track, and all in one simple walk report.</p>
                <p>We started Walkies by talking to local dog walkers to understand exactly what features they would like to see. We then approached online groups who all gave us amazing ideas that Rob developed and introduced. That has very much been our way of working and we will continue to listen, learn, and develop features that you suggest. We have an instant chat box set-up so that you are always a click away from talking with us. We also wanted to be more affordable than the other dog walking apps out there, and worked hard to make the experience clear and easy to use while you’re working, so that you can quickly update information without taking too much attention away from the pups.</p>
                <p>So far I have had an amazing response to Walkies, with many saying it has improved their business. We know from our experience as dog owners that being able to see exactly the route our walker took, pictures and videos from that day, or just the satisfaction of knowing they dropped by to check that Gatsby hasn’t eaten the sofa, gives us that relief of knowing our babies are in good hands.</p>
                <p>It’s not just about the dogs either. All pets need looking after, from furry, hooved, and even scaled, Walkies is there for all your pet sitting needs.</p>
            </div>
            <div className="right-column">
              <img src={UsImageOne} alt=""></img>
              <img src={UsImageTwo} alt=""></img>
              <img src={UsImageThree} alt=""></img>
            </div>
        </div>
        <ContactUs
          content='You can instant message me, at any time, in app or here with any questions you have or features you’d like me to add.'
        />
        <Footer/>
      </main>
      )
    }
  };

  export default About;
