import React, { Component } from 'react';
import moment from 'moment';
import Note from './Assets/icons/notetype';
import Picture from './Assets/icons/picturetype';
import Video from './Assets/icons/videotype';
import Start from './Assets/icons/starttype';
import End from './Assets/icons/endtype';
import Walk from './Assets/icons/walktype';
import Food from './Assets/icons/foodtype';
import Treat from './Assets/icons/treattype';
import Wee from './Assets/icons/weetype';
import Water from './Assets/icons/watertype';
import Poo from './Assets/icons/pootype';
import Medication from './Assets/icons/medicationtype';
import WalkMap from '../Components/SmallMap';
import ReactPlayer from 'react-player'
import './BoardingUpdate.css';
import Package from './Assets/icons/packagetype';
import Taxi from './Assets/icons/taxitype';
import Mail from './Assets/icons/mailtype';
import Plants from './Assets/icons/plantstype';
import LitterBox from './Assets/icons/litterboxtype';
import Clean from './Assets/icons/cleantype';
import Trash from './Assets/icons/trashtype';
import Supplement from './Assets/icons/supplementtype';
import Haircut from './Assets/icons/haircut';
import Bath from './Assets/icons/bath';
import Drying from './Assets/icons/drying';
import Dried from './Assets/icons/dried';
import Nails from './Assets/icons/nails';
import PrivateAreasCut from './Assets/icons/privateAreasCut';

class BoardingUpdate extends Component {
  render() {
    const time = moment(this.props.update.time.toDate())
    const notes = this.props.update.notes
    const type = this.props.update.type
    const formattedTime = time.format('ddd Do HH:mm')

    if (type === "start") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Start/>
                    </div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "end") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <End/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "pee") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Wee/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "poop") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Poo/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "water") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Water/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "treat") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Treat/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "medication") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Medication/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "supplement") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Supplement/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "mail") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Mail/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "plants") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Plants/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "litterBox") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <LitterBox/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "clean") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Clean/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "trash") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Trash/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "breakfast" || type === "lunch"  || type === "dinner" ) {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Food/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "picture") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Picture/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="picture">
                            <img alt="" src={this.props.update.picture}/>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "video") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-video">
                        <Video/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="video">
                        <ReactPlayer
                            className='react-player'
                            url={this.props.update.video.url}
                            playing
                            controls
                            playsinline
                            loop
                            muted
                            width='100%'
                            height='100%'
                        />
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "note") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Note/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "noteWithPicture") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Picture/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="picture">
                            <img alt="" src={this.props.update.picture}/>
                        </div>
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div>
                </div>
            </div> 
        )
    } else if (type === "packages") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Package/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "haircut") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Haircut/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "feetCut") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Haircut/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "faceCut") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Haircut/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "deshed") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Haircut/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "privateAreasCut") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <PrivateAreasCut/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "bathed") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Bath/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "drying") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Drying/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "dried") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Dried/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "nails") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Nails/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "taxi") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Taxi/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="map">
                            <WalkMap coords={this.props.update.taxi.walkCoordinates}/>
                        </div>
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                        <div className="button">
                            <a href={`https://personalwalkies.com/taxi/${this.props.update.taxi.id}`}>
                                Open full Taxi report
                            </a>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else if (type === "walk") {
        return (
            <div className="update-container">
                <div className="status">
                    <div className="type-image">
                        <Walk/>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="update">
                    <div className="update-content">
                        <div className="map">
                            <WalkMap coords={this.props.update.walk.walkCoordinates}/>
                        </div>
                        <div className="update-notes">
                            <p className="notes"> {notes}</p>
                        </div>
                        <div className="button">
                            <a href={`https://personalwalkies.com/walk/${this.props.update.walk.id}`}>
                                Open full Walk report
                            </a>
                        </div>
                    </div>
                    <div className="update-time">
                        <p className="time"> {formattedTime}</p>
                    </div> 
                </div>
            </div> 
        )
    } else {
        return (
            <div >
            </div>
        ) 
    }
  }
}

export default BoardingUpdate;