import Team from '../Components/Assets/icons/team.js';
import AddAppointment from '../Components/Assets/icons/addappointment.js';
import ThumbsUp from '../Components/Assets/icons/thumbsup.js';
import Timeline from '../Components/Assets/icons/schedule.js';
import Notifications from '../Components/Assets/icons/notifications.js';


export const petsitterteamfeature = 
  {
    title: 'For the whole team',
    features: [
        {
            icon: Team, 
            title: 'Add your team members to Walkies'
        },
        {
        icon: AddAppointment, 
            title: 'Manage their schedule and assign them appointments'
        },
        {
        icon: ThumbsUp, 
            title: 'Restrict what a team member can access'
        },
        {
        icon: Timeline, 
            title: 'See all of a team member\'s activity on a timeline'
        }
        ,
        {
        icon: Notifications, 
        title: 'Push notifications when your team members start and end walks, drop ins, and pet sittings'
        }
    ]
  }
