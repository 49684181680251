import React, { Component } from 'react';

class Phone extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M18.1614911,23 C19.6658558,23 20.9995124,22.4769529 22.0664378,21.2920912 C22.6852544,20.6089277 22.9946628,19.872392 22.9946628,19.0931587 C22.9946628,18.356623 22.6959237,17.6414362 21.991753,17.1290636 L18.6736153,14.7593401 C18.0868063,14.3430374 17.6066899,14.1615721 17.1799198,14.1615721 C16.6251187,14.1615721 16.1450023,14.4711305 15.6115396,15.004852 L14.7793379,15.8374575 C14.6619761,15.9655507 14.491268,16.0189229 14.3418985,16.0189229 C14.1711904,16.0189229 13.9791439,15.9548763 13.8511128,15.8694808 C13.1256036,15.4425036 11.8879703,14.3643862 10.7570294,13.2328967 C9.62608859,12.1014071 8.54849402,10.8524988 8.12172389,10.137312 C8.03636987,10.0092188 7.97235435,9.81707909 7.97235435,9.64628821 C7.97235435,9.49684619 8.02570061,9.32605531 8.1430624,9.20863658 L8.97526414,8.37603105 C9.5087268,7.84230956 9.8288044,7.36196021 9.8288044,6.80688986 C9.8288044,6.37991266 9.64742709,5.89956332 9.22065697,5.31246967 L6.85208277,1.99272198 C6.36129712,1.28820961 5.63578791,1 4.89960944,1 C4.11008471,1 3.37390624,1.29888404 2.70174329,1.91800097 C1.51745619,2.97476953 0.994662785,4.3197477 0.994662785,5.81416788 C0.994662785,9.20863658 3.64063757,13.3716642 7.1401526,16.8515284 C10.6183291,20.3527414 14.7793379,23 18.1614911,23 Z"/>
      </svg>
    );
  }
}

export default Phone;