import React, { Component } from 'react';

class Read extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M11.9666334,21.6564222 C16.3517933,21.6564222 20.0416676,19.5286874 23.0362563,15.2732178 L23.0362575,15.2732186 C24.4958327,13.1990848 24.4936179,10.4312444 23.0307251,8.35944915 C20.0371243,4.11981638 16.3490937,2 11.9666334,2 C7.67252309,2 4.01397663,4.10801762 0.990994054,8.32405286 L0.990998076,8.32405574 C-0.50550261,10.4111666 -0.507740954,13.2192834 0.985430569,15.3087773 C4.00948751,19.5405406 7.66988847,21.6564222 11.9666334,21.6564222 Z M11.9950901,16.5045852 C9.39605053,16.5045852 7.26180273,14.3608518 7.26180273,11.8376966 C7.26180273,9.22917156 9.39605053,7.15183704 11.9950901,7.15183704 C14.5751585,7.15183704 16.7094063,9.23865711 16.7094063,11.8376966 C16.7094063,14.3608518 14.5751585,16.5045852 11.9950901,16.5045852 Z M11.9666334,13.8676034 C13.1048989,13.8676034 14.0155113,12.9664766 14.0155113,11.8471822 C14.0155113,10.7373733 13.1048989,9.79830431 11.9666334,9.79830431 C10.8378535,9.79830431 9.95569773,10.7373733 9.95569773,11.8471822 C9.95569773,12.9664766 10.8378535,13.8676034 11.9666334,13.8676034 Z"/>
  </svg>
    );
  }
}

export default Read;