import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="ic_boarding_food" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#1AE0B3" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M7.46988996,8.5 L16.53011,8.5 C17.4362114,8.5 18.2291114,9.10916787 18.4625799,9.9846747 L19.2625799,12.9846747 C19.5471862,14.0519483 18.912709,15.1478636 17.8454353,15.4324699 C17.6773287,15.4772983 17.5040912,15.5 17.33011,15.5 L6.66988996,15.5 C5.56532046,15.5 4.66988996,14.6045695 4.66988996,13.5 C4.66988996,13.3260188 4.69259163,13.1527814 4.73742008,12.9846747 L5.53742008,9.9846747 C5.77088857,9.10916787 6.56378856,8.5 7.46988996,8.5 Z" id="Rectangle" fill="#FFFFFF"></path>
    </g>
  </svg>
);
