import React, { Component } from 'react';
import './Services.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components
import { MoonLoader } from 'halogenium';
import IconWithTitleAndDescription from '../Components/IconWithTitleAndDescription';

//Assets
import Walk from './Assets/icons/tracking';
import DropIn from './Assets/icons/dropin';
import Daycare from './Assets/icons/daycare';
import HomeSitting from './Assets/icons/homesitting';
import OvernightStay from './Assets/icons/overnightstay';
import Boarding from './Assets/icons/boarding';
import Service from './Assets/icons/service';
import Taxi from './Assets/icons/taxi';
import Training from './Assets/icons/training';
import Grooming from './Assets/icons/grooming';

class Services extends Component {

    state = {
        services: null
    }

    componentDidMount() {
        const db = firebase.firestore();
        var that = this;

        db.collection(`companies/${this.props.companyID}/services`)
        .orderBy('type')
        .onSnapshot(function(querySnapshot) {
            var services = []
            querySnapshot.forEach(function(service) {
                if (service.data().hidden !== true && (!service.data().customers || service.data().customers.length == 0)) {
                    services.push(service.data()) 
                }
            });
            services = services.sort(function(a, b) {
                return a.position-b.position;
            });
            that.setState({services});
        });
    }

    loading() {
        return (
          <article aria-busy={true} className="loader">
            <MoonLoader
              loading={true}
              color="#1AE0B3"
              size="50"
              margin="auto"
            />
          </article>
        )
    }

    generateServices(services) {
        return services.length
            ? <React.Fragment>
            <div className="company-services">
                {services.map(service => (
                    (service.type === 'walk' ?
                        <IconWithTitleAndDescription 
                            icon={<Walk color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'boarding' ?
                        <IconWithTitleAndDescription 
                            icon={<Boarding color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'dropin' ?
                        <IconWithTitleAndDescription 
                            icon={<DropIn color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'daycare' ?
                        <IconWithTitleAndDescription 
                            icon={<Daycare color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'homeSitting' ?
                        <IconWithTitleAndDescription 
                            icon={<HomeSitting color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'overnight' ?
                        <IconWithTitleAndDescription 
                            icon={<OvernightStay color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'taxi' ?
                        <IconWithTitleAndDescription 
                            icon={<Taxi color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'training' ?
                        <IconWithTitleAndDescription 
                            icon={<Training color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'grooming' ?
                        <IconWithTitleAndDescription 
                            icon={<Grooming color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    :
                    service.type === 'other' ?
                        <IconWithTitleAndDescription 
                            icon={<Service color={this.props.color}/>}
                            title={`${service.title} - ${Intl.NumberFormat(service.country || 'US', { style: 'currency', currency: service.currency }).format(service.amount / 100)}`}
                            description={service.description}
                        />
                    : null)
                ))}
                </div>
            </React.Fragment>
            : null
    }

    render() {
        var that = this;

        if (!this.state.services) {
            return (
                <div className="services wide-container">
                    {that.loading()}
                </div>
            )
        }

        if (this.state.services.length > 0) {
            return (
                <div className="services wide-container">
                <h5>Services</h5>
                {that.generateServices(this.state.services)}
                </div>
            )
        }

        return (
            <div className="services wide-container">
            <h4>Services</h4>
            <h6>No services added</h6>
            </div>
        )
    } 
};

export default Services;