
import Phone from '../Components/Assets/images/customerreports.png';
import Tracking from '../Components/Assets/icons/tracking.js';
import Messaging from '../Components/Assets/icons/messaging.js';
import Photo from '../Components/Assets/icons/photo.js';
import Notifications from '../Components/Assets/icons/notifications.js';
import AddAppointment from '../Components/Assets/icons/addappointment.js';
import Invoice from '../Components/Assets/icons/invoice.js';

export const whatiswalkiescustomershowcase = 
  {
    title: 'Download the app',
    subtitle: 'If you’d like to be able to see all of your pet’s reports in one place and much more then you can download the customer app',
    featureimage: Phone,
    features: [
        {
            icon: Tracking, 
            title: 'Track walk distance, duration, and route in realtime'
        },
        {
            icon: Messaging, 
            title: 'Instant message your pet sitter in app'
        },
        {
            icon: Photo, 
            title: 'View and save photos and videos'
        },
        {
            icon: Notifications, 
            title: 'Stay up to date with your pet with push notifications'
        },
        {
            icon: AddAppointment, 
            title: 'Book walks, drop ins, and pet sitting appointments'
        },
        {
            icon: Invoice, 
            title: 'Easily see which invoices you have paid and which you haven’t'
        }
    ]
  }
