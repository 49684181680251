import React, { Component } from 'react';
import './ContactUs.css';

//Components
import ContactUsBox from '../Components/ContactUsBox';

export default class ContactUs extends Component {
  render() {
    return (
      <div>
          <div className="contact-us wide-container">
            <div className="contact-us-left-column">
                <h3>I'm here if you need me</h3>
                <p>{this.props.content}</p>
            </div>
            <div className="contact-us-right-column">
                <ContactUsBox/>
            </div>
          </div>
      </div>
    )
  }
}