import Tick from '../Components/Assets/icons/tick.js';

export const pricetierpro = 
  {
    title: 'Pro',
    price: '$14.99 per month + $4.99 for each additional team member',
    features: [
        {
            icon: Tick, 
            title: 'Add team members'
        },
        {
            icon: Tick, 
            title: 'Unlimited customers'
        },
        {
            icon: Tick, 
            title: 'Unlimited photos'
        },
        {
            icon: Tick, 
            title: '6 videos per walk/drop in'
        },
        {
            icon: Tick, 
            title: '6 videos per pet sitting per day'
        },
        {
            icon: Tick, 
            title: 'Weather'
        },
        {
            icon: Tick, 
            title: 'Automatic text messages keep your customers up to date'
        }
    ]
  }
