import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="ic_boarding_video" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#F7792D" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M6.84883721,17.0261628 L12.5348837,17.0261628 C14.0697674,17.0261628 14.8837209,16.2122093 14.8837209,14.7819767 L14.8837209,9.21802326 C14.8837209,7.7877907 14.0813953,6.97383721 12.5523256,6.97383721 L6.84883721,6.97383721 C5.3255814,6.97383721 4.5,7.7877907 4.5,9.22383721 L4.5,14.9389535 C4.5,16.369186 5.31395349,17.0261628 6.84883721,17.0261628 Z M15.9244186,14.375 L15.9244186,9.75290698 L17.5,8.42151163 C17.872093,8.10755814 18.1918605,7.89825581 18.5581395,7.89825581 C19.0988372,7.89825581 19.5,8.26453488 19.5,8.875 L19.5,15.252907 C19.5,15.8633721 19.0988372,16.2296512 18.5581395,16.2296512 C18.1976744,16.2296512 17.8662791,16.0203488 17.5,15.7063953 L15.9244186,14.375 Z" id="video" fill="#FFFFFF" fillRule="nonzero"></path>
    </g>
  </svg>
);
