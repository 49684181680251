import React, { Component } from 'react';
import './IconWithTitleAndDescription.css';

class IconWithTitle extends Component {
    render() {
        return (
            <div className="icon-with-title-and-description">
                <div className="type-image">
                    {this.props.icon}
                </div>
                <div className="icon-with-title-and-description-content">
                <h6>{this.props.title}</h6>
                    <p>{this.props.description}</p>
                </div>
            </div>
        )
    }
}

export default IconWithTitle;