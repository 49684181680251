import React, { Component } from 'react';
import './SideMenu.css';

//Firebase
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

//Assets
import MenuIcon from './Assets/images/menu.svg';
import CloseIcon from './Assets/images/close.svg';

//Components
import { slide as Menu } from 'react-burger-menu'
import ButtonPrimary from './ButtonPrimary';

export default class SideMenu extends Component {

  state = {
    user: {}
  }

  componentDidMount = () => {
    var that = this;
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            that.setState({user: user});
        } else {
            that.setState({user: null});
        }
    });
  };

  openDownload() {
    window.location.href="/download"
  }

  openIntercom(e) {
    e.preventDefault();
    window.Intercom('show', {
        app_id: 'ylflg7ex',
    });
  }

  render() {
    if (this.state.user) {
      return (
        <div className='side-menu' id="side-menu-container">
          <Menu className="menu"
            customBurgerIcon={ <img src={MenuIcon} alt="Menu"/> }
            customCrossIcon={ <img src={CloseIcon} alt="Close"/> }
            right
            disableAutoFocus
            width={ '80%' }
          >
            <a id="walks" href="/walks">Walks</a>
            <a id="dropins" href="/dropins">Drop ins</a>
            <a id="petsittings" href="/petsittings">Pet sittings</a>
            <a id="taxis" href="/taxis">Taxis</a>
            <a id="appointments" href="/appointments">Appointments</a>
            <a id="invoices" href="/invoices">Invoices</a>
            <a onClick={ this.openIntercom } href="">Help</a>
            <ButtonPrimary
                  action={this.openDownload}
                  title='Download app'
              />
          </Menu>
        </div>
      )
    } else {
      return (
          <div className='side-menu' id="side-menu-container">
              <Menu className="menu"
                customBurgerIcon={ <img src={MenuIcon} alt="Menu"/> }
                customCrossIcon={ <img src={CloseIcon} alt="Close"/> }
                right
                disableAutoFocus
                width={ '80%' }
              >
                <a id="home" className="menu-item-top" href="/">Home</a>
                <a id="journal" href="/journal">Journal</a>
                <a id="about" href="/about">About</a>
                <a id="pricing" href="/pricing">Pricing</a>
                <a onClick={ this.openIntercom } href="">Help</a>
                <ButtonPrimary
                      action={this.openDownload}
                      title='Download app'
                  />
              </Menu>
          </div>
      )
    }
  }
}
