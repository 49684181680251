import React, { Component } from 'react';
import './PhotoSet.css';

//Components
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import Photo from './Photo';
import { MoonLoader } from 'halogenium';

export default class PhotoSet extends Component {
  constructor(props) {
    super(props);

    if (!this.props.photos.length) {
      this.setState({ resolving: false });
    }

    const promises = this.props.photos.map (
      photo => new Promise(resolve => {
        const img = new Image();

        img.addEventListener('load', e => {
          resolve({
            src: photo,
            width: img.width,
            height: img.height
          });
        });

        img.src = photo;
      }
    ));

    Promise.all(promises).then(photos => this.setState({ photos, resolving: false }));
  }

  state = {
    current: 0,
    photos: [],
    resolving: true
  };

  open = (event, obj) => {
    this.setState({
      current: obj.index,
      open: true,
    });
  }

  close = () => {
    this.setState({
      current: 0,
      open: false,
    });
  }

  prev = () => {
    this.setState({
      current: this.state.current - 1,
    });
  }

  next = () => {
    this.setState({
      current: this.state.current + 1,
    });
  }

  render() {
    var columnNumber = 3;
    var margin = 4;
    if (window.innerWidth <= 1024 && window.innerWidth > 600) {
      columnNumber = 3;
    } else if (window.innerWidth <= 600) {
      columnNumber = 2;
      margin = 0;
    }

    return (
      <div>
        {this.state.resolving
            ?
              <div className="loading">
                <MoonLoader
                  loading={true}
                  color="#1AE0B3"
                  size="50"
                  margin="auto"
                />
              </div>
            : null
        }
        <Gallery
          margin={margin}
          photos={this.state.photos}
          onClick={this.open}
          columns={columnNumber}
          ImageComponent={Photo}
          direction={'row'}
        />
        <Lightbox images={this.state.photos}
          onClose={this.close}
          onClickPrev={this.prev}
          onClickNext={this.next}
          currentImage={this.state.current}
          isOpen={this.state.open}
        />
      </div>
    )
  }
}
