import React, { Component } from 'react';

class Duration extends Component {
  render() {
      return (
          <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
            <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M6.33877551,24 L18.5591837,24 C19.9997599,24 20.8220747,23.2515783 20.9138329,22.0201598 L20.9346939,21.2571429 C20.9346939,17.7306122 16.9306122,14.8285714 15.7428571,13.6163265 L15.4498194,13.2931217 C15.1395314,12.9238095 14.9469388,12.5619048 14.9469388,12 C14.9469388,11.277551 15.2530612,10.8979592 15.7428571,10.3836735 L16.0378191,10.0966904 C17.4805081,8.74798001 20.922449,6.0516035 20.922449,2.74285714 L20.922449,2.20408163 C20.922449,0.844897959 20.0897959,0 18.5591837,0 L6.33877551,0 C4.82040816,0 4,0.844897959 4,2.19183673 L4,2.74285714 C4,6.30612245 8.01632653,9.14693878 9.17959184,10.3836735 C9.66938776,10.9102041 9.9755102,11.277551 9.9755102,12 C9.9755102,12.722449 9.65714286,13.1142857 9.17959184,13.6163265 C8.00408163,14.8285714 4,17.7306122 4,21.2571429 L4,21.8081633 C4,23.155102 4.82040816,24 6.33877551,24 Z M7.20816327,3.90612245 L17.7142857,3.90612245 C18.1795918,3.90612245 18.277551,4.26122449 17.9959184,4.7755102 C16.9918367,6.6244898 13.4040816,9.77142857 12.4612245,9.77142857 C11.5306122,9.77142857 8.04081633,6.56326531 6.93877551,4.7755102 C6.63265306,4.28571429 6.75510204,3.90612245 7.20816327,3.90612245 Z"/>
        </svg>
        );
    }
}

export default Duration;