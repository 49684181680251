import React, { Component } from 'react';

class Notifications extends Component {
  render() {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M18.3725681,7.25486381 C20.3521401,7.25486381 22,5.60700389 22,3.62743191 C22,1.64785992 20.3521401,0 18.3725681,0 C16.3929961,0 14.7451362,1.64785992 14.7451362,3.62743191 C14.7451362,5.60700389 16.3929961,7.25486381 18.3725681,7.25486381 Z M14.2422179,21.9892996 C16.6391051,21.9892996 18.1157588,21.6254864 19.2392996,20.5019455 C20.3521401,19.3784047 20.7159533,17.9338521 20.7159533,15.5155642 L20.7159533,8.63521401 C19.9990272,8.97762646 19.2071984,9.17023346 18.3725681,9.17023346 C15.3336576,9.17023346 12.8297665,6.66634241 12.8297665,3.62743191 C12.8297665,2.79280156 13.0223735,1.99027237 13.364786,1.28404669 L6.47373541,1.28404669 C4.07684825,1.28404669 2.60019455,1.65856031 1.48735409,2.78210117 C0.36381323,3.90564202 0,5.33949416 0,7.7577821 L0,15.5155642 C0,17.9338521 0.36381323,19.3891051 1.4766537,20.5019455 C2.61089494,21.6254864 4.07684825,21.9892996 6.47373541,21.9892996 L14.2422179,21.9892996 Z"/>
    </svg>
    );
  }
}

export default Notifications;