import Tick from '../Components/Assets/icons/tick.js';

export const pricingfeatures = 
  {
    title: 'All of our plans include',
    features: [
        {
            icon: Tick, 
            title: '2 week free trial - See if Walkies is right for you without paying a penny'
        },
        {
            icon: Tick, 
            title: 'Premium support - we’ll give you exceptional support whether you pay or not'
        },
        {
            icon: Tick, 
            title: 'Cancel your subscription at any time'
        }
    ]
  }
