import Phone from '../Components/Assets/images/customernotifications.png';
import Email from '../Components/Assets/icons/email';
import Messaging from '../Components/Assets/icons/messaging';
import Notifications from '../Components/Assets/icons/notifications';
import Tick from '../Components/Assets/icons/tick';

export const customernotificationsfeature = 
  {
    title: 'Stay up to date with your pet',
    featureimage: Phone,
    features: [
        {
            icon: Email, 
            title: 'Automatic emails'
        },
        {
            icon: Messaging, 
            title: 'Automatic text message'
        },
        {
            icon: Notifications, 
            title: 'Push notifications'
        },
        {
            icon: Tick, 
            title: 'Select which type of notifications are best for you'
        }
    ]
  }