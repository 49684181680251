import React, { Component } from 'react';

class Poop extends Component {
  render() {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M17.8103099,5.13768579 C20.0400107,5.13768579 21.8475403,6.94521543 21.8475403,9.17491624 C21.8475403,9.76730355 21.7187328,10.337737 21.48238,10.854349 C22.9628484,11.4886374 24,12.9590004 24,14.6716032 C24,16.964106 22.1415593,18.8225467 19.8490565,18.8225467 L4.1509435,18.8225467 C1.85844071,18.8225467 0,16.964106 0,14.6716032 C0,12.9590004 1.03715164,11.4886374 2.51761995,10.854349 C2.28126718,10.337737 2.15245965,9.76730355 2.15245965,9.17491624 C2.15245965,6.94521543 3.95998929,5.13768579 6.1896901,5.13768579 L6.30119751,5.13768579 C6.27430401,4.97048875 6.26046248,4.79977616 6.26046248,4.62683672 C6.26046248,4.44502563 6.27594579,4.26354478 6.30674457,4.08436133 L6.36099383,3.76874593 C6.65742502,2.04414634 7.95826549,0.664994719 9.66261344,0.268367395 C11.2002161,-0.0894557982 12.7994485,-0.0894557982 14.3370511,0.268367395 C16.0413991,0.664994719 17.3422395,2.04414634 17.6386707,3.76874593 L17.69292,4.08436133 C17.7540249,4.43986201 17.7539724,4.79512411 17.6986602,5.13768579 L17.8103099,5.13768579 Z"/>
    </svg>
    );
  }
}

export default Poop;