// @flow
import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

class Map extends Component {
  
  render() {
    const mapOptions = {
      disableDefaultUI: true,
    };

    return (
      <GoogleMap
        ref={ref => this.map = ref}
        defaultZoom={this.props.defaultZoom || 15.5}
        defaultCenter={this.props.defaultCenter}
        options={mapOptions}
      >
        {this.props.children}
      </GoogleMap>
    )
  }
}

export default withScriptjs(withGoogleMap(Map));
