import React, { Component } from 'react';
import './Download.css';

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ShowcaseRight from '../Components/ShowcaseRight';
import ShowcaseLeft from '../Components/ShowcaseLeft';
import ContactUs from '../Components/ContactUs';

//Data
import { petsittershowcase } from '../Data/toppetsittershowcase';
import { customershowcase } from '../Data/topcustomershowcase';

class Download extends Component {
  render() {
    window.scrollTo(0, 0)
    return (
      <main className="download">
        <Header/>
        <div className="top-container">
            <div className="header wide-container">
            <h2 className="title">Download Walkies</h2>
            <p> We have two apps, one for Pet Sitters and one for Pet Parents</p>
            </div>
        </div>
        <ShowcaseRight
          showcase={petsittershowcase}
          download={true}
        />
        <ShowcaseLeft
          color='#A940AA'
          showcase={customershowcase}
          download={true}
        />
        <ContactUs
          content='You can instant message me in app or here with any questions you have or features you’d like me to add at any time.'
        />
        <Footer/>
      </main>
      )
    }
  };

  export default Download;
