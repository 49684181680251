import Phone from '../Components/Assets/images/customerinvoices.png';
import Invoice from '../Components/Assets/icons/invoice';
import PaymentLink from '../Components/Assets/icons/paymentlink';

export const customerinvoicesfeature = 
  {
    title: 'Keep track of invoices',
    featureimage: Phone,
    features: [
        {
            icon: Invoice, 
            title: 'Easily see which invoices you have paid and which you haven’t'
        },
        {
            icon: PaymentLink, 
            title: 'Pay invoices in app with just a couple taps'
        }
    ]
  }