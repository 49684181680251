import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle" fill="#9B4FAA" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M11.855723,11.9045459 L15.5828423,8.17198563 L14.2280208,6.81172312 C13.0690772,5.65822052 11.523819,5.65277947 10.4301679,6.74643052 C9.34195791,7.82919947 9.34195791,9.39622188 10.4954605,10.5442834 L11.855723,11.9045459 Z M13.0309898,13.0798127 L14.3966934,14.4455163 C15.550196,15.5990189 17.0900131,15.6044599 18.1891052,14.5162499 C19.2827563,13.433481 19.2773152,11.8664586 18.1292537,10.712956 L16.7581091,9.34725243 L13.0309898,13.0798127 Z M5,14.0483196 L11.3007359,14.0483196 C10.9633908,12.6282056 9.67930302,11.5726419 8.15580901,11.5726419 C6.62687396,11.5726419 5.34278615,12.6282056 5,14.0483196 Z M5,15.5772547 C5.3373451,17.0028098 6.62143291,18.0638146 8.15580901,18.0638146 C9.67930302,18.0638146 10.9579498,16.9973688 11.3007359,15.5772547 L5,15.5772547 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
    </g>
</svg>
);
