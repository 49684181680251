import React, { Component } from 'react';

class Notes extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'}  viewBox='0 0 24 24'>
      <path fill={this.props.color || "#1AE0B3"} fillRule="evenodd" d="M6.553125,23 L16.659375,23 C18.91875,23 20.2125,21.70625 20.2125,19.446875 L20.2125,11.46875 L12.375,11.46875 C11.315625,11.46875 10.753125,10.90625 10.753125,9.846875 L10.753125,2 L6.553125,2 C4.303125,2 3,3.303125 3,5.5625 L3,19.446875 C3,21.70625 4.303125,23 6.553125,23 Z M13.06875,9.790625 L20.1375,9.790625 C20.1204545,9.29630682 19.7470041,8.82523244 19.2143689,8.27879179 L14.08125,3.115625 C13.509375,2.54375 12.984375,2.103125 12.43125,2.075 L12.43125,9.1625 C12.440625,9.584375 12.646875,9.790625 13.06875,9.790625 Z M7.996875,15.44375 C7.5,15.44375 7.134375,15.0875 7.134375,14.609375 C7.134375,14.121875 7.5,13.765625 7.996875,13.765625 L15.234375,13.765625 C15.7125,13.765625 16.096875,14.13125 16.096875,14.609375 C16.096875,15.078125 15.7125,15.44375 15.234375,15.44375 L7.996875,15.44375 Z M7.996875,19.146875 C7.5,19.146875 7.134375,18.78125 7.134375,18.303125 C7.134375,17.825 7.5,17.459375 7.996875,17.459375 L15.234375,17.459375 C15.7125,17.459375 16.096875,17.834375 16.096875,18.303125 C16.096875,18.771875 15.7125,19.146875 15.234375,19.146875 L7.996875,19.146875 Z"/>
  </svg>
    );
  }
}

export default Notes;