import React, { Component } from 'react';
import './Leaderboard.css';

//Components
import IconWithTitle from '../Components/IconWithTitle';

//Icons
import Tick from './Assets/icons/tick';
import Cross from './Assets/icons/cross';

class Leaderboard extends Component {
  render() {
    return (
      <div className="dogs-container">
        {this.props.dogs.sort(this.props.sort).map(dog => (
          <div className="dog-item">
            <div className="dog-top">
              <div key={dog.name} className="dog">
                <div className="dog-image" style={{ backgroundImage: `url(${dog.picture})` }}/>
                <div className="dog-content">
                  <h6 className="dog-name">
                    {dog.name}
                  </h6>
                </div>
                </div>
                {!this.props.boarding ?
                <div className="dog-checklist">
                    {dog.poos === 0 ? 
                    <IconWithTitle icon={<Cross/>} title={`${dog.poos} poops`}/>
                    : dog.poos === 1 ? 
                    <IconWithTitle icon={<Tick/>} title={`${dog.poos} poop`}/>
                    :
                    <IconWithTitle icon={<Tick/>} title={`${dog.poos} poops`}/>
                    }

                    {dog.wees === 0 ? 
                    <IconWithTitle icon={<Cross/>}title={`${dog.wees} pees`}/>
                    : dog.wees === 1 ? 
                    <IconWithTitle icon={<Tick/>}title={`${dog.wees} pee`}/>
                    :
                    <IconWithTitle icon={<Tick/>}title={`${dog.wees} pees`}/>
                    }

                    {dog.food ? <IconWithTitle icon={<Tick/>} title='Food'/> : null}
                    {dog.water ? <IconWithTitle icon={<Tick/>} title='Water' /> : null}
                    {dog.treat ? <IconWithTitle icon={<Tick/>} title='Treat'/> : null}
                    
                    {dog.medication ? <IconWithTitle icon={<Tick/>} title='Medication'/> : null}
                    {dog.type === 'cat' ? dog.litterBox ? <IconWithTitle icon={<Tick/>} title='Litter box'/> : null : null}

                    {dog.additionalChecklistItems && dog.additionalChecklistItems.length > 0 ? (
                      dog.additionalChecklistItems.map((checklistItem, index) => (
                        <IconWithTitle icon={checklistItem.completed ? <Tick/> : <Cross/>} title={checklistItem.title}/>
                      ))
                    ) : ( null )
                    }
                </div>
                : 
                dog.additionalChecklistItems && dog.additionalChecklistItems.length > 0 ?
                  <div className="dog-checklist">
                    {dog.additionalChecklistItems.map((checklistItem, index) => (
                      <IconWithTitle icon={checklistItem.completed ? <Tick/> : <Cross/>} title={checklistItem.title}/>
                    ))}
                  </div>
                : null
                }
            </div>
            <div className="dog-bottom">
                {dog.activityNotes && this.props.displayNotes === true ? 
                  <div className="dog-notes"> 
                    <h6 className="dog-notes-title">{'Notes'}</h6> 
                    <p>{dog.activityNotes}</p> 
                  </div> : null
                }
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default Leaderboard;