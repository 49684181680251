import React, { Component } from 'react';
import './Activity.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components
import { MoonLoader } from 'halogenium';
import E404 from '../Errors/E404';
import Header from '../Components/Header';
import HeaderBlank from '../Components/HeaderBlank';
import Footer from '../Components/FooterPoweredBy';
import IconWithTitle from '../Components/IconWithTitle';
import Leaderboard from '../Components/Leaderboard';
import ActivityMap from '../Components/ActivityMap';
import PhotoSet from '../Components/PhotoSet';
import ReactPlayer from 'react-player'

//Icons
import Time from '../Components/Assets/icons/time.js';
import Duration from '../Components/Assets/icons/duration.js';
import Distance from '../Components/Assets/icons/distance.js';
import Weather from '../Components/Assets/icons/weather.js';
import TeamMember from '../Components/Assets/icons/teammember.js';

//Formatters
import moment from 'moment';
import { formatSeconds } from '../Helpers/time';
import { metresToMiles } from '../Helpers/distance';

class CheckIn extends Component {

  state = {
    checkIn: null
  }

  componentDidMount() {
    const db = firebase.firestore();
    var that = this;

    db.collection('checkIns').doc(window.location.pathname.split('/')[2]).onSnapshot(function(doc) {
        if (doc.exists) {
          const checkIn = doc.data();
          that.setState({checkIn: checkIn});
        } else {
          console.log("No such document!");
          that.setState();
        }
    });
  }

  loading() {
    return (
      <article aria-busy={true} className="topLoader wide-container">
        <MoonLoader
          loading={true}
          color="#1AE0B3"
          size="50"
          margin="auto"
        />
      </article>
    )
  }

  checkIn() {
    const checkIn = this.state.checkIn;
    const start = moment(checkIn.startTime.toDate());

    var timeData;
    if (checkIn.endTime) {
      const end = moment(checkIn.endTime.toDate());
      timeData = `${start.format('HH:mm')} to ${end.format('HH:mm')}`
    } else {
      timeData = `${start.format('HH:mm')}`
    }

    const weather = checkIn.weather;
    if (weather) {
      var weatherCondition;
      if (weather.condition === 'clear-day') {
        weatherCondition = 'clear'
      } else if (weather.condition === 'clear-night') {
        weatherCondition = 'clear'
      } else if (weather.condition === 'rain') {
        weatherCondition = 'rain'
      } else if (weather.condition === 'snow') {
        weatherCondition = 'snow'
      } else if (weather.condition === 'sleet') {
        weatherCondition = 'sleet'
      } else if (weather.condition === 'wind') {
        weatherCondition = 'wind'
      } else if (weather.condition === 'fog') {
        weatherCondition = 'fog'
      } else if (weather.condition === 'cloudy') {
        weatherCondition = 'cloudy'
      } else if (weather.condition === 'partly-cloudy-day') {
        weatherCondition = 'partly cloudy'
      } else if (weather.condition === 'partly-cloudy-night') {
        weatherCondition = 'partly cloudy'
      }
    }

    var notes;
    if (checkIn.endTime) {
      notes = checkIn.notes
    }

    return (
      <div>
        <div className="top-container">
            <div className="header wide-container">
              <div className="header-left">
                <h5 className="typeTitle">{'Drop in'}</h5>
                <h2 className="title">{start.format('dddd Do MMMM')}</h2>
                <IconWithTitle
                  icon={<Time/>}
                  title={timeData} 
                />
                <IconWithTitle
                  icon={<Duration/>}
                  title={formatSeconds(checkIn.duration)} 
                />
                {checkIn.distance > 0 ? (
                  <IconWithTitle
                    icon={<Distance/>}
                    title={metresToMiles(checkIn.distance) + ' mi'} 
                  />
                ) : null}
                {weather ? (
                  <IconWithTitle
                    icon={<Weather/>}
                    title={`${Math.round(weather.temperature)}° ${weatherCondition}`} 
                  />
                ) : ( null )}
                {checkIn.teamMembers && checkIn.teamMembers[0] ? (
                  <IconWithTitle
                    icon={<TeamMember/>}
                    title={`Dropped-in on by ${checkIn.teamMembers[0].name}`}
                  />
                ) : ( null )}
              </div>
              <div className="header-right">
                <ActivityMap coords={checkIn.walkCoordinates}/>
              </div>
            </div>
        </div>
        <div className="wide-container">
          <h4>{'Details'}</h4>
          {notes
            ? [<p className="activity-notes">
                {notes}
              </p>]
            : null
          }
          <Leaderboard
            dogs={checkIn.dogs}
            displayNotes={checkIn.endTime != null}
            sort={(a: Dog, b: Dog): number => a.poos > b.poos ? -1 : 1}
          />
        </div>
        <div className="wide-container">
          {checkIn.walkVideos && checkIn.walkVideos.length !== 0 && checkIn.walkImages && checkIn.walkImages.length !== 0 ? 
          <h5>{'Photos & videos'}</h5> 
          : checkIn.walkVideos && checkIn.walkVideos.length !== 0 && checkIn.walkImages && checkIn.walkImages.length === 0 ?
          <h4>{'Videos'}</h4>
          : checkIn.walkVideos && checkIn.walkVideos.length === 0 && checkIn.walkImages && checkIn.walkImages.length !== 0 ?
          <h4>{'Photos'}</h4>
          : null
          }
          <div className='player-wrapper'>
          {Array.isArray(checkIn.walkVideos)
              ? [checkIn.walkVideos.map((video, key) =>
              <ReactPlayer
                className='react-player'
                url={video.url}
                playing
                controls
                playsinline
                loop
                muted
                width='100%'
                height='100%'
              />
            )] : null 
          }
          </div>
          {Array.isArray(checkIn.walkImages)
              ? [<PhotoSet key="pp" photos={checkIn.walkImages}/>]
              : null
          }
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.checkIn) {
      return (
        <main className="walk">
        <HeaderBlank/>
        {this.loading()}
        <Footer/>
        </main>
      )
    }

    if (this.state.checkIn) {
      return (
        <main className="walk">
        <Header
          company={true}
          companyID={this.state.checkIn.companyID || null}
          companyLogo={this.state.checkIn.companyLogo || null}
          companyName={this.state.checkIn.companyName || null}
        />
        {this.checkIn()}
        <Footer/>
        </main>
      )
    }

    return (
      <main className="walk">
      <E404/>
      </main>
    )
  }
}

export default CheckIn;