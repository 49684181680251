import React, { Component } from 'react';

class DropIn extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M12.2903226,0 C17.4212261,0 21.5806452,4.1594191 21.5806452,9.29032258 C21.5806452,14.4212261 17.4212261,24 12.2903226,24 C7.1594191,24 3,14.4212261 3,9.29032258 C3,4.1594191 7.1594191,0 12.2903226,0 Z M12.2903226,4.64516129 C10.5800214,4.64516129 9.19354839,6.03163432 9.19354839,7.74193548 C9.19354839,9.45223664 10.5800214,10.8387097 12.2903226,10.8387097 C14.0006237,10.8387097 15.3870968,9.45223664 15.3870968,7.74193548 C15.3870968,6.03163432 14.0006237,4.64516129 12.2903226,4.64516129 Z"/>
      </svg>
    );
  }
}

export default DropIn;