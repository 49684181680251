import { Component } from 'react';

export default class Redirect extends Component {
  componentDidMount() {
    window.location = this.props.to;
  }

  render() {
    return null;
  }
}
