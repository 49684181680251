import React from 'react';

export default (props: {}): React.DOM => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Symbols-/-Clean" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle" fill="#D3E513" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M11.9967412,19 C12.4138734,19 12.7527933,18.6936685 12.8114525,18.2569832 C13.4371508,13.9096834 14.0367784,13.2970205 18.2341713,12.8277467 C18.6643389,12.7690875 18.9967412,12.4171322 18.9967412,12 C18.9967412,11.5763501 18.6708566,11.2309125 18.240689,11.1722533 C14.0628492,10.6182495 13.5218808,10.0837989 12.8114525,5.73649907 C12.7397579,5.30633147 12.4073557,5 11.9967412,5 C11.5796089,5 11.240689,5.30633147 11.1755121,5.73649907 C10.5563315,10.0903166 9.9632216,10.6964618 5.76582868,11.1722533 C5.32914339,11.2243948 5.00325885,11.5698324 5.00325885,12 C5.00325885,12.4171322 5.31610801,12.7625698 5.75931099,12.8277467 C9.93715084,13.4078212 10.4650838,13.9162011 11.1755121,18.2635009 C11.2602421,18.7001862 11.5926443,19 11.9967412,19 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
    </g>
</svg>
);
