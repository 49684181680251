import React, { Component } from 'react';

class Grooming extends Component {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_1463_9589)">
          <path d="M4.15576 10.7256C2.09033 10.7256 0.446777 9.07324 0.446777 7.00781C0.446777 4.93359 2.09912 3.27246 4.15576 3.27246C6.22998 3.27246 7.88232 4.9248 7.88232 7.00781C7.88232 7.80762 7.96143 8.08008 8.62061 8.42285L10.3257 9.33691V9.40723C9.73682 9.81152 9.43799 10.2949 9.20068 11.1387H9.13037C8.99854 11.0596 8.84033 10.9629 8.63818 10.8574L7.43408 10.207C7.21436 10.0928 7.07373 10.0576 6.88037 10.0576C6.08057 10.0576 5.86963 10.7256 4.15576 10.7256ZM4.15576 8.92383C5.21924 8.92383 6.07178 8.05371 6.07178 7.00781C6.07178 5.92676 5.21924 5.08301 4.15576 5.08301C3.10986 5.08301 2.26611 5.92676 2.26611 7.00781C2.26611 8.05371 3.10986 8.92383 4.15576 8.92383ZM4.15576 20.7275C2.09033 20.7275 0.446777 19.0664 0.446777 16.9922C0.446777 14.9268 2.09912 13.2744 4.15576 13.2744C5.86963 13.2744 6.11572 13.9512 6.88037 13.9512C7.06494 13.9512 7.18799 13.916 7.43408 13.793L8.45361 13.248C9.20947 12.8525 9.43799 12.6328 9.56982 12.1406L9.75439 11.4727C10.0532 10.4355 10.5542 9.88184 11.6704 9.47754L19.3345 6.71777C21.6284 5.8916 23.1929 6.2168 23.5532 7.6582L8.5415 15.6211C7.95264 15.9375 7.88232 16.2539 7.88232 16.9922C7.88232 19.0752 6.22998 20.7275 4.15576 20.7275ZM11.8462 12.1758C12.2505 12.1758 12.5933 11.8594 12.5933 11.4551C12.5933 11.0332 12.2505 10.7168 11.8462 10.7168C11.4595 10.7168 11.1167 11.0332 11.1167 11.4551C11.1167 11.8506 11.4595 12.1758 11.8462 12.1758ZM19.3257 17.2559L12.1626 14.584V14.4609L16.1265 12.3604L23.5532 16.2891C23.1929 17.7656 21.646 18.0996 19.3257 17.2559ZM4.15576 18.917C5.21924 18.917 6.07178 18.0557 6.07178 16.9922C6.07178 15.9287 5.21924 15.0762 4.15576 15.0762C3.10986 15.0762 2.26611 15.9287 2.26611 16.9922C2.26611 18.0557 3.10986 18.917 4.15576 18.917Z" id="Icon" fill={this.props.color || '#1AE0B3'}/>
        </g>
        <defs>
        <clipPath id="clip0_1463_9589">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    );
  }
}

export default Grooming;