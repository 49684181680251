import React, { Component } from 'react';
import './Invoice.css';

// Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

//Components
import { MoonLoader } from 'halogenium';
import Header from '../Components/Header';
import HeaderBlank from '../Components/HeaderBlank';
import Footer from '../Components/FooterPoweredBy';
import E404 from '../Errors/E404';
import IconWithTitleAndAccessory from '../Components/IconWithTitleAndAccessory';
import ButtonPrimary from '../Components/ButtonPrimary';
import ButtonSecondary from '../Components/ButtonSecondary';

//Formatters
import moment from 'moment';

//Assets
import Walk from '../Components/Assets/icons/tracking';
import DropIn from '../Components/Assets/icons/dropin';
import Daycare from '../Components/Assets/icons/daycare';
import HomeSitting from '../Components/Assets/icons/homesitting';
import OvernightStay from '../Components/Assets/icons/overnightstay';
import Boarding from '../Components/Assets/icons/boarding';
import Service from '../Components/Assets/icons/service';
import Travel from '../Components/Assets/icons/travel';
import Discount from '../Components/Assets/icons/award';
import BankTransfer from '../Components/Assets/icons/banktransfer';
import PayPal from '../Components/Assets/icons/paypal';
import Venmo from '../Components/Assets/icons/venmo';
import CashApp from '../Components/Assets/icons/cashapp';
import Cash from '../Components/Assets/icons/cash';
import Cheque from '../Components/Assets/icons/cheque';
import Zelle from '../Components/Assets/icons/zelle';
import Tick from '../Components/Assets/icons/tick';
import Taxi from '../Components/Assets/icons/taxi';
import Training from '../Components/Assets/icons/training';
import Grooming from '../Components/Assets/icons/grooming';

class Invoice extends Component {

  state = {
    invoice: null,
    tipAmount: null,
    loading: true,
    paying: false
  }

  generateServices(services) {
    return services.length
        ? <React.Fragment>
        <div className="invoice-services">
            {services.map(service => (
                (service.type === 'walk' ?
                    <IconWithTitleAndAccessory 
                        icon={<Walk/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'boarding' ?
                    <IconWithTitleAndAccessory 
                        icon={<Boarding/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'dropin' ?
                    <IconWithTitleAndAccessory 
                        icon={<DropIn/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'daycare' ?
                    <IconWithTitleAndAccessory 
                        icon={<Daycare/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'homeSitting' ?
                    <IconWithTitleAndAccessory 
                        icon={<HomeSitting/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'overnight' ?
                    <IconWithTitleAndAccessory 
                        icon={<OvernightStay/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'taxi' ?
                    <IconWithTitleAndAccessory 
                        icon={<Taxi/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'other' ?
                    <IconWithTitleAndAccessory 
                        icon={<Service/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'training' ?
                    <IconWithTitleAndAccessory 
                        icon={<Training/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                :
                service.type === 'grooming' ?
                    <IconWithTitleAndAccessory 
                        icon={<Grooming/>}
                        title={`(x${service.quantity}) ${service.title}`}
                        subtitle={Intl.NumberFormat(service.country || this.state.invoice.country, { style: 'currency', currency: service.currency }).format(service.amount / 100 * service.quantity)}
                    />
                : null)
            ))}
            {this.state.invoice.travelAmount ?
                <IconWithTitleAndAccessory 
                    icon={<Travel/>}
                    title='Travel'
                    subtitle={Intl.NumberFormat(this.state.invoice.country, { style: 'currency', currency: this.state.invoice.currency }).format(this.state.invoice.travelAmount / 100)}
                />
            : null}
            {this.state.invoice.discountAmount ?
                <IconWithTitleAndAccessory 
                    icon={<Discount/>}
                    title='Discount'
                    subtitle={Intl.NumberFormat(this.state.invoice.country, { style: 'currency', currency: this.state.invoice.currency }).format(-this.state.invoice.discountAmount / 100)}
                />
            : null}
            </div>
        </React.Fragment>
      : null
  }

  componentDidMount() {
    const db = firebase.firestore();
    var that = this;

    db.collection('invoices').doc(window.location.pathname.split('/')[2]).onSnapshot(function(doc) {
        if (doc.exists) {
          const invoice = doc.data();
          that.setState({invoice, loading: false});
        } else {
          console.log("No such document!");
          that.setState({loading: false});
        }
    })
  }
  
  handleChange(event) {
    this.setState({tipAmount: event.target.value});
  }

  payDeposit(event) {
    event.preventDefault();
    var that = this;
    that.setState({paying: true});
    const getCheckout = firebase.functions().httpsCallable('depositCheckoutSession');
    getCheckout({companyID: that.state.invoice.companyID, invoiceID: that.state.invoice.id})
    .then(session => {
      window.location.href=session.data
    }).catch(function(error) {
      console.log(error);
      that.setState({paying: false});
    });
  }

  payWithTip(event) {
    event.preventDefault();
    var that = this;
    const tip = event.target[0].value

    if (tip == 0) {
      return alert(`Your tip needs to be at least ${that.state.invoice.currency === "GBP" ? '£' :'$'}1.`)
    }

    that.setState({paying: true});
    const getCheckout = firebase.functions().httpsCallable('invoiceCheckoutSession');
    return getCheckout({tipAmount: tip * 100, companyID: that.state.invoice.companyID, invoiceID: that.state.invoice.id})
    .then(session => {
      window.location.href=session.data
    }).catch(function(error) {
      console.log(error);
      that.setState({paying: false});
    });
  }

  payWithoutTip(event) {
    event.preventDefault();
    var that = this;
    that.state.invoice.tipAmount = 0
    that.setState({paying: true});
    const getCheckout = firebase.functions().httpsCallable('invoiceCheckoutSession');
    getCheckout({companyID: that.state.invoice.companyID, invoiceID: that.state.invoice.id})
    .then(session => {
      window.location.href=session.data
    }).catch(function(error) {
      console.log(error);
      that.setState({paying: false});
    });
  }

  paying() {
    return (
      <div className="paying">
        <MoonLoader
          loading={true}
          color="#1AE0B3"
          size="50px"
          className='loader'
        />
        <h5>Preparing secure checkout...</h5>
      </div>
    )
  }

  loading() {
    return (
      <article aria-busy={true} className="wide-container loading">
        <MoonLoader
          loading={true}
          color="#1AE0B3"
          size="50"
        />
      </article>
    )
  }

  paymentLink(depositPaid) {
    return(
      <div className="invoice-pay-link payment-method">
        <h4 className='tip-form-title'>Pay invoice</h4>
        <p className='tip-form-description'>Please enter how much you'd like to tip below or pay without a tip.</p>
        <form className="tip-form" onSubmit={this.payWithTip.bind(this)}>
          <input className='invoice-form-text-field' type="number" placeholder="0"/>
          <button className='invoice-form-button' type="submit">Pay with tip</button>
          <ButtonSecondary
            className='pay-without-tip-button'
            title='Pay invoice without tip'
            action={this.payWithoutTip.bind(this)}
          />
          {!depositPaid ?
          <div className='pay-deposit-button'>
            <ButtonSecondary
            title='Pay deposit'
            action={this.payDeposit.bind(this)}
          />
          </div>
          : null}
        </form>
      </div>
    )
  }

  bankTransfer() {
    return (
      <div className="invoice-bank-transfer payment-method">
        <BankTransfer size='56'/>
        <h5>Please pay via bank transfer</h5>
      </div>
    )
  }

  paypal = () => { 
    return (
      <div className="invoice-paypal payment-method">
        <PayPal/>
          <a href={this.state.invoice.paypalURL}>
          <ButtonPrimary
            title='Pay with PayPal'
          />
          </a>
      </div>
    )
  }

  venmo() { 
    return (
      <div className="invoice-venmo payment-method">
        <Venmo/>
        <a href={this.state.invoice.venmoURL}>
        <ButtonPrimary
          title='Pay with Venmo'
        />
        </a>
      </div>
    )
  }

  cashApp() { 
    return (
      <div className="invoice-cash-app payment-method">
        <CashApp/>
        <a href={this.state.invoice.cashAppURL}>
        <ButtonPrimary
          title='Pay with Cash App'
        />
        </a>
      </div>
    )
  }

  cash() { 
    return (
      <div className="invoice-cash payment-method">
        <Cash size='56'/>
        <h5>Please pay with cash</h5>
      </div>
    )
  }

  other() { 
    return (
      <div className="invoice-cash payment-method">
        <Cash size='56'/>
        <h5>Please pay with {this.state.invoice.otherService}</h5>
      </div>
    )
  }

  cheque() { 
    return (
      <div className="invoice-cheque payment-method">
        <Cheque size='56'/> 
        <h5>Please pay with a cheque</h5>
      </div>
    )
  }

  zelle() { 
    return (
      <div className="invoice-zelle payment-method">
        <Zelle/>
        <h5>Please pay with Zelle</h5>
      </div>
    )
  }

  paid() { 
    return (
      <div className="paying payment-method">
        <Tick size='56'/>
        <h5>Invoice paid, thank you.</h5>
      </div>
    )
  }

  invoice() {
    const invoice = this.state.invoice;
    const value = Intl.NumberFormat(invoice.country, { style: 'currency', currency: invoice.currency }).format(invoice.amount / 100);
    const serviceTax = invoice.serviceTaxAmount ? Intl.NumberFormat(invoice.country, { style: 'currency', currency: invoice.currency }).format(invoice.serviceTaxAmount / 100) : null;
    const deposit = invoice.deposit ? Intl.NumberFormat(invoice.country, { style: 'currency', currency: invoice.currency }).format(invoice.deposit / 100) : null;
    const dateCreated = moment(invoice.dateCreated.toDate()).format('dddd Do MMMM');
    const depositPaid = (this.state.invoice.payments || []).filter(payment => payment.type === 'deposit').reduce((total, el) => total + el.amount, 0) >= this.state.invoice.deposit
    
    var dueDate = dateCreated
    if (invoice.dueDate) {
      dueDate = moment(invoice.dueDate.toDate()).format('dddd Do MMMM');
    }

    const formatter = new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    });
    var coveredDates = formatter.format(invoice.coveredDates.map(date => moment(date.toDate()).format('dddd Do MMMM')))

    return (
        <div className="content-container wide-container">
          <div className="left-column">
            {this.state.invoice.paid ?
              this.paid()
            :
              this.state.paying ?
                this.paying()
              :
              this.state.invoice.paymentMethod === 'Payment link' ?
                this.paymentLink(depositPaid)
              : 
              this.state.invoice.paymentMethod === 'Bank transfer' ?
                this.bankTransfer()
              : 
              this.state.invoice.paymentMethod === 'PayPal' ?
                this.paypal()
              : 
              this.state.invoice.paymentMethod === 'Venmo' ?
                this.venmo()
              : 
              this.state.invoice.paymentMethod === 'Cash App' ?
                this.cashApp()
              :
              this.state.invoice.paymentMethod === 'Cash' ?
                this.cash()
              : 
              this.state.invoice.paymentMethod === 'Other' ?
                this.other()
              : 
              this.state.invoice.paymentMethod === 'Cheque' ?
                this.cheque()
              : 
              this.state.invoice.paymentMethod === 'Zelle' ?
                this.zelle()
              : null
            }
          </div> 
          <div className="right-column">
            {invoice.services ?
              <div className="invoice-services">
              <h5>Services</h5>
                {this.generateServices(invoice.services)}
                {deposit ?
                <div className="invoice-services-total">
                  <h6>Deposit required</h6>
                  <p>{deposit}{depositPaid ? " (Paid)" : ""}</p>
                </div> 
                : null }
                {serviceTax && serviceTax !== null ?
                <div className="invoice-services-total">
                  <h6>Service tax ({invoice.serviceTaxPercentage}%)</h6>
                  <p>{serviceTax}</p>
                </div> 
                : null }
                <div className="invoice-services-total">
                  <h6>Total</h6>
                  <p>{value}</p>
                </div> 
              </div> 
            : null }
            <div className="invoice-notes">
              <h5>Notes</h5>
              <p>{invoice.notes || 'No notes'}</p>
            </div> 
            <div className="invoice-due-date">
              <h5>Due date</h5>
              <p>{dueDate}</p>
            </div> 
            {coveredDates ?
            <div className="invoice-dates-covered">
              <h5>Dates covered</h5>
              <p>{coveredDates}</p>
            </div>
            : null }
            <div className="invoice-meta">
              <h5>Date created</h5>
              <p>{dateCreated}</p>
            </div> 
          </div>
      </div>       
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <main className="invoice">
        <HeaderBlank/>
        {this.loading()}
        <Footer/>
        </main>
      )
    }

    if (this.state.invoice) {
      return (
        <main className="invoice">
        <Header
          company={true}
          companyID={this.state.invoice.companyID || null}
          companyLogo={this.state.invoice.companyLogo || null}
          companyName={this.state.invoice.companyName || null}
        />
        {this.invoice()}
        <Footer/>
        </main>
      )
    }

    return (
      <main className="invoice">
      <E404/>
      </main>
    )
  }
}

export default Invoice;