import React, { Component } from 'react';
import './SubscribeSucceeded.css';
import illustration from '../Components/Assets/illustrations/illo.walkies.pro.png'
import ButtonSecondary from '../Components/ButtonSecondary';

class SubscribeSucceeded extends Component {

  openAppStore(e) {
    e.preventDefault();
    
  }

  render() {
    return (  
      <main className="subscribe-succeeded">
        <div className="content-container wide-container">
          <div className="message-container">
            <img src={illustration} alt={"logo"} className='subscribe-succeeded-illustration'/> 
            <h5>Thank you for becoming a Walkies PRO member!</h5>
            <p>If you have a Walkies App Store subscription you can cancel it. You can now return to the app.</p>
            <a href='https://apps.apple.com/account/subscriptions' target=''>
            <ButtonSecondary
              title="Open App Store"
            />
            </a>
          </div>
        </div> 
      </main>    
    );
  }
}

export default SubscribeSucceeded;