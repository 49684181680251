import React from 'react';

export default (props: {}): React.DOM => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width="24" height="24" fill="#1AE0B3" rx="10"/>
      <path fill="#FFF" d="M10.5648022,18.2129208 C9.95361528,18.2129208 9.45156889,17.9071884 9.10231924,17.5141037 L5.96998636,13.9545041 C5.6207367,13.5614195 5.51159618,13.244768 5.51159618,12.8298453 C5.51159618,11.9454049 6.25375171,11.2465878 7.12687585,11.2465878 C7.61800819,11.2465878 7.9781719,11.4758872 8.30559345,11.8034577 L10.5648022,14.3257507 L14.8212824,7.66515014 C15.2141883,7.05368517 15.6507503,6.74795268 16.2510232,6.74795268 C17.1132333,6.74795268 17.8772169,7.41401274 17.8772169,8.2766151 C17.8772169,8.59326661 17.7899045,8.94267516 17.5607094,9.29208371 L12.0600273,17.4049136 C11.7216917,17.8962693 11.154161,18.2129208 10.5648022,18.2129208 Z"/>
    </g>
  </svg>
);
