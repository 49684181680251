import Phone from '../Components/Assets/images/customermessages.png';
import Messaging from '../Components/Assets/icons/messaging';
import Schedule from '../Components/Assets/icons/schedule';

export const customermessagingfeature = 
  {
    title: 'Instant message your pet sitter',
    featureimage: Phone,
    features: [
        {
            icon: Messaging, 
            title: 'Message your pet sitter in app'
        },
        {
            icon: Schedule, 
            title: 'Discuss appointments before you book them'
        }
    ]
  }