import React, { Component } from 'react';
import './DepositPaymentSucceeded.css';

//Components
import Tick from '../Components/Assets/icons/tick';

class DepositPaymentSucceeded extends Component {

  render() {
    return (  
      <main className="invoice-payment-succeeded">
        <div className="content-container wide-container">
          <div className="message-container">
            <Tick size='56'/>
            <h5>Deposit paid, thank you.</h5>
          </div>
        </div> 
      </main>    
    );
  }
}

export default DepositPaymentSucceeded;