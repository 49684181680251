import React, { Component } from 'react';
import './HomeHero.css';

//Assets
import AppStore from './Assets/images/appstorebadge.js';
import PlayStore from './Assets/images/playstorebadge.js';
import ScrollDown from './Assets/icons/scrolldown.js';

class HomeHero extends Component {
    render() {
        return (
        <section className="hero">
        <div className="hero-container wide-container">
          <div className="hero-container-left">
            <h1>Let's go for a walk</h1>
            <h4>Walkies helps Pet Sitters run their business with simplicity</h4>
            <div className="badges">
              <a href="https://apps.apple.com/app/walkies-pet-walking-sitting/id1336482131">
              <div className="appstore"><AppStore/></div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.swiftlabs.walkies">
              <div className="playstore"><PlayStore/></div>
              </a>
            </div>
            </div>
        </div>
        <div className="scrolldown"><ScrollDown/></div>
      </section>
      )
    }
  };
  
  export default HomeHero;