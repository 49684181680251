import React, { Component } from 'react';
import './Activity.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components
import { MoonLoader } from 'halogenium';
import E404 from '../Errors/E404';
import Header from '../Components/Header';
import HeaderBlank from '../Components/HeaderBlank';
import Footer from '../Components/FooterPoweredBy';
import IconWithTitle from '../Components/IconWithTitle';
import Leaderboard from '../Components/Leaderboard';
import ActivityMap from '../Components/ActivityMap';
import PhotoSet from '../Components/PhotoSet';
import ReactPlayer from 'react-player'

//Icons
import Time from '../Components/Assets/icons/time.js';
import Duration from '../Components/Assets/icons/duration.js';
import Distance from '../Components/Assets/icons/distance.js';
import Weather from '../Components/Assets/icons/weather.js';
import TeamMember from '../Components/Assets/icons/teammember.js';
import Tick from '../Components//Assets/icons/tick';
import Cross from '../Components//Assets/icons/cross';

//Formatters
import moment from 'moment';
import { formatSeconds } from '../Helpers/time';
import { metresToMiles } from '../Helpers/distance';

class Walk extends Component {

  state = {
    walk: null
  }

  componentDidMount() {
    const db = firebase.firestore();
    var that = this;

    db.collection('walks').doc(window.location.pathname.split('/')[2]).onSnapshot(function(doc) {
        if (doc.exists) {
          const walk = doc.data();
          that.setState({walk});
        } else {
          console.log("No such document!");
          that.setState();
        }
    });
  }

  loading() {
    return (
      <article aria-busy={true} className="topLoader wide-container">
        <MoonLoader
          loading={true}
          color="#1AE0B3"
          size="50"
          margin="auto"
        />
      </article>
    )
  }

  walk() {
    const walk = this.state.walk;
    const start = moment(walk.startTime.toDate());

    if (walk.disableLiveTracking && walk.active) {
      return (
        <div className="live-tracking-disabled wide-container">
            <h5 className="title">Live tracking disabled</h5>
            <p className="subtitle">You'll be able to view all of the report details when your pet sitter has ended the walk.</p>
        </div>
      )
    }

    var timeData;
    if (walk.endTime) {
      const end = moment(walk.endTime.toDate());
      timeData = `${start.format('HH:mm')} to ${end.format('HH:mm')}`
    } else {
      timeData = `${start.format('HH:mm')}`
    }

    const weather = walk.weather;
    if (weather) {
      var weatherCondition;
      if (weather.condition === 'clear-day') {
        weatherCondition = 'clear'
      } else if (weather.condition === 'clear-night') {
        weatherCondition = 'clear'
      } else if (weather.condition === 'rain') {
        weatherCondition = 'rain'
      } else if (weather.condition === 'snow') {
        weatherCondition = 'snow'
      } else if (weather.condition === 'sleet') {
        weatherCondition = 'sleet'
      } else if (weather.condition === 'wind') {
        weatherCondition = 'wind'
      } else if (weather.condition === 'fog') {
        weatherCondition = 'fog'
      } else if (weather.condition === 'cloudy') {
        weatherCondition = 'cloudy'
      } else if (weather.condition === 'partly-cloudy-day') {
        weatherCondition = 'partly cloudy'
      } else if (weather.condition === 'partly-cloudy-night') {
        weatherCondition = 'partly cloudy'
      }
    }

    var notes;
    if (walk.endTime) {
      notes = walk.notes
    }

    return (
      <div>
        <div className="top-container">
            <div className="header wide-container">
              <div className="header-left">
                <h5 className="typeTitle">{'Walk'}</h5>
                <h3 className="title">{start.format('dddd Do MMMM')}</h3>
                <IconWithTitle
                  icon={<Time/>}
                  title={timeData} 
                />
                <IconWithTitle
                  icon={<Duration/>}
                  title={formatSeconds(walk.duration)} 
                />
                <IconWithTitle
                  icon={<Distance/>}
                  title={metresToMiles(walk.distance) + ' mi'} 
                />
                {weather ? (
                  <IconWithTitle
                    icon={<Weather/>}
                    title={`${Math.round(weather.temperature)}° ${weatherCondition}`} 
                  />
                ) : ( null )}
                {walk.teamMembers && walk.teamMembers[0] ? (
                  <IconWithTitle
                    icon={<TeamMember/>}
                    title={`Walked by ${walk.teamMembers[0].name}`}
                  />
                ) : ( null )}
                {walk.additionalChecklistItems && walk.additionalChecklistItems.length > 0 ? (
                  walk.additionalChecklistItems.map((checklistItem, index) => (
                    <IconWithTitle icon={checklistItem.completed ? <Tick/> : <Cross/>} title={checklistItem.title}/>
                  ))
                ) : ( null )} 
              </div>
              <div className="header-right">
                {walk.walkCoordinates && walk.walkCoordinates.length > 0 ? (
                <ActivityMap coords={walk.walkCoordinates}/>
                ) : ( null ) }
              </div>
            </div>
        </div>
        <div className="wide-container">
          {notes ? (
            <div>
              <h5 className='walk-details-title'>{'Notes'}</h5>
              <p className="activity-notes">
                {notes}
              </p>
            </div>
          ) : null}
          <Leaderboard
            dogs={walk.dogs}
            displayNotes={walk.endTime != null}
            sort={(a: Dog, b: Dog): number => a.poos > b.poos ? -1 : 1}
          />
        </div>
        <div className="wide-container">
          {walk.walkVideos && walk.walkVideos.length !== 0 && walk.walkImages && walk.walkImages.length !== 0 ? 
          <h5>{'Photos & videos'}</h5> 
          : walk.walkVideos && walk.walkVideos.length !== 0 && walk.walkImages && walk.walkImages.length === 0 ?
          <h5>{'Videos'}</h5>
          : walk.walkVideos && walk.walkVideos.length === 0 && walk.walkImages && walk.walkImages.length !== 0 ?
          <h5>{'Photos'}</h5>
          : null
          }
          <div className='player-wrapper'>
          {Array.isArray(walk.walkVideos)
              ? [walk.walkVideos.map((video, key) =>
              <ReactPlayer
                className='react-player'
                url={video.url}
                playing
                controls
                playsinline
                loop
                muted
                width='100%'
                height='100%'
              />
            )] : null 
          }
          </div>
          {Array.isArray(walk.walkImages) ? <PhotoSet className='gallery' key="pp" photos={walk.walkImages}/> : null}
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.walk) {
      return (
        <main className="walk">
        <HeaderBlank/>
        {this.loading()}
        <Footer/>
        </main>
      )
    }

    if (this.state.walk) {
      return (
        <main className="walk">
        <Header
          company={true}
          companyID={this.state.walk.companyID || null}
          companyLogo={this.state.walk.companyLogo || null}
          companyName={this.state.walk.companyName || null}
        />
        {this.walk()}
        <Footer/>
        </main>
      )
    }

    return (
      <main className="walk">
      <E404/>
      </main>
    )
  }
}

export default Walk;
