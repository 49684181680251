import React, { Component } from 'react';
import './ShowcaseLeft.css';

//Components
import IconWithTitle from './IconWithTitle';
import AppStore from './Assets/images/appstorebadge.js';
import PlayStore from './Assets/images/playstorebadge.js';

class Showcase extends Component {

  generateFeatures(features) {
    return features.length
      ? <React.Fragment>
        <div className="showcase-features">
            {features.map(feature => (
              <IconWithTitle 
                key={feature.title}
                icon={<feature.icon color={this.props.color || null}/>}
                title={feature.title}
              />
            ))}
          </div>
        </React.Fragment>
      : null
  }3

  render() {
    return (
      <div className="showcase-left wide-container">
      <div className="showcase-left-column">
          <img className='feature-image' alt="" src={this.props.showcase.featureimage}/>
      </div>
      <div className="showcase-right-column">
          <h3>{this.props.showcase.title}</h3>
          <p>{this.props.showcase.subtitle}</p>
          {this.generateFeatures(this.props.showcase.features)}
          {this.props.download ? 
            <div className="showcase-left-badges">
              <a href="https://apps.apple.com/app/walkies-customer-pet-journal/id1488308024">
              <div className="showcase-left-appstore"><AppStore color={this.props.color || '#1AE0B3'}/></div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.swiftlabs.journal">
              <div className="showcase-left-playstore"><PlayStore color={this.props.color || '#1AE0B3'}/></div>
              </a>
            </div>
          : null }
      </div>
      </div>
    )
  }
};

export default Showcase;