import React, { Component } from 'react';

class Video extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'}  viewBox='0 0 24 24'>
      <path fill={this.props.color || "#1AE0B3"} fillRule="evenodd" d="M3.75813953,20.0418605 L12.855814,20.0418605 C15.3116279,20.0418605 16.6139535,18.7395349 16.6139535,16.4511628 L16.6139535,7.54883721 C16.6139535,5.26046512 15.3302326,3.95813953 12.8837209,3.95813953 L3.75813953,3.95813953 C1.32093023,3.95813953 0,5.26046512 0,7.55813953 L0,16.7023256 C0,18.9906977 1.30232558,20.0418605 3.75813953,20.0418605 Z M18.2790698,15.8 L18.2790698,8.40465116 L20.8,6.2744186 C21.3953488,5.77209302 21.9069767,5.4372093 22.4930233,5.4372093 C23.3581395,5.4372093 24,6.02325581 24,7 L24,17.2046512 C24,18.1813953 23.3581395,18.7674419 22.4930233,18.7674419 C21.9162791,18.7674419 21.3860465,18.4325581 20.8,17.9302326 L18.2790698,15.8 Z"/>
  </svg>
    );
  }
}

export default Video;