import React, { Component } from 'react';
import './CustomerExplanation.css';

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ShowcaseLeft from '../Components/ShowcaseLeft';
import ButtonPrimary from '../Components/ButtonPrimary';
import ContactUs from '../Components/ContactUs';

//Data
import { whatiswalkiescustomershowcase } from '../Data/whatiswalkiescustomershowcase';

class CustomerExplanation extends Component {

  openReport() {
    window.location.href="/walk/nZtHp6wr7ZLXFxrNqrPg"
  }
  
  render() {
    window.scrollTo(0, 0)
    return (
      <main className="customer-explanation">
        <Header/>
        <div className="top-container">
            <div className="header wide-container">
            <h2 className="title">Client help - What is Walkies?</h2>
            <p className="intro">Walkies is an app that lets your pet sitter send you pictures, videos, notes and more whilst your pet is with them.</p>
            </div>
        </div>
        <div className="content wide-container">
            <h3 className="customer-explanation-section-one-title">How it works</h3>
            <li>Your pet sitter will add you and your pet to their app.</li>
            <li>Then they’ll go on a walk, drop in, or look after your pet.</li>
            <li>Whilst they are with your pet they can take pictures, videos, add notes, and more.</li>
            <li>Then when they are finished you’ll receive a link via email or text message to view all of your pet’s activity on our website.</li>
            <ButtonPrimary
                    action={this.openReport}
                    title='View example report'
                />
            <h3 className="customer-explanation-section-one-title">What you need to do</h3>
            <p>All you need to do is give your pet sitter your information, which you probably already have. From there everything will just work. You don’t have to sign up to anything or download anything.</p>
            <p>However, we do recommend downloading the customer app as it will take things to the next level.</p>
        </div>
        <ShowcaseLeft
            color='#A940AA'
            showcase={whatiswalkiescustomershowcase}
            download={true}
        />
        <ContactUs
          content='If you have anymore questions then please chat with us.'
        />
        <Footer/>
      </main>
      )
    }
  };

  export default CustomerExplanation;
