import React, { Component } from 'react';
import './IconWithTitleAndAccessory.css';

class IconWithTitleAndAccessory extends Component {
    render() {
        return (
            <div className="icon-with-title-and-accessory">
                <div className="type-image">
                    {this.props.icon}
                </div>
                <h6>{this.props.title}</h6>
                <p>{this.props.subtitle}</p>
            </div>
        )
    }
}

export default IconWithTitleAndAccessory;