import React, { Component } from 'react';
import './Pricing.css';

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import FeatureBox from '../Components/FeatureBox';
import PriceTier from '../Components/PriceTier';
import ContactUs from '../Components/ContactUs';

//Data
import { pricingfeatures } from '../Data/pricingfeatures';
import { pricetierfree } from '../Data/pricetierfree';
import { pricetierplus } from '../Data/pricetierplus';
import { pricetierpro } from '../Data/pricetierpro';

class Pricing extends Component {

  render() {
    window.scrollTo(0, 0)
    return (
      <main className="pricing">
        <Header/>
        <div className="top-container">
            <div className="header wide-container">
            <h2 className="title">Pricing - we’re more affordable than any other pet sitting software</h2>
            </div>
        </div>
        <FeatureBox feature={pricingfeatures}/>
        <div className="pricing-tiers wide-container">
          <PriceTier pricetier={pricetierfree}/>
          <PriceTier pricetier={pricetierplus}/>
          <PriceTier pricetier={pricetierpro}/>
        </div>
        <ContactUs
          content='If you have any questions about Walkies pricing then please chat to me.'
        />
        <Footer/>
      </main>
      )
    }
  };

  export default Pricing;
