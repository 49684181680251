import Phone from '../Components/Assets/images/petsitterinvoices.png';
import Invoice from '../Components/Assets/icons/invoice';
import PaymentLink from '../Components/Assets/icons/paymentlink';

export const petsitterinvoicesfeature = 
  {
    title: 'Flexible invoicing',
    featureimage: Phone,
    features: [
        {
            icon: Invoice, 
            title: 'Request payments via bank transfer, cash, cheque, PayPal, Venmo, Zelle, or Walkies Payment Links'
        },
        {
            icon: PaymentLink, 
            title: 'You don’t have to use us to process your payments but if you do we make it really easy with Walkies Payment Links'
        }
    ]
  }