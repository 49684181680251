import React, { Component } from 'react';

class Cross extends Component {
  render() {
    return (
      <svg width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
      <path fill="#D0021B" fillRule="evenodd" d="M11.994543,24 C18.6084584,24 24,18.6169245 24,12 C24,5.38307552 18.6084584,0 11.994543,0 C5.38062756,0 0,5.38307552 0,12 C0,18.6169245 5.38062756,24 11.994543,24 Z M15.2141883,16.7825296 C14.7121419,16.7825296 14.3301501,16.6405823 14.0027285,16.3130118 L11.994543,14.3366697 L10.0081855,16.3130118 C9.68076398,16.6405823 9.29877217,16.7825296 8.79672578,16.7825296 C7.87994543,16.7825296 7.19236016,16.0946315 7.19236016,15.166515 C7.19236016,14.7515924 7.36698499,14.3585077 7.67257844,14.0527753 L9.71350614,12.010919 L7.67257844,9.9799818 C7.36698499,9.6633303 7.19236016,9.27024568 7.19236016,8.86624204 C7.19236016,7.93812557 7.87994543,7.25022748 8.79672578,7.25022748 C9.29877217,7.25022748 9.68076398,7.3921747 10.0081855,7.71974522 L11.994543,9.70700637 L14.0027285,7.71974522 C14.3301501,7.3921747 14.7121419,7.25022748 15.2141883,7.25022748 C16.1309686,7.25022748 16.8185539,7.93812557 16.8185539,8.86624204 C16.8185539,9.27024568 16.6439291,9.67424932 16.3383356,9.9799818 L14.319236,12.010919 L16.3383356,14.0527753 C16.6439291,14.3585077 16.8185539,14.7515924 16.8185539,15.166515 C16.8185539,16.0946315 16.1309686,16.7825296 15.2141883,16.7825296 Z"/>
    </svg>
    );
  }
}

export default Cross;