import React, { Component } from 'react';

class PrivateAreasCut extends Component {
  render() {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g id="ic_privateAreasCut" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="Rectangle" fill="#AA7663" x="0" y="0" width="24" height="24" rx="10"></rect>
                <g id="eyes.inverse" transform="translate(4.735, 6.5)" fill-rule="nonzero">
                    <rect id="Rectangle" fill="#000000" opacity="0" x="0" y="0" width="14.5300783" height="11"></rect>
                    <path d="M3.35137413,11 C5.27997135,11 6.7302672,8.77730123 6.7302672,5.49703217 C6.7302672,2.21674265 5.27997135,0 3.35137413,0 C1.57294392,0 0.1924125,1.90578075 0,4.7960567 C0.291366038,4.25201282 0.834855237,3.92602246 1.54434019,3.92602246 C2.61059905,3.92602246 3.31353944,4.67284065 3.31353944,5.77319372 C3.31353944,6.89339542 2.61059905,7.63233351 1.54434019,7.63233351 C0.889652606,7.63233351 0.372605735,7.35704185 0.0689713079,6.88602702 C0.417591361,9.38867387 1.71279515,11 3.35137413,11 Z M1.05644968,5.55920306 C1.27422688,5.60116201 1.47592152,5.40328973 1.53397838,5.12774222 C1.5879468,4.85980872 1.45602683,4.59624509 1.22625551,4.55427079 C1.01447537,4.51801211 0.788792494,4.7120825 0.742729751,4.98573675 C0.694758387,5.26155034 0.836763858,5.52133766 1.05644968,5.55920306 Z M11.1530478,11 C13.0797313,11 14.5300783,8.77730123 14.5300783,5.49703217 C14.5300783,2.21674265 13.0797313,0 11.1530478,0 C9.372709,0 7.99410156,1.90578075 7.79981114,4.7960567 C8.09117001,4.25201282 8.63464079,3.92602246 9.34006289,3.92602246 C10.4103744,3.92602246 11.1133403,4.67284065 11.1133403,5.77319372 C11.1133403,6.89339542 10.4103744,7.63233351 9.34006289,7.63233351 C8.69133655,7.63233351 8.17237594,7.35704185 7.86873639,6.88602702 C8.21740506,9.38867387 9.51260629,11 11.1530478,11 Z M8.85814897,5.55920306 C9.07183261,5.60116201 9.27978527,5.40328973 9.33376905,5.12774222 C9.38365927,4.85980872 9.25174441,4.58643591 9.02603595,4.55427079 C8.81424558,4.51801211 8.59048155,4.7120825 8.54253577,4.98573675 C8.49453882,5.26155034 8.6324405,5.51913738 8.85814897,5.55920306 Z" id="Shape" fill="#FFFFFF"></path>
                </g>
            </g>
        </svg>
    );
  }
}

export default PrivateAreasCut;