import React, { Component } from 'react';
import './PriceTier.css';

//Components
import IconWithTitle from './IconWithTitle';

class PriceTier extends Component {

  generateFeatures(features) {
    return features.length
      ? <React.Fragment>
        <div className="price-tier-features">
            {features.map(feature => (
              <IconWithTitle 
                icon={<feature.icon/>}
                title={feature.title}
              />
            ))}
          </div>
        </React.Fragment>
      : null
  }3

  render() {
    return (
      <div className="price-tier">
        <h3 className='price-tier-title'>{this.props.pricetier.title}</h3>
        <h5 className='price-tier-price'>{this.props.pricetier.price}</h5>
        {this.generateFeatures(this.props.pricetier.features)}
      </div>
    )
  }
};

export default PriceTier;