// @flow
import React, { Component } from 'react';
import './App.css';

//Pages
import Home from './Pages/Home';
import Journal from './Pages/Journal';
import About from './Pages/About';
import Download from './Pages/Download';
import CustomerHowItWorks from './Pages/CustomerExplanation';
import Pricing from './Pages/Pricing';
import Walk from './Pages/Walk';
import CheckIn from './Pages/CheckIn';
import Taxi from './Pages/Taxi';
import Boarding from './Pages/Boarding';
import Invoice from './Pages/Invoice';
import DepositPaymentSucceeded from './Pages/DepositPaymentSucceeded';
import DepositPaymentFailed from './Pages/DepositPaymentFailed';
import InvoicePaymentSucceeded from './Pages/InvoicePaymentSucceeded';
import InvoicePaymentFailed from './Pages/InvoicePaymentFailed';
import SubscribeSucceeded from './Pages/SubscribeSucceeded';
import SubscribeFailed from './Pages/SubscribeFailed';
import CompanyProfile from './Pages/CompanyProfile';
import SubscribeFromLink from './Pages/SubscribeFromLink';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Walks from './Pages/Walks';
import Dropins from './Pages/Dropins';
import Contract from './Pages/Contract';
import Appointment from './Pages/Appointment';
import Bulletin from './Pages/Bulletin';
import TnCs from './Pages/TnCs';
import E404 from './Errors/E404';

//Helpers
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MediaQueryProvider } from 'react-media-query-hoc';
import Redirect from './Components/Functional/Redirect';

class App extends Component {
  render() {
    return (
      <MediaQueryProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/journal" component={Journal}/>
            <Route path="/about" component={About}/>
            <Route path="/download" component={Download}/>
            <Route path="/client-help" component={CustomerHowItWorks}/>
            <Route path="/pricing" component={Pricing}/>
            <Route path="/terms-and-conditions" component={TnCs}/>
            <Route path="/login" component={Login}/>
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/walks" component={Walks}/>
            <Route path="/dropins" component={Dropins}/>
            <Route path="/appointment" component={Appointment}/>
            <Route path="/announcement" component={Bulletin}/>
            <Route
              path="/privacy-policy"
              render={props => <Redirect to="https://www.iubenda.com/privacy-policy/78887434"/>}
            />
            <Route path="/walk/:walkId" component={Walk}/>
            <Route path="/checkin/:checkinId" component={CheckIn}/>
            <Route path="/taxi/:taxiId" component={Taxi}/>
            <Route path="/invoice/:invoiceId" component={Invoice}/>
            <Route path="/deposit-payment-succeeded" component={DepositPaymentSucceeded}/>
            <Route path="/deposit-payment-failed" component={DepositPaymentFailed}/>
            <Route path="/invoice-payment-succeeded" component={InvoicePaymentSucceeded}/>
            <Route path="/invoice-payment-failed" component={InvoicePaymentFailed}/>
            <Route path="/subscriptionSuccess" component={SubscribeSucceeded}/>
            <Route path="/subscriptionCancel" component={SubscribeFailed}/>
            <Route path="/company/:companyId" component={CompanyProfile}/>
            <Route path="/boarding/:boardingId" component={Boarding}/>
            <Route path="/profile/:companyName" component={CompanyProfile}/>
            <Route path="/subscribe/:companyName" component={SubscribeFromLink}/>
            <Route path="/contract/:contractId" component={Contract}/>
            <Route component={E404}/>
          </Switch>
        </BrowserRouter>
      </MediaQueryProvider>
    )
  }
};
export default App;
