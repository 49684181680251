import React, { Component } from 'react';

class Insurance extends Component {
  render() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
          <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M18.9269357,19.5070883 C21.1166848,19.5070883 22.4165758,18.2071974 22.4165758,16.0174482 L22.4165758,7.23227917 L16.6499455,7.23227917 C15.8822246,7.23227917 15.1842966,6.53435115 15.1842966,5.76663032 L15.1842966,0 L10.3249727,0 C8.13522356,0 6.83533261,1.29989095 6.83533261,3.48964013 L6.83533261,3.57688113 L8.07415485,3.57688113 C10.0109051,3.57688113 11.0141767,3.87350055 12.3838604,5.19956379 L17.1384951,9.84078517 C18.5169029,11.1842966 18.7960742,12.2050164 18.7960742,14.1330425 L18.7960742,19.5070883 L18.9269357,19.5070883 Z M22.1025082,5.66194111 C22.1025082,5.2693566 21.7535442,4.87677208 21.2911668,4.41439477 L17.9498364,1.08178844 C17.5398037,0.663031625 17.1733915,0.331515812 16.7546347,0.322791712 L16.7546347,5.217012 C16.7546347,5.51363141 16.9029444,5.66194111 17.1995638,5.66194111 L22.1025082,5.66194111 Z M13.6314068,24 C15.8211559,24 17.1210469,22.7001091 17.1210469,20.5103599 L17.1210469,14.1417666 L9.76663032,14.1417666 C8.70229008,14.1417666 8.06543075,13.5049073 8.06543075,12.4405671 L8.06543075,5.09487459 L5.07306434,5.09487459 C2.88331516,5.09487459 1.58342421,6.39476554 1.58342421,8.58451472 L1.58342421,20.5103599 C1.58342421,22.7001091 2.88331516,24 5.07306434,24 L13.6314068,24 Z M10.3424209,12.4667394 L16.7895311,12.4492912 C16.780807,11.9345692 16.3358779,11.4198473 15.7251908,10.8266085 L11.3282443,6.43838604 C10.778626,5.88876772 10.2813522,5.4438386 9.74918212,5.4263904 L9.74918212,11.8735005 C9.74918212,12.2748092 9.94111232,12.4667394 10.3424209,12.4667394 Z"/>
          </svg>
        );
    }
}

export default Insurance;