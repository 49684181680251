import React, { Component } from 'react';
import './AllActivities.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

//Components
import { MoonLoader } from 'halogenium';
import Activity from './ActivityCard';

class AllActivities extends Component {

    state = {
        activities: null
    }

    componentDidMount() {
        var that = this;
        return firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const db = firebase.firestore();
                return db.collection("customers").doc(user.uid).get()
                .then(function(doc) {
                    if (doc.exists) {
                        const user = doc.data();
                        const companyId = user.linkedCompanyID;
                        const customerId = user.linkedCustomerID;

                        const data = {
                            "type": that.props.type,
                            "customerID": customerId,
                            "companyID": companyId,
                            "limit": 10
                        }
                
                        var getWalks = firebase.functions().httpsCallable('getActivities');
                        return getWalks(data).then(activities => {
                            that.setState({activities: activities.data});
                        }).catch(function(error) {
                            console.log(error)
                        });
                    } else {
                        console.log('no doc')
                    }
                }).catch(function(error) {  
                    console.log(error)   
                });
            }
        });
    }

    generateActivities(activities) {
        return activities.length
            ? <React.Fragment>
            <div className='activities'>
                {activities.map(activity => (
                    <Activity 
                        activity={activity}
                        url={this.props.url}
                    />
                ))}
                </div>
            </React.Fragment>
            : null
    }

    loading() {
        return (
          <article aria-busy={true} className="loader">
            <MoonLoader
              loading={true}
              color="#1AE0B3"
              size="50"
              margin="auto"
            />
          </article>
        )
    }

    render() {
        if (!this.state.activities) {
            return (
                <div className="all-activities">
                    {this.loading()}
                </div>
            )
        }

        if (this.state.activities.length > 0) {
            return (
                <div className="all-activities">
                {this.generateActivities(this.state.activities)}
                </div>
            )
        }

        return (
            <div className="all-activities">
            <h4>{this.props.title}</h4>
            <h6>{this.props.error}</h6>
            </div>
        )
    } 
};

export default AllActivities;