import React, { Component } from 'react';

class HomeSitting extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M4.9928003,8.91246684 L4.9928003,7.93937097 C4.9928003,6.98446381 5.50208412,6.48427435 6.48427435,6.48427435 L9.56726033,6.48427435 C10.5494505,6.48427435 11.0587344,6.98446381 11.0587344,7.93937097 L11.0587344,8.91246684 L12.9412656,8.91246684 L12.9412656,7.93937097 C12.9412656,6.98446381 13.4505495,6.48427435 14.4327397,6.48427435 L17.5157257,6.48427435 C18.4888215,6.48427435 19.0071997,6.98446381 19.0071997,7.93937097 L19.0071997,8.91246684 L21.5718075,8.91246684 L21.5718075,6.42061387 C21.5718075,4.58355438 20.5714286,3.60136415 18.7161804,3.60136415 L5.28381963,3.60136415 C3.41947707,3.60136415 2.41909814,4.58355438 2.41909814,6.42061387 L2.41909814,8.91246684 L4.9928003,8.91246684 Z M1.5005684,20.3986358 C2.02804092,20.3986358 2.38272073,20.043956 2.38272073,19.5255779 L2.38272073,18.425161 C2.49185297,18.4615385 2.87381584,18.4797272 3.15574081,18.4797272 L20.8442592,18.4797272 C21.1261842,18.4797272 21.508147,18.4615385 21.6172793,18.425161 L21.6172793,19.5255779 C21.6172793,20.043956 21.9719591,20.3986358 22.4994316,20.3986358 L23.1178477,20.3986358 C23.6362258,20.3986358 24,20.043956 24,19.5255779 L24,13.7597575 C24,11.7317166 22.8904888,10.6676772 20.8442592,10.6676772 L3.15574081,10.6676772 C1.10951118,10.6676772 0,11.7317166 0,13.7597575 L0,19.5255779 C0,20.043956 0.363774157,20.3986358 0.88215233,20.3986358 L1.5005684,20.3986358 Z"/>
      </svg>
    );
  }
}

export default HomeSitting;