import Phone from '../Components/Assets/images/customerpetsitting.png';
import Video from '../Components/Assets/icons/video';
import Treat from '../Components/Assets/icons/treat';
import Poop from '../Components/Assets/icons/poop';
import Tracking from '../Components/Assets/icons/tracking';

export const customerpetsittingfeature = 
  {
    title: 'See what your pet gets up to at daycare',
    featureimage: Phone,
    features: [
        {
            icon: Video, 
            title: 'View and save photos and videos'
        },
        {
            icon: Treat, 
            title: 'See if they ate, had treats, or their medication'
        },
        {
            icon: Poop, 
            title: 'See if they peed and pooped'
        },
        {
            icon: Tracking, 
            title: 'View any walks they have been on'
        }
    ]
  }