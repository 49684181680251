import React, { Component } from 'react';

class Time extends Component {
  render() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
          <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M11.7507258,24 C17.6735794,24 22.5114061,19.1621734 22.5114061,13.2393198 C22.5114061,11.0891746 21.874326,9.08834509 20.7793447,7.40605558 L21.6652841,6.54002489 C22.0037329,6.21153049 22.1630029,5.87308171 22.1630029,5.47490668 C22.1630029,4.69846537 21.555786,4.09124844 20.7793447,4.09124844 C20.3214434,4.09124844 19.992949,4.22065533 19.6644546,4.52924098 L18.9278308,5.23600166 C17.3550394,3.83243467 15.3741186,2.8768146 13.1941103,2.57818333 L13.1941103,1.42347574 C13.1941103,0.607216922 12.5570303,0 11.7606802,0 C10.9941933,0 10.3172957,0.607216922 10.3172957,1.42347574 L10.3172957,2.57818333 C5.07133969,3.28494401 1,7.80423061 1,13.2393198 C1,19.1621734 5.83782663,24 11.7507258,24 Z M11.7507258,15.3396931 C10.5960182,15.3396931 9.6702613,14.4139361 9.6702613,13.2393198 C9.6702613,12.5524679 10.0087101,11.9751141 10.6557445,11.5271672 L10.6557445,7.22687681 C10.6557445,6.60970552 11.1335545,6.13189548 11.7507258,6.13189548 C12.3579428,6.13189548 12.8457072,6.60970552 12.8457072,7.22687681 L12.8457072,11.5470759 C13.502696,11.9552053 13.8411448,12.5325591 13.8411448,13.2393198 C13.8411448,14.4139361 12.9054334,15.3396931 11.7507258,15.3396931 Z"/>
          </svg>
        );
    }
}

export default Time;