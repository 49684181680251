import React, { Component } from 'react';
import './Contact.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components
import { MoonLoader } from 'halogenium';
import IconWithTitle from '../Components/IconWithTitle';

//Assets
import Email from './Assets/icons/email';
import Phone from './Assets/icons/phone';
import Facebook from './Assets/icons/facebook';
import Instagram from './Assets/icons/instagram';
import Twitter from './Assets/icons/twitter';

class Contact extends Component {

    state = {
        contact: null
    }

    componentDidMount() {
        const db = firebase.firestore();
        var that = this;

        db.collection(`companies/${this.props.companyID}/contact`)
        .orderBy('type')
        .onSnapshot(function(querySnapshot) {
            var contact = []
            querySnapshot.forEach(function(doc) {
                contact.push(doc.data()) 
            });
            that.setState({contact});
        });
    }

    loading() {
        return
    }

    generateContactItems(contact) {
        return contact.length
            ? <React.Fragment>
            <div className="company-contact">
                {contact.map(contactItem => (
                    (contactItem.type === 'email' ?
                        <a className="link" href={"mailto:" + contactItem.info}>
                            <IconWithTitle 
                                icon={<Email color={this.props.color}/>}
                                title={contactItem.info}
                            />
                        </a>
                    :
                    contactItem.type === 'phone' ?
                        <a className="link" href={"tel:" + contactItem.info}>
                            <IconWithTitle 
                                icon={<Phone color={this.props.color}/>}
                                title={contactItem.info}
                            />
                        </a>
                    :
                    contactItem.type === 'facebook' ?
                        <a className="link" href={`https://www.facebook.com/${contactItem.info}`}>
                            <IconWithTitle 
                                icon={<Facebook color={this.props.color}/>}
                                title={contactItem.info}
                            />
                        </a>
                    :
                    contactItem.type === 'instagram' ?
                        <a className="link" href={`https://www.instagram.com/${contactItem.info}`}>
                            <IconWithTitle 
                                icon={<Instagram color={this.props.color}/>}
                                title={contactItem.info}
                            />
                        </a>
                    :
                    contactItem.type === 'twitter' ?
                        <a className="link" href={`https://www.twitter.com/${contactItem.info}`}>
                            <IconWithTitle 
                                icon={<Twitter color={this.props.color}/>}
                                title={contactItem.info}
                            />
                        </a>
                    : null)
                ))}
                </div>
            </React.Fragment>
            : null
    }

    render() {
        function hexToRGBA(hex, opacity) {
            return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(opacity||1).join(',') + ')';
        }

        var that = this;

        if (!this.state.contact) {
            return (
                <div className="contact">
                    {that.loading()}
                </div>
            )
        }

        if (this.state.contact.length > 0) {
            var style = {}
            if (this.props.color) {
                style = {
                    background: hexToRGBA(this.props.color, 0.05)
                }
            }

            return (
                <div className="contact">
                {this.state.contact.length > 0 &&
                    <h6>Contact</h6>
                }
                {that.generateContactItems(this.state.contact)}
                </div>
            )
        }

        return null;
    } 
};

export default Contact;