import React, { Component } from 'react';
import './ContactUsBox.css';

//Assets
import Rob from './Assets/images/rob.png';
import Beth from './Assets/images/beth.png';

//Components
import ButtonPrimary from '../Components/ButtonPrimary';

export default class ContactUsBox extends Component {

    openIntercom(e) {
        e.preventDefault();
        window.Intercom('showNewMessage');
    }

    render() {
        return (
        <div>
            <div className="contact-us-box">
                <div className="rob profile-box">
                    <img className="profile-image" alt="" src={Rob}/>
                    <div className="contact-us-box-content">
                        <h5>Rob Swift</h5>
                        <p>{`Founder, designer, developer, and customer support.`}</p>
                    </div>
                </div>
                {/* <div className="beth profile-box">
                    <img className="profile-image" alt="" src={Beth}/>
                    <div className="contact-us-box-content">
                        <h5>Beth Cleavy</h5>
                        <p>{`Co-founder & Content Writer`}</p>
                    </div>
                </div> */}
                <ButtonPrimary
                    action={this.openIntercom}
                    title='Chat to me'
                />
            </div>
        </div>
        )
    }
}