import React, { Component } from 'react';
import './FooterPoweredBy.css';

//Firebase
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

//Helpers
import { Link } from 'react-router-dom';

//Assets
import Logo from './Assets/logo/poweredbylogo';
import AppStore from './Assets/images/appstorebadge.js';
import PlayStore from './Assets/images/playstorebadge.js';

export default class FooterPoweredBy extends Component {

  state = {
      user: {}
  }
  
  componentDidMount = () => {
      var that = this;
      firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
              that.setState({user: user});
          } else {
              that.setState({user: null});
          }
      });
  };

  logout(e) {
    e.preventDefault();
    firebase.auth().signOut().then(function() {
      localStorage.clear();
      window.location.reload();
    }).catch(function(error) {
      console.log(error)
    });
  }

  openIntercom(e) {
    e.preventDefault();
    window.Intercom('show', {
        app_id: 'ylflg7ex',
    });
  }

  render() {
    if (this.state.user) {
      return (
        <footer className="footer-powered-by">
          <div className="footer-powered-by-content wide-container">
            <div className="columns">
              <div className="left-columns">
              <div className="help-column">
                <a href="" onClick={this.openIntercom}>Help</a>
              </div>
              <div className="about-column">
                <a href="" href="/about">About</a>
              </div>
              <div className="logout-column">
                <a href="" onClick={this.logout}>Sign out</a>
              </div>
              </div>
              <div className="right-columns">
              <div className="logo-column">
                <Link className="logo" to="/"><Logo/></Link>
              </div>
              </div>
            </div>
          </div>
        </footer>
      )
    } else {
      return (
        <footer className="footer-powered-by">
          <div className="footer-powered-by-content wide-container">
              <div className="badges">
                <a href="https://apps.apple.com/app/walkies-customer-pet-journal/id1488308024">
                <div className="showcase-left-appstore"><AppStore color={this.props.color || '#A940AA'}/></div>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.swiftlabs.journal">
                <div className="showcase-left-playstore"><PlayStore color={this.props.color || '#A940AA'}/></div>
                </a>
              </div>
              <Link className="logo" to="/"><Logo/></Link>
          </div>
        </footer>
      )
    }
  }
}
