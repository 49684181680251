import Tick from '../Components/Assets/icons/tick.js';
import Cross from '../Components/Assets/icons/cross.js';

export const pricetierfree = 
  {
    title: 'Starter',
    price: 'Free',
    features: [
        {
            icon: Tick, 
            title: 'Add up to 4 customers'
        },
        {
            icon: Tick, 
            title: '2 photos per walk/drop in'
        },
        {
            icon: Tick, 
            title: '2 photos per pet sitting per day'
        },
        {
            icon: Tick, 
            title: 'Automatic emails keep your customers up to date'
        },
        {
            icon: Cross, 
            title: 'You can’t add any team members until you upgrade'
        }
    ]
  }
