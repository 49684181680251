import React, { Component } from 'react';

class PackageType extends Component {
  render() {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="Rectangle" fill="#BD7E4A" x="0" y="0" width="24" height="24" rx="10"></rect>
                <path d="M15.9310509,9.14075131 L18.1545411,7.95577746 C18.021398,7.82929149 17.8616262,7.72277699 17.6752259,7.62291964 L13.3680456,5.33951498 C12.9486448,5.11317166 12.4759867,5 12.0099857,5 C11.5373276,5 11.0646695,5.11317166 10.6452687,5.33951498 L9.71326676,5.83214456 L15.9310509,9.14075131 Z M12.0033286,11.2244413 L14.6262482,9.82643842 L8.44840704,6.4978602 L6.33808845,7.62291964 C6.15168806,7.72277699 5.98525915,7.82929149 5.85877318,7.95577746 L12.0033286,11.2244413 Z M12.5891583,19 C12.7156443,18.9866857 12.8487874,18.9267713 12.9819306,18.8601997 L17.6352829,16.3903947 C18.3542558,16.0175939 18.6405136,15.5715644 18.6405136,14.7194484 L18.6405136,9.2339515 C18.6405136,9.16072278 18.6405136,9.10080837 18.6338564,9.0342368 L12.5891583,12.2962435 L12.5891583,19 Z M11.4174988,19 L11.4174988,12.2962435 L5.37280076,9.0342368 C5.3661436,9.10080837 5.35948645,9.16072278 5.35948645,9.2339515 L5.35948645,14.7194484 C5.35948645,15.5715644 5.65240133,16.0175939 6.37137423,16.3903947 L11.0247266,18.8601997 C11.1578697,18.9267713 11.2910128,18.9866857 11.4174988,19 Z" id="􀐛" fill="#FFFFFF" fill-rule="nonzero"></path>
            </g>
        </svg>
    );
  }
}

export default PackageType;