import React, { Component } from 'react';

class Twitter extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill={this.props.color || '#00AAEC'} fillRule="evenodd" d="M24,4.36764175 C23.1181546,4.76923077 22.1686917,5.04080896 21.1727417,5.1621524 C22.1898222,4.5381004 22.9702412,3.54857349 23.3379116,2.3698086 C22.3856313,2.94763453 21.3333333,3.36800289 20.2091918,3.593355 C19.3132594,2.61249549 18.0327522,2 16.6156013,2 C13.8982215,2 11.693608,4.26074395 11.693608,7.04875406 C11.693608,7.44456482 11.735869,7.82881907 11.8203909,8.19862766 C7.72812115,7.9877212 4.10072196,5.97977609 1.67071668,2.92163236 C1.24669836,3.66991694 1.00440218,4.5381004 1.00440218,5.46262189 C1.00440218,7.21343445 1.87356929,8.75911882 3.19492868,9.66486096 C2.38915302,9.64030336 1.62845571,9.41061755 0.963549921,9.0350307 L0.963549921,9.09714698 C0.963549921,11.5442398 2.66103187,13.5854099 4.91494982,14.0476706 C4.50220109,14.166125 4.06691319,14.2253521 3.6175383,14.2253521 C3.30058109,14.2253521 2.99066737,14.1950163 2.69061454,14.1357891 C3.31748547,16.1408451 5.13470681,17.6013001 7.29001585,17.6403034 C5.60521219,18.9953052 3.48089452,19.8028169 1.17485473,19.8028169 C0.77760169,19.8028169 0.384574749,19.7797039 0,19.7334778 C2.17925691,21.163597 4.76844515,22 7.54780771,22 C16.6057404,22 21.5573164,14.3076923 21.5573164,7.63524738 C21.5573164,7.41567353 21.5530903,7.19609967 21.5446381,6.98085952 C22.5067794,6.26868906 23.3421377,5.38028169 24,4.36764175"/>
            </svg>
        );
    }
}

export default Twitter;