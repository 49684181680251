import React, { Component } from 'react';
import './DepositPaymentFailed.css';

//Components
import Failed from '../Components/Assets/icons/cross';

class DepositPaymentFailed extends Component {

  render() {
    return (  
      <main className="invoice-payment-failed">
        <div className="content-container wide-container">
          <div className="message-container">
            <Failed size='56'/>
            <h5>Deposit payment failed</h5>
            <p>Please try again.</p>
          </div>
        </div> 
      </main>    
    );
  }
}

export default DepositPaymentFailed;