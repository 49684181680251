import React, { Component } from 'react';
import './ButtonPrimary.css';

export default class ButtonPrimary extends Component {
    render() {
        return (
            <button
                style={{backgroundColor: this.props.color}}
                className="button-primary"
                onClick={this.props.action}>{this.props.title}
            </button>
        )
    }
}