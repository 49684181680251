import Phone from '../Components/Assets/images/petsitterschedule.png';
import Tracking from '../Components/Assets/icons/tracking';
import Notifications from '../Components/Assets/icons/notifications';
import Team from '../Components/Assets/icons/team.js';
import AddAppointment from '../Components/Assets/icons/addappointment';

export const petsitterschedulefeature = 
  {
    title: 'Easy scheduling',
    featureimage: Phone,
    features: [
        {
            icon: Tracking, 
            title: 'Add any of your services to your calendar'
        },
        {
            icon: Notifications, 
            title: 'Walkies will automatically notify your customers about new appointments, cancellations, and updates'
        },
        {
            icon: Team, 
            title: 'Manage your team’s schedule and assign appointments to team members'
        },
        {
            icon: AddAppointment, 
            title: 'Your customers can book your services via the customer app'
        }
    ]
  }