import Phone from '../Components/Assets/images/petsittershowcase.png';
import Tracking from '../Components/Assets/icons/tracking.js';
import Poop from '../Components/Assets/icons/poop.js';
import Photo from '../Components/Assets/icons/photo.js';
import Schedule from '../Components/Assets/icons/schedule.js';
import Invoice from '../Components/Assets/icons/invoice.js';
import Team from '../Components/Assets/icons/team.js';

export const petsittershowcase = 
  {
    title: 'Pet Sitters',
    subtitle: 'Run your business with simplicity and give your customers a great experience',
    featureimage: Phone,
    features: [
        {
            icon: Tracking, 
            title: 'Track distance, duration, and route in realtime'
        },
        {
            icon: Poop, 
            title: 'Track pees and poops'
        },
        {
            icon: Photo, 
            title: 'Take photos and videos'
        },
        {
            icon: Schedule, 
            title: 'Schedule your appointments'
        },
        {
            icon: Invoice, 
            title: 'Keep track of invoices'
        },
        {
            icon: Team, 
            title: 'Add your whole team'
        }
    ]
  }
