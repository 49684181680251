import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="ic_boarding_notes" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#1AE0B3" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M15.3,14 C15.9075132,14 16.4,14.4924868 16.4,15.1 C16.4,15.7075132 15.9075132,16.2 15.3,16.2 L7.5,16.2 C6.89248678,16.2 6.4,15.7075132 6.4,15.1 C6.4,14.4924868 6.89248678,14 7.5,14 L15.3,14 Z M13,10.9 C13.6075132,10.9 14.1,11.3924868 14.1,12 C14.1,12.6075132 13.6075132,13.1 13,13.1 L7.5,13.1 C6.89248678,13.1 6.4,12.6075132 6.4,12 C6.4,11.3924868 6.89248678,10.9 7.5,10.9 L13,10.9 Z M16.5,7.8 C17.1075132,7.8 17.6,8.29248678 17.6,8.9 C17.6,9.50751322 17.1075132,10 16.5,10 L7.5,10 C6.89248678,10 6.4,9.50751322 6.4,8.9 C6.4,8.29248678 6.89248678,7.8 7.5,7.8 L16.5,7.8 Z" id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
);
