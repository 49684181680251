import React, { Component } from 'react';
import './InvoicePaymentSucceeded.css';

//Components
import Tick from '../Components/Assets/icons/tick';

class InvoicePaymentSucceeded extends Component {

  render() {
    return (  
      <main className="invoice-payment-succeeded">
        <div className="content-container wide-container">
          <div className="message-container">
            <Tick size='56'/>
            <h5>Invoice paid, thank you.</h5>
            <p>You’ll receive an email confirming your payment with a reference number.</p>
          </div>
        </div> 
      </main>    
    );
  }
}

export default InvoicePaymentSucceeded;