import React, { Component } from 'react';

class Cheque extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M0,7.67256637 L24,7.67256637 L24,6.58938053 C24,4.25309735 22.7362832,3 20.3787611,3 L3.62123894,3 C1.26371681,3 0,4.25309735 0,6.58938053 L0,7.67256637 Z M0,17.9415929 C0,20.2884956 1.26371681,21.5415929 3.62123894,21.5415929 L20.3787611,21.5415929 C22.7362832,21.5415929 24,20.2778761 24,17.9415929 L24,10.2212389 L0,10.2212389 L0,17.9415929 Z M3.67433628,15.4884956 L3.67433628,13.5132743 C3.67433628,12.9185841 4.09911504,12.5044248 4.71504425,12.5044248 L7.31681416,12.5044248 C7.94336283,12.5044248 8.36814159,12.9185841 8.36814159,13.5132743 L8.36814159,15.4884956 C8.36814159,16.0831858 7.94336283,16.4973451 7.31681416,16.4973451 L4.71504425,16.4973451 C4.09911504,16.4973451 3.67433628,16.0831858 3.67433628,15.4884956 Z"/>
      </svg>
    );
  }
}

export default Cheque;