import React from 'react';
import './Review.css';

export default function Testimonial(props) {
  return (
    <div className='review-box'>
      <div className='top'>
        <img className='profile-image' src={props.src} alt=""/>
        <div className='review-box-content'>
          <h5>{props.name}</h5>
          <p>{props.subtext}</p>
        </div>
      </div>
      <p>{props.text}</p>
    </div>
  );
}
