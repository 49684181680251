import React, { Component } from 'react';
import './Journal.css';

//Firebase
import 'firebase/compat/app';
import "firebase/compat/auth";

//Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import JournalHero from '../Components/JournalHero';
import ShowcaseRight from '../Components/ShowcaseRight';
import ShowcaseLeft from '../Components/ShowcaseLeft';
import ContactUs from '../Components/ContactUs';

//Data
import { customerreportsfeature } from '../Data/customerreportsfeature';
import { customerpetsittingfeature } from '../Data/customerpetsittingfeature';
import { customernotificationsfeature } from '../Data/customernotificationsfeature';
import { customerappointmentsfeature } from '../Data/customerappointmentsfeature';
import { customerinvoicesfeature } from '../Data/customerinvoicesfeature';
import { customermessagingfeature } from '../Data/customermessagingfeature';

class Journal extends Component {

  render() {
    window.scrollTo(0, 0)
    return (
      <main className="journal header-offset">
        <Header wide noShadow/>
        <JournalHero/>
        <div className='journal-activities-showcase'>
          <ShowcaseRight showcase={customerreportsfeature}/>
        </div>
        <div className='journal-pet-sitting-showcase'>
          <ShowcaseLeft showcase={customerpetsittingfeature}/>
        </div>
        <div className='journal-notifications-showcase'>
          <ShowcaseRight showcase={customernotificationsfeature}/>
        </div>
        <div className='journal-appointments-showcase'>
          <ShowcaseLeft showcase={customerappointmentsfeature}/>
        </div>
        <div className='journal-invoices-showcase'>
          <ShowcaseRight showcase={customerinvoicesfeature}/>
        </div>
        <div className='journal-messaging-showcase'>
          <ShowcaseLeft showcase={customermessagingfeature}/>
        </div>
        <ContactUs
          content='You can instant message us (the founders of Walkies) in app or here with any questions you have or features you’d like us to add at any time. There’s just two of us but that means you’ll get truly personal support. We’re very responsive and everything we do is centered around you.'
        />
        <Footer/>
      </main>
    );
  }
}

export default Journal;

