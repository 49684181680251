import React, { Component } from 'react';

class Weather extends Component {
  render() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M4.9070632,20 C1.99628253,20 0,18.1685393 0,15.494382 C0,13.3820225 1.33828996,11.6404494 3.39033457,11.0561798 C3.71375465,7.08988764 7.00371747,4 10.9182156,4 C13.8624535,4 16.33829,5.57303371 17.6988848,8.31460674 C21.2899628,8.29213483 24,10.7977528 24,14.1348315 C24,17.4382022 21.401487,20 18.0223048,20 L4.9070632,20 Z"/>
          </svg>
        );
    }
}

export default Weather;