import Tick from '../Components/Assets/icons/tick.js';

export const pricetierplus = 
  {
    title: 'Plus',
    price: '$4.99 per month',
    features: [
        {
            icon: Tick, 
            title: 'Unlimited customers'
        },
        {
            icon: Tick, 
            title: 'Unlimited photos'
        },
        {
            icon: Tick, 
            title: '2 videos per walk/drop in'
        },
        {
            icon: Tick, 
            title: '2 videos per pet sitting per day'
        },
        {
            icon: Tick, 
            title: 'Automatic emails keep your customers up to date'
        }
    ]
  }
