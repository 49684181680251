import React, { Component } from 'react';
import './SubscribeFromLink.css';

// Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

//Components
import { MoonLoader } from 'halogenium';
import HeaderBlank from '../Components/HeaderBlank';
import Footer from '../Components/FooterPoweredBy';

class SubscribeFromLink extends Component {

    componentDidMount() {
        const companyID = window.location.pathname.split('/')[2];
        const getCheckout = firebase.functions().httpsCallable('checkoutSession');
        return getCheckout({companyID: companyID})
        .then(session => {
            window.location.href=session.data
        }).catch(function(error) {
            console.log(error);
        });
    }

    render() {
        return (  
        <main className="subscribe-from-link wide-container">
            <div>
                <MoonLoader
                    loading={true}
                    color="#1AE0B3"
                    size="50px"
                    margin="auto"
                />
            </div> 
            <h5>Preparing subscription checkout...</h5>
        </main>    
        );
    }
}

export default SubscribeFromLink;