import React, { Component } from 'react';

class TeamMember extends Component {
  render() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M12.0121079,11.6912493 C14.700055,11.6912493 16.9279031,9.31810677 16.9279031,6.27903137 C16.9279031,3.32471106 14.6758393,1 12.0121079,1 C9.33626857,1 7.07209686,3.3489268 7.08410844,6.29113924 C7.0963126,9.31810677 9.3241607,11.6912493 12.0121079,11.6912493 Z M4.5052284,23 C2.43478261,23 1.73252614,22.3340671 1.73252614,21.1838195 C1.73252614,18.0842047 5.72812328,13.8222345 12,13.8222345 C18.2839846,13.8222345 22.2674739,18.0842047 22.2674739,21.1838195 C22.2674739,22.3340671 21.5652174,23 19.5068795,23 L4.5052284,23 Z"/>
          </svg>
        );
    }
}

export default TeamMember;