import React from 'react';

export default (props: {}): React.DOM => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width="24" height="24" fill="#1AE0B3" rx="10"/>
      <path fill="#FFF" d="M11.4945345,17.5504163 C11.0614017,17.5504163 10.7837525,17.4283071 10.4394674,17.1174838 L6.35247066,13.3320999 C5.9748677,12.9879741 5.77496025,12.53284 5.77496025,11.9888992 C5.77496025,11.4449584 5.9748677,10.9898242 6.35247066,10.6345976 L10.4394674,6.84921369 C10.7837525,6.53839038 11.0614017,6.42738205 11.4945345,6.42738205 C12.2497404,6.42738205 12.782827,6.97132285 12.782827,7.77058279 C12.782827,8.1480111 12.5940255,8.54764107 12.3163763,8.78075856 L11.0725077,9.85753932 L10.0285466,10.5235893 L12.2053166,10.3570768 L16.7254461,10.3570768 C17.6028177,10.3570768 18.224752,11.0786309 18.224752,11.9888992 C18.235858,12.8880666 17.6028177,13.6096207 16.7254461,13.6096207 L12.2053166,13.6096207 L10.0174406,13.4431082 L11.0280838,14.1091582 L12.3163763,15.1970398 C12.5940255,15.4190564 12.782827,15.8075856 12.782827,16.1961147 C12.782827,16.9953747 12.2497404,17.5504163 11.4945345,17.5504163 Z" transform="rotate(-180 12 11.989)"/>
    </g>
  </svg>
);
