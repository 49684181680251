import Phone from '../Components/Assets/images/petsitterpetsitting.png';
import Video from '../Components/Assets/icons/video.js';
import Treat from '../Components/Assets/icons/treat.js';
import Poop from '../Components/Assets/icons/poop.js';
import Notes from '../Components/Assets/icons/notes.js';
import Tracking from '../Components/Assets/icons/tracking.js';

export const petsitterpetsittingfeature = 
  {
    title: 'Track daycare, boarding, and home sitting',
    featureimage: Phone,
    features: [
        {
            icon: Video, 
            title: 'Take photos and videos'
        },
        {
            icon: Treat, 
            title: 'Quickly track food, treats, and medication'
        },
        {
            icon: Poop, 
            title: 'Track pees and poops'
        },
        {
            icon: Notes, 
            title: 'Beautiful timeline that your customers will love'
        },
        {
            icon: Tracking, 
            title: 'Walks are added to pet sitting reports'
        }
    ]
  }
