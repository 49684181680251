import React, { Component } from 'react';
import './FeatureCalloutSpecial.css';
import Tracking from './Assets/icons/tracking';

class FeatureCalloutSpecial extends Component {

  render() {
    return (
      <div className="feature-callout-special">
        <div className="feature-callout-special-content wide-container">
            <Tracking color='#fff' size='40'/>
            <h1 className='feature-callout-special-title'>483,414</h1>
             <h3 className='feature-callout-special-subtitle'>Activities tracked so far</h3>
        </div> 
      </div>
    )
}
};

export default FeatureCalloutSpecial;