import React, { Component } from 'react';
import './Header.css';

import classnames from 'classnames';

export default class HeaderBlank extends Component {

  render() {
    return (
      <header className={classnames('main-header')}>
      </header>
    )
  }
}
