import React, { Component } from 'react';
import './Qualifications.css';

//Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components
import { MoonLoader } from 'halogenium';
import IconWithTitle from '../Components/IconWithTitle';

//Assets
import Certificate from './Assets/icons/certificate';
import Award from './Assets/icons/award';
import Insurance from './Assets/icons/insurance';

class Qualifications extends Component {

    state = {
        qualifications: null
    }

    componentDidMount() {
        const db = firebase.firestore();
        var that = this;

        db.collection(`companies/${this.props.companyID}/qualifications`)
        .orderBy('type')
        .onSnapshot(function(querySnapshot) {
            var qualifications = []
            querySnapshot.forEach(function(doc) {
                qualifications.push(doc.data()) 
            });
            that.setState({qualifications});
        });
    }

    loading() {
        return
    }

    generateQualifications(qualifications) {
        return qualifications.length
            ? <React.Fragment>
            <div className="company-qualifications">
                {qualifications.map(qualification => (
                    (qualification.type === 'certificate' ?
                        <IconWithTitle 
                            icon={<Certificate color={this.props.color}/>}
                            title={qualification.info}
                        />
                    :
                    qualification.type === 'award' ?
                        <IconWithTitle 
                            icon={<Award color={this.props.color}/>}
                            title={qualification.info}
                        />
                    :
                    qualification.type === 'insurance' ?
                        <IconWithTitle 
                            icon={<Insurance color={this.props.color}/>}
                            title={qualification.info}
                        />
                    : null)
                ))}
                </div>
            </React.Fragment>
            : null
    }

    render() {
        var that = this;

        if (!this.state.qualifications) {
            return (
                <div className="qualifications">
                    {that.loading()}
                </div>
            )
        }

        if (this.state.qualifications.length > 0) {
            return (
                <div className="qualifications">
                {this.state.qualifications.length > 0 &&
                    <h6>Qualifications</h6>
                }
                {that.generateQualifications(this.state.qualifications)}
                </div>
            )
        }

        return null;
    } 
};

export default Qualifications;