import Phone from '../Components/Assets/images/petsittercustomers.png';
import Messaging from '../Components/Assets/icons/messaging';
import Tracking from '../Components/Assets/icons/tracking';
import Notes from '../Components/Assets/icons/notes';

export const petsittercustomersfeature = 
  {
    title: 'Manage your clients',
    featureimage: Phone,
    features: [
        {
            icon: Messaging, 
            title: 'Message them via Whatsapp, text, email, or in app'
        },
        {
            icon: Tracking, 
            title: 'Get directions via Waze, Google Maps, or Apple Maps'
        },
        {
            icon: Notes, 
            title: 'See all of their information in one place'
        }
    ]
  }