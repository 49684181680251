import React, { Component } from 'react';

class Facebook extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill={this.props.color || '#4460A0'} fillRule="evenodd" d="M12.7509128,23 L2.21423111,23 C1.5434333,23 1,22.4562295 1,21.7856929 L1,2.21422169 C1,1.54351445 1.54351867,1 2.21423111,1 L21.7858543,1 C22.456396,1 23,1.54351445 23,2.21422169 L23,21.7856929 C23,22.4563148 22.4563106,23 21.7858543,23 L16.1796816,23 L16.1796816,14.4803876 L19.0393222,14.4803876 L19.4675128,11.1601434 L16.1796816,11.1601434 L16.1796816,9.04037731 C16.1796816,8.07909002 16.4466178,7.42400444 17.8250911,7.42400444 L19.5832677,7.42323616 L19.5832677,4.45358317 C19.2791977,4.41312049 18.2355258,4.32271971 17.0212947,4.32271971 C14.4863823,4.32271971 12.7509128,5.87003287 12.7509128,8.71155406 L12.7509128,11.1601434 L9.88393082,11.1601434 L9.88393082,14.4803876 L12.7509128,14.4803876 L12.7509128,23 L12.7509128,23 Z"/>
            </svg>
        );
    }
}

export default Facebook;