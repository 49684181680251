import React, { Component } from 'react';
import './FeatureBox.css';

//Components
import IconWithTitle from './IconWithTitle';

class FeatureBox extends Component {

  generateFeatures(features) {
    return features.length
      ? <React.Fragment>
        <div className="feature-box-features">
            {features.map(feature => (
              <IconWithTitle 
                icon={<feature.icon color={'#FEC200'}/>}
                title={feature.title}
              />
            ))}
          </div>
        </React.Fragment>
      : null
  }

  render() {
    return (
      <div className="feature-box wide-container">
        <div className="feature-box-content ">
          <h3 className='feature-box-title'>{this.props.feature.title}</h3>
          {this.generateFeatures(this.props.feature.features)}
        </div>
      </div>
    )
  }
};

export default FeatureBox;