import React, { Component } from 'react';

class App extends Component {
  render() {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M16.0015221,23 C18.6803653,23 20.2095383,22.5535261 21.3815322,21.3703704 C22.564688,20.1872146 23,18.7138508 23,15.9903602 L23,7.99847793 C23,5.28614916 22.564688,3.79046169 21.3815322,2.62962963 C20.1872146,1.44647387 18.6803653,1 16.0015221,1 L7.99847793,1 C5.3196347,1 3.80162354,1.44647387 2.61846778,2.62962963 C1.43531202,3.81278539 1,5.28614916 1,7.99847793 L1,15.9903602 C1,18.7138508 1.43531202,20.1983765 2.61846778,21.3703704 C3.81278539,22.5535261 5.3196347,23 7.99847793,23 L16.0015221,23 Z"/>
  </svg>
    );
  }
}

export default App;