import React from 'react';

export default (props: {}): React.DOM => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle" fill="#FFA06A" x="0" y="0" width="24" height="24" rx="10"></rect>
        <path d="M12.0031675,19.062412 C12.2565697,19.062412 12.6366729,18.8343501 13.0104411,18.587283 C16.4503754,16.3066635 18.75,13.5762553 18.75,10.845847 C18.75,8.22946973 16.9191694,6.5 14.8032614,6.5 C13.4792351,6.5 12.5479822,7.22853121 12.0031675,8.261145 C11.4583529,7.22853121 10.5271,6.5 9.20307367,6.5 C7.08716565,6.5 5.25,8.22946973 5.25,10.845847 C5.25,13.5762553 7.54962459,16.3066635 10.9958939,18.587283 C11.3633271,18.8343501 11.7497654,19.062412 12.0031675,19.062412 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
    </g>  
  </svg>  
);
