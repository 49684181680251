import React, { Component } from 'react';

class Dried extends Component {
  render() {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g id="ic_dried" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="Rectangle" fill="#429BFF" x="0" y="0" width="24" height="24" rx="10"></rect>
                <path d="M8.15550801,18 L15.8444648,18 C17.2553633,18 18.0000102,17.2554074 18.0000102,15.8618276 L18.0000102,8.13816292 C18.0000102,6.74259064 17.2553633,6 15.8444648,6 L8.15550801,6 C6.74894193,6 5.99998982,6.7382474 5.99998982,8.13816292 L5.99998982,15.8618276 C5.99998982,17.2597506 6.74894193,18 8.15550801,18 Z M11.320963,15.1561612 C11.0621608,15.1561612 10.8516498,15.0407941 10.6660039,14.8039795 L9.13384242,12.9557177 C9.00806246,12.796104 8.94675776,12.6439823 8.94675776,12.4713389 C8.94675776,12.1182886 9.23215144,11.8343499 9.58752543,11.8343499 C9.79803638,11.8343499 9.95823644,11.9098135 10.1274053,12.1197544 L11.3015814,13.5924353 L13.8665033,9.49705661 C14.0197651,9.25966106 14.2114644,9.13561296 14.4317748,9.13561296 C14.77413,9.13561296 15.0916201,9.38312282 15.0916201,9.74284003 C15.0916201,9.8949454 15.0213684,10.0574582 14.9204425,10.2133205 L11.9524959,14.7958902 C11.798637,15.0280902 11.5737119,15.1561612 11.320963,15.1561612 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
            </g>
        </svg>
    );
  }
}

export default Dried;