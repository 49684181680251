import React, { Component } from 'react';

class Google extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path fill="#4285F4" d="M23.7589556,12.2727297 C23.7589556,11.4218198 23.6825956,10.6036398 23.5407756,9.8181798 L12.2389559,9.8181798 L12.2389559,14.4600007 L18.6971457,14.4600007 C18.4189558,15.9600007 17.5735058,17.2309096 16.3025958,18.0818186 L16.3025958,21.0927276 L20.1807757,21.0927276 C22.4498657,19.0036366 23.7589556,15.9272727 23.7589556,12.2727297 L23.7589556,12.2727297 Z"/>
                <path fill="#34A853" d="M12.2389559,24 C15.4789558,24 18.1953258,22.9254545 20.1807757,21.0927276 L16.3025958,18.0818186 C15.2280458,18.8018186 13.8535058,19.2272726 12.2389559,19.2272726 C9.11350495,19.2272726 6.468051,17.1163636 5.52441402,14.2800007 L1.5153241,14.2800007 L1.5153241,17.3890906 C3.48986906,21.3109096 7.54805098,24 12.2389559,24 L12.2389559,24 Z"/>
                <path fill="#FBBC05" d="M5.52441402,14.2800007 C5.28441403,13.5599997 5.14805103,12.7909097 5.14805103,11.9999998 C5.14805103,11.2090898 5.28441403,10.4399998 5.52441402,9.7199998 L5.52441402,6.61090986 L1.5153241,6.61090986 C0.702596121,8.23090983 0.23896003,10.0636398 0.23896003,11.9999998 C0.23896003,13.9363597 0.702596121,15.7690907 1.5153241,17.3890906 L5.52441402,14.2800007 L5.52441402,14.2800007 Z"/>
                <path fill="#EA4335" d="M12.2389559,4.7727299 C14.0007758,4.7727299 15.5825958,5.37817989 16.8262358,6.56726986 L20.2680457,3.12545993 C18.1898658,1.18908998 15.4735058,-1.59872116e-14 12.2389559,-1.59872116e-14 C7.54805098,-1.59872116e-14 3.48986906,2.68908994 1.5153241,6.61090986 L5.52441402,9.7199998 C6.468051,6.88363986 9.11350495,4.7727299 12.2389559,4.7727299 L12.2389559,4.7727299 Z"/>
            </g>
            </svg>
        );
    }
}

export default Google;