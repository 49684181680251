import React, { Component } from 'react';
import './ActivityCard.css';

//Firebase
import firebase from 'firebase/compat/app';

//Components
import Map from '../Components/ActivityMap';
import IconWithTitle from '../Components/IconWithTitle';

//Formatters
import moment from 'moment';
import { formatSeconds } from '../Helpers/time';
import { metresToMiles } from '../Helpers/distance';

//Helpers
import { Link } from 'react-router-dom';

//Icons
import Time from './Assets/icons/time.js';
import Duration from './Assets/icons/duration.js';
import Distance from './Assets/icons/distance.js';
import Weather from './Assets/icons/weather.js';

class ActivityCard extends Component {
    render() {

        var startTime;
        var endTime;

        if (this.props.activity.startTime["_seconds"] && this.props.activity.startTime["_nanoseconds"]) {
            startTime = moment(new firebase.firestore.Timestamp(this.props.activity.startTime["_seconds"], this.props.activity.startTime["_nanoseconds"]).toDate());
        }

        if (this.props.activity.endTime) {
            if (this.props.activity.endTime["_seconds"] && this.props.activity.endTime["_nanoseconds"]) {
                endTime = moment(new firebase.firestore.Timestamp(this.props.activity.endTime["_seconds"], this.props.activity.endTime["_nanoseconds"]).toDate());
            }
        }

        if (!startTime) {
            startTime = moment(this.props.activity.startTime.toDate());
        }

        var timeData;
        if (this.props.activity.endTime && endTime === null) {
            endTime = moment(this.props.activity.endTime.toDate());
            timeData = `${startTime.format('HH:mm')} to ${endTime.format('HH:mm')}`
        } else {
            timeData = `${startTime.format('HH:mm')}`
        }

        var backgroundImage;
        if (this.props.activity.walkImages) {
            if (this.props.activity.walkImages.length > 0) {
                backgroundImage = {
                    backgroundImage: `url(${this.props.activity.walkImages[0]})`
                }
            }
        }

        return (
            <Link className='activity-card' to={`/${this.props.url}/${this.props.activity.id}`}>
                {this.props.activity.walkCoordinates && (!this.props.activity.walkImages || this.props.activity.walkImages.length === 0)  ?
                <div className='activity-map'>
                    <Map coords={this.props.activity.walkCoordinates}/>
                </div>
                : 
                <div className='activity-image' style={backgroundImage}></div>
                }
                <div className='activity-content'>
                    <h6>{startTime.format('dddd Do MMMM')}</h6>
                    <p className='activity-meta'>{formatSeconds(this.props.activity.duration)}, {metresToMiles(this.props.activity.distance) + ' mi'}</p>
                </div>
            </Link>
        );
    }
}

export default ActivityCard;
