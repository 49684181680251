import React, { Component } from 'react';

class Share extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.size || '24'} height={this.props.size || '24'} viewBox="0 0 24 24">
        <path fill={this.props.color || '#1AE0B3'} fillRule="evenodd" d="M12.0372723,9.83354511 C12.7734011,9.83354511 13.3418043,9.22786955 13.3418043,8.53833122 L13.3418043,5.43540873 L13.2299873,4.0936044 L13.630665,4.87632359 L14.5624735,5.86404066 C14.7767895,6.08767471 15.0656501,6.227446 15.3917831,6.227446 C15.9508683,6.227446 16.5006353,5.79881406 16.5006353,5.16518424 C16.5006353,4.83905125 16.3795002,4.6154172 16.1651842,4.40110123 L13.0995341,1.46590428 C12.7547649,1.1304532 12.3913596,1 12.0372723,1 C11.6831851,1 11.3197798,1.1304532 10.9750106,1.46590428 L7.90936044,4.40110123 C7.69504447,4.6154172 7.57390936,4.83905125 7.57390936,5.16518424 C7.57390936,5.79881406 8.12367641,6.227446 8.68276154,6.227446 C9.00889454,6.227446 9.29775519,6.08767471 9.51207116,5.86404066 L10.4531978,4.87632359 L10.8538755,4.0936044 L10.7327404,5.43540873 L10.7327404,8.53833122 C10.7327404,9.22786955 11.3011436,9.83354511 12.0372723,9.83354511 Z M6.56755612,23 C4.3218975,23 3.02668361,21.7141042 3.02668361,19.4777637 L3.02668361,11.5946633 C3.02668361,9.34900466 4.3218975,8.06310885 6.56755612,8.06310885 L10.4066074,8.06310885 L10.4066074,13.9894113 C10.4066074,14.8839475 11.1427361,15.6107582 12.0372723,15.6107582 C12.9318086,15.6107582 13.6679373,14.8839475 13.6679373,13.9894113 L13.6679373,8.06310885 L17.4324439,8.06310885 C19.6781025,8.06310885 20.9733164,9.35832274 20.9733164,11.5946633 L20.9733164,19.4777637 C20.9733164,21.7141042 19.6781025,23 17.4324439,23 L6.56755612,23 Z"/>
      </svg>
    );
  }
}

export default Share;