import React, { Component } from 'react';
import './IconWithTitle.css';

class IconWithTitle extends Component {
    render() {
        const textColor = {
            color: this.props.textColor
        }

        return (
            <div className="icon-with-title">
                <div className="type-image">
                    {this.props.icon}
                </div>
                <p style={textColor}>{this.props.title}</p>
            </div>
        )
    }
}

export default IconWithTitle;